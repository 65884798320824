<template>
    <div class="searchable-dropdown">
        <input 
        type="text" 
        v-model="search" 
        @focus="isOpen = true" 
        @input="filterOptions" 
        placeholder="Search name" 
        />
        <button>Search</button>

    </div>
</template>

<script>
export default {
    data(){
        return{
            search: '',
            isOpen: false,
 
        }
    },

    watch: {
        search() {
        this.filterOptions();
        }
    },
    methods: {
        filterOptions() {
        const searchLower = this.search.toLowerCase();
        this.filteredStates = this.states.filter(state =>
            state.toLowerCase().includes(searchLower)
        );
        },
        selectState(state) {
        this.search = state;
        this.selectedState = state;
        this.isOpen = false;
        },
        closeDropdown() {
        setTimeout(() => {
            this.isOpen = false;
        }, 200); // Delay to allow click event to register
        }
    },
    mounted() {
        this.filteredStates = this.states;
    }
}
</script>

<style scoped>
input {
    width: 300px;
    border-radius: 8px;
    border: none;
    padding: 0em .5em;
}

button {
    margin-left: .5em;
    color: white;
    background-color: #b31919;
    border: none;
    border-radius: 5px;
    padding: .5em;
}

.searchable-dropdown {
    display: flex;
    flex-direction: row;
}
</style>