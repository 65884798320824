<template>
    <CountiesNav
    realEstateURL="/medina"
    realEstateForm="/medina-real-estate-form"
    realEstateCounties="/medina-counties"/>
    <CountiesContent
    :buttonPath="buttonPath"
    :countiesList="countiesList"/>

</template>

<script>
import CountiesNav from '@/landing-pages/components/navigations/CountiesNav.vue'
import CountiesContent from '@/landing-pages/components/counties/CountiesContent.vue'
import summitCounty from '@/landing-pages/assets/summit-county-ohio.png'
import cuyahogaCounty from '@/landing-pages/assets/cuyahoga-falls-fridays.jpeg'
import medinaCounty from '@/landing-pages/assets/downtown-medina.jpeg'

export default {
    components: {
        CountiesNav,
        CountiesContent
    },

    data (){
        return {
            summitCounty: summitCounty,
            cuyahogaCounty: cuyahogaCounty,
            medinaCounty: medinaCounty,

            buttonPath: '/medina-real-estate-form',

            countiesList: [
                {
                    location: 'Medina County',
                    src: medinaCounty,
                    idName: 'medinaCounty',
                    subtitle: 'Find your perfect retreat in Medina County, Ohio, offering peaceful living with easy access to urban amenities.',
                    affordability: 4.1,
                    affordablityText: "Medina County offers a balance of affordable living options amidst rising market trends.",
                    parksRec: 4.5,
                    parksRecText: "Boasts expansive parks and recreational areas, perfect for outdoor enthusiasts and families.",
                    schools: 5,
                    schoolsText: "Home to highly rated public schools, providing strong educational foundations for students.",
                    shoppingNightlife: 3.8,
                    shoppingNightlifeText: "Offers a modest variety of shopping destinations and a growing nightlife scene.",
                    summaryLine1: "Medina County, nestled in Ohio, offers residents a scenic blend of rolling hills and spacious landscapes, making it a perfect spot for those seeking a suburban lifestyle with plenty of outdoor amenities. The county is characterized by its affordable housing market, well-maintained parks, and family-friendly atmosphere, appealing to new homeowners and growing families alike. The local schools are highly regarded, providing excellent educational opportunities that contribute to the community's appeal.",
                    summaryLine2: "Economically, Medina County benefits from a diverse industrial base and a robust agricultural sector, which supports local employment and economic stability. The area's shopping venues range from quaint downtown boutiques to well-known retail establishments, while the nightlife is modest but growing, with new restaurants and cafes adding to the vibrant local scene."
                },
                {
                    location: 'Cuyahoga Falls',
                    src: cuyahogaCounty,
                    idName: 'cuyahogaCounty',
                    subtitle: 'Explore the charm of Cuyahoga Falls, Ohio, with its welcoming neighborhoods and thriving local life.',
                    affordability: 3.8,
                    affordablityText: "Cuyahoga Falls presents competitive housing prices, making it a viable option for first-time homebuyers.",
                    parksRec: 5,
                    parksRecText: "Rich in natural beauty and recreational facilities, it's a haven for nature lovers and active lifestyles.",
                    schools: 4,
                    schoolsText: "Features high-level educational institutions with strong community support.",
                    shoppingNightlife: 4,
                    shoppingNightlifeText: "Vibrant local shopping areas and lively nightlife cater well to both residents and visitors",
                    summaryLine1: "Cuyahoga Falls, located along the Cuyahoga River in northeastern Ohio, is known for its vibrant community and beautiful riverfront views. The city combines the charm of historic development with modern amenities, creating a lively atmosphere that attracts both young professionals and families. It stands out for its affordability, making it a great place for first-time homebuyers looking for value in a tight housing market.",
                    summaryLine2: "The city is also celebrated for its extensive park system, featuring waterfalls, hiking trails, and picnic areas that are perfect for recreational activities throughout the year. Schools in Cuyahoga Falls are committed to providing high-quality education and boast numerous programs aimed at student success. Shopping and nightlife in the city offer a mix of traditional and contemporary options, from local diners and bars to trendy shops and theaters, ensuring there's always something exciting to explore."
                },
                {
                    location: 'Summit County',
                    src: summitCounty,
                    idName: 'summitCounty',
                    subtitle: 'Discover your dream home in Summit County, Ohio, where scenic beauty meets vibrant community living.',
                    affordability: 3.4,
                    affordablityText: "While slightly higher in cost, Summit County offers diverse living options for various budgets.",
                    parksRec: 5,
                    parksRecText: "Exceptional parks and recreational offerings make Summit County a top choice for outdoor activities.",
                    schools: 4.5,
                    schoolsText: "Known for its top-performing schools and commitment to educational excellence.",
                    shoppingNightlife: 4.1,
                    shoppingNightlifeText: "Summit County boasts a thriving shopping scene and energetic nightlife that rivals major metropolitan areas.",
                    summaryLine1: "Summit County is an essential hub of activity in Ohio, known for its economic vitality and cultural diversity. The county features a mix of urban and suburban environments, offering everything from high-rise apartments to suburban homes, catering to a diverse range of preferences and budgets. The area's parks and recreation facilities are among the best in the state, providing countless opportunities for outdoor adventures and community events.",
                    summaryLine2: "Education in Summit County is top-notch, with numerous schools achieving recognition for academic excellence and innovation. The county also serves as a shopping and nightlife epicenter, with abundant shopping malls, boutiques, restaurants, and entertainment venues that draw crowds from across the region. The vibrant mix of activities and amenities makes Summit County a dynamic place to live and work, appealing to residents of all ages and backgrounds."
                }
            ],
        }
    },

}

</script>

<style scoped>
@media (max-width: 786px)  {
    .paragraph {
        margin: 2em 0 3.5em 0 !important;
    }

    .content {
        margin-top: 3em !important;
    }

    .image-content {
        flex-direction: column !important;
    }

    .block-image {
        height: 200px !important;
    }

    h2 {
        font-size: 42px !important;
        line-height: 1em;
    }

    h4 {
        font-size: 42px !important;
    }

    h3 {
        font-size: 42px !important;
    }

    p {
        font-size: 17px !important;
        line-height: 1.3em;
        width: 100% !important;
    }

    p1 {
        line-height: 1.2em !important;
    }

    p4 { 
        font-size: 17px !important;
    }

    p6 { 
        font-size: 17px !important;
    }
}

@media (max-width: 1000px)  {
    .block-image {
        max-height: 250px;
    }
}

button {
    color: white;
    background-color: #b31919;
    border: none;
    border-radius: 35px;
    font-weight: 800;
    padding: .5em 1em;
}

.paragraph-container {
    max-width: 1350px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1.5em .7em;
}

.paragraph {
    background-color: #efefef;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 2em 0 5em 0;
}

.inline-summary {
    margin-top: 1em;
}

.inline {
    text-align: left;
}

.summary {
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: left;
}

.image-content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    max-width: 1350px;
    padding: 0 .7em;
    gap: 20px;
}

.block-image {
    height: 350px;
}

.content {
    margin-top: 5em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.location-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

ul{
    margin: 0;
    padding: 0;
    width: 100%;
}

.bg-image {
    background: url("@/landing-pages/assets/summit-county-ohio.png");
    box-shadow: inset 0 0 0 1100px #00000076;
    position: relative;
    background-position: 0px 800px;
    background-size: cover no-repeat;
    height: 24em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-attachment: fixed;
}

.blocks {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    max-width: 1350px;
    padding: 4em .7em 0 .7em;
}

p6 {
    text-align: left;
    margin-bottom: 1em;
    font-size: 17px;

}

p4 {
    font-weight: 800;
    line-height: 1.2em;
    font-size: 17px;
}

h5 {
    color: rgb(185, 28, 14);
    font-size: 17px;
    font-weight: 800;
    line-height: 1.5rem;
    display: inline-block;
    margin-right: .3em;
}

h6 {
    color: #062850;
    font-size: 17px;
    font-weight: 800;
    line-height: 1.5rem;
    display: inline-block;
}

p1 {
    color: rgb(173, 173, 173);
    width: 100%;
    font-size: 19px;
    line-height: 1.3;
    font-weight: 800;
}

h1 {
    color: rgb(6, 40, 80);
    width: 100%;
    font-size: 26px;
    line-height: 1.3;
    font-weight: 800;
}

h2 {
    font-weight: 500;
    font-size: 58px;
    line-height: 1em;
    font-family: 'Inter';
    margin: 0;
    color: white;
    text-align: left;
}

h4 {
    font-family: 'Playfair-Display';
    font-size: 58px;
    font-weight: 600;
    color: #b31919;
    margin: 0;
    text-align: left;
    display: inline-block;
    margin-right: .3em;
    /* text-shadow: 0px 1px 3px rgb(0, 0, 0); */
}

h3 {
    font-family: 'Playfair-Display';
    font-size: 58px;
    font-weight: 600;
    color: #062850;
    margin: 0;
    text-align: left;
    display: inline-block;
}

p {
    font-weight: 500;
    font-size: 24px;
    line-height: 1.3em;
    font-family: 'Inter';
    color: white;
    width: 60%;
    margin-top: 1em;
    text-align: left;
}

</style>