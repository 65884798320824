<template>
    <nav class="navbar" >
      <div class="container">
        <div class="logo">
          <router-link class="menu-item" 
          :to="{ path: '/about'}"><img :src="navLogo" alt="liberty.logo"></router-link>
        </div>
        
        <div class="menu-toggle">
          <router-link class="menu-item" 
          :to="{ path: '/purchase-form'}"><button>Get Approved</button></router-link>
        </div>
  
        <div class="menu-items">
          <div class="dropdown-menu-item" @mouseover="isDropdownVisible = true" @mouseleave="isDropdownVisible = false">
              <div class="menu-item-with-icon">
                <router-link class="menu-item" 
                 :to="{ path: '/buy'}">Buy<i class="fa-solid fa-caret-down"></i></router-link>
              </div>

              <div class="dropdown" v-if="isDropdownVisible" @mouseover="isDropdownVisible = true" @mouseleave="isDropdownVisible = false">
                <div class="dropdown-flex">
                  <router-link class="menu-item-dropdown" :to="{ path: '/conventional-loans'}">Conventional Loans</router-link>
                  <router-link class="menu-item-dropdown" :to="{ path: '/fha-loans'}">FHA Loans</router-link>
                  <router-link class="menu-item-dropdown" :to="{ path: '/fixed-interest-rate-loans'}">Fixed Interest Rate Loans</router-link>
                  <router-link class="menu-item-dropdown" :to="{ path: '/home-ready-loans'}">Fannie Mae Home Ready</router-link>
                  <router-link class="menu-item-dropdown" :to="{ path: '/manufactured-home-loans'}">Manufactured Home Loans</router-link>
                  <router-link class="menu-item-dropdown" :to="{ path: '/renovation-loans'}">Renovation Loans</router-link>
                  <router-link class="menu-item-dropdown" :to="{ path: '/usda-loans'}">USDA Loans</router-link>
                  <router-link class="menu-item-dropdown" :to="{ path: '/va-loans'}">VA Loans</router-link>
                </div>
              </div>
          </div>
          
          <router-link class="menu-item" 
          :to="{ path: '/refinance'}">Refinance</router-link>
  
          <router-link class="menu-item" 
          :to="{ path: '/cash-out'}">Cash-Out</router-link>

          <div class="dropdown-menu-item" @mouseover="isDropdownVisible = true" @mouseleave="isDropdownVisible = false">
            <router-link class="menu-item" 
            :to="{ path: '/resource-center'}">Free Resources<i class="fa-solid fa-caret-down"></i></router-link>
              
              <div class="dropdown1" v-if="isDropdownVisible" @mouseover="isDropdownVisible = true" @mouseleave="isDropdownVisible = false">
                <div class="dropdown-flex">
                  <router-link class="menu-item-dropdown" :to="{ path: '/blogs'}">Helpful Articles</router-link>
                  <router-link class="menu-item-dropdown" :to="{ path: '/videos'}">Mortgage Videos</router-link>
                  <router-link class="menu-item-dropdown" :to="{ path: '/quiz-and-cases'}">Case Scenarios</router-link>
                  <router-link class="menu-item-dropdown" :to="{ path: '/quiz-and-cases'}">Quizzes</router-link>
                </div>
              </div>
              
          </div>

          <router-link class="menu-item" 
          :to="{ path: '/about'}">About Us</router-link>
  
          <router-link class="menu-item" 
          :to="{ path: '/find-a-loan-officer'}">Find an Officer</router-link>
  
          <router-link class="menu-item" 
          :to="{ path: '/purchase-form'}"><button>Get Approved</button></router-link>
  
        </div>
      </div>
    </nav>
</template>

<script>
import navLogo from '@/landing-pages/assets/lhm_hori_logo_bluebell_redtype.svg';
import navLogoWhite from '@/landing-pages/assets/lhm_hori_logo_white-transparent.png';


export default {
  name: 'Navigation',
  data() {
    return {
      navLogo: navLogo,
      navLogoWhite: navLogoWhite,
      isDropdownVisible: null
    };
  },

  props: {
      purchaseURL: {
          type: String,
          required: true
      },
      purchForm: {
          type: String,
          required: true
      },
      refiURL: {
          type: String,
          required: true
      },
      cashOutURL: {
          type: String,
          required: true
      },
      formURL: {
          type: String,
          required: true
      },
  }
}
</script>

<style scoped>
@media screen and (max-width: 1157px) {
  .logo img {
  width: 150px !important;
}

  .container {
    padding-left: 5px;
  }

  .menu-items {
    display: none !important;
  }

  .menu-toggle {
    display: block !important;
    position: absolute;
    right: 20px;
    z-index: 1000;
  }

  .mobile-menu-open .menu-items {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 60px;
    left: 0;
    right: 0;
    z-index: 999;
    background-color: black;
    padding: 20px;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.11);
  }

  button {
    margin-right: -2em !important;
    font-size: 14px !important;
    padding: .5em 1.2em !important;
  }

}


.menu-items {
  display: flex;
  align-items: center;
  gap: 15px;
}

.menu-item {
  margin-right: 20px;
  font-weight: 700;
  color: #062850;
  cursor: pointer;
  text-decoration: none;
  display: flex;
  align-items: center;
  position: relative;
}

i {
  color: #062850;
}

.menu-item-dropdown {
  font-weight: 700;
  color: #062850;
  cursor: pointer;
  text-decoration: none;
}

.menu-item-with-icon {
    display: flex;
    align-items: center;
}

.fa-solid.fa-caret-down {
    color: #062850;
    margin-top: 0px;
    margin-left: .5em;

}

.dropdown-menu-item {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 78px;
  min-width: 90px;
}

.dropdown-menu-item:hover .dropdown {
  display: block;
}

.dropdown-menu-item:hover .dropdown1 {
  display: block;
}

.dropdown-flex {
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.dropdown {
  display: none;
  position: absolute;
  margin-top: 27.9em;
  padding: .5em;
  width: 200px;
  z-index: 1000;
  background-color: rgba(255, 255, 255, 0.932); 
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
}

.dropdown1 {
  display: none;
  position: absolute;
  margin-top: 14.9em;
  padding: .5em;
  width: 200px;
  z-index: 1000;
  background-color: rgba(255, 255, 255, 0.932); 
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
}

.find-officer {
  background-color: #062850;
  color: white;
  margin-right: -1.8em;
}

.case-button {
  background-color: #062850;
  color: white;
  border: none;
  border-radius: 35px;
  font-weight: 700;
  padding: .5em 1.2em;
  font-family: 'Merriweather';
  font-size: 15px;
  margin-right: -2em;
}

button {
  background-color: #b31919;
  color: white;
  border: none;
  border-radius: 5px;
  font-weight: 700;
  padding: .5em 2em;
  font-family: 'Merriweather';
  font-size: 15px;
}

i{
  color: white;
  margin-top:50%;
}

.navbar {
  position: fixed; 
  top: 0; 
  left: 0;
  right: 0;
  z-index: 1000;
  max-height: 100px;
  display: flex;
  justify-content: center;
  --bs-navbar-padding-y: 0rem;
  background-color: rgba(255, 255, 255, 0.458); /* 
  backdrop-filter: blur(10px); /* Adjust the blur radius as needed */
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
}

.container {
  max-width: 1380px;
  margin: 0em 1em;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo img {
  width: 210px;
}

.menu-toggle {
  display: none;
}

</style> 
