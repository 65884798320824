<template>
    <div class="main-nav">
        <RealEstateNav
        realEstateURL="/medina"
        realEstateForm="/medina-real-estate-form"
        realEstateCounties="/medina-counties"/>
    </div>
    <div class="modules-navigation">
        <div class="modules-nav-container">
            <div class="module-header">
                <h4>Current Module: </h4>
                <h5>Marriage & Joint Scenarios</h5>
            </div>
            <!-- <div class="">
                <div class="modules-menu">
                    <i class="fa-solid fa-bars"></i>
                </div>
            </div> -->
        </div>
    </div>
    
    <div class="container">
    <div class="fix-height">
        <div class="fix-width">
            <!-- <CaseAndQuizzes/> -->
            <Stories continueURL="/medina-real-estate-form"/>
        </div>
    </div>
    </div>
</template>

<script script>
import RealEstateNav from '@/landing-pages/components/navigations/RealEstateNav.vue'
import CaseAndQuizzes from '@/landing-pages/components/modules/CaseAndQuizzes.vue';
import Stories from '@/landing-pages/components/modules/Stories.vue'

export default{
    components: {
        RealEstateNav,
        CaseAndQuizzes,
        Stories
    }

}

</script>

<style scoped>
@media screen and (max-width: 920px) {
    .fix-width {
        flex-direction: column !important;
    }


    h4, h5 {
        font-size: 16px !important;
    }
}

@media (min-width: 631px) and (max-width: 920px)
{

    .container {
        height: 900px !important;
    }

}
@media screen and (max-width: 1000px) {
    .main-nav {
        height: 70px !important;
    }
}

@media screen and (max-width: 630px) {

    .container {
        height: 1100px !important;
    }


    .modules-navigation {
        padding: 1em 0em !important;
    }
}



.main-nav {
    height: 80px;
}

.module-button {
    padding: .5em 1em;
    background-color: white;
    color: #b31919;
    font-weight: 800;
    border-radius: 25px;
    border: none;
    line-height: 1.1em;
}

.module-button2 {
    padding: .5em 1em;
    background-color: white;
    color: #062850;
    font-weight: 800;
    border-radius: 25px;
    border: none;
    line-height: 1.1em;
}

.active {
    background-color: white !important;
    border: 1px solid #b31919 !important;
}

.active2 {
    background-color: white !important;
    border: 1px solid #062850 !important;
}

.hactive {
    color: black !important;
    font-weight: 700 !important;
}

h4 {
    font-size: 22px;
    color: #062850;
    padding-right: .35em;
}

h5 {
    font-size: 22px;
    color: #b31919;
}

h4, h5 {
    margin: 0;
    text-align: left;
    text-wrap: nowrap;
    font-weight: 800;
}

.module-header {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.modules-nav-container {
    display: flex;
    max-width: 1350px;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 0 .7em;
}

.modules-navigation {
    background-color: #d5e4f6;
    padding: 1.2em 0em;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.module-background {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    width: 100%;
    padding: 0em .7em;
}

h1 {
    text-align: left;
    font-size: 16px;
    color: white;
    font-weight: 600;
    margin: 0;
    max-width: 300px;
}

.module1 {
    padding: .8em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #b31919;
    margin: .2em 0;
    border-radius: 10px;
    height: 60px;
}

.module2 {
    padding: .8em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #062850;
    margin: .2em 0;
    border-radius: 10px;
    height: 60px;
}

.flex-contain {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: relative;
    padding: 0em .5em;
}


/* .flex-contain:not(:last-child)::after {
  content: "";
  position: absolute;
  right: 0; 
  top: 0; 
  bottom: 0; 
  width: 2px;
  background-color: #ccc; 
} */

.modules-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 40px;
}

.modules {
    display: flex; 
    justify-content: flex-end;
    align-items: flex-start;   
    width: 40%;
    background-color: none;
    height: 100%;
    overflow-y: auto;
    padding-top: 2em;
    box-shadow: -6px 2px 10px rgba(0, 0, 0, 0.142);
}

.fix-width {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    gap: 50px;
    width: 100%;
    height: 100%;

}

.fix-height{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;

}

p {
    font-size: 16px;
    font-weight: 700;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}


.modules-menu{
    display: inline-flex;
    text-decoration: none;
    color: black;
}

.container {
    width: 100%;
    max-width: 100%;
    height: 700px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-left: 0em; 
    padding-right: 0em;
}


</style>