<template>
    <div class="info-background">
        <div class="info-rows">
            <div style="text-align: left;">
                <h1>Helpful Topics</h1>
            </div>
            <div class="info-blocks">
            <router-link class="menu-item" :to="purchForm">
                <div class="cards">
                    <div class="card-headers">
                        <h4>Look Before You Lock: Why Rates Matter</h4>
                        <p2>Apply Online. Fast approval.</p2>
                        <p3>Almost half of all homebuyers don’t compare mortgage lenders before locking a rate. But small rate differences can add up to big savings.</p3>
                    </div>

                    <div class="block-image">
                        <img :src="coupleHome" alt="coupleHome">
                    </div>
                    <button class="col-button2">Get Approved Now</button>
                </div>
            </router-link>
            <router-link class="menu-item" :to="refiURL">
                <div class="cards">
                    <div class="card-headers">
                        <h4>Look Before You Lock: Why Rates Matter</h4>
                        <p2>Apply Online. Fast approval.</p2>
                        <p3>Almost half of all homebuyers don’t compare mortgage lenders before locking a rate. But small rate differences can add up to big savings.</p3>
                    </div>

                    <div class="block-image">
                        <img :src="suburbHouse" alt="suburbHouse">
                    </div>
                    <button class="col-button">Apply & Save</button>
                </div>
            </router-link>
            <div>
                <router-link class="menu-item" :to="cashOutURL">
                    <div class="cards">
                        <div class="card-headers">
                            <h4>Look Before You Lock: Why Rates Matter</h4>
                            <p2>Apply Online. Fast approval.</p2>
                            <p3>Almost half of all homebuyers don’t compare mortgage lenders before locking a rate. But small rate differences can add up to big savings.</p3>
                        </div>

                        <div class="block-image">
                            <img :src="realtor" alt="summitCounty">
                        </div>
                        <button class="col-button">Get Cash Fast</button>
                    </div>
                </router-link>
            </div>
            </div>
        </div>
    </div>
</template>

<script>
import coupleHome from '@/landing-pages/assets/working on computer.png'
import suburbHouse from '@/landing-pages/assets/Suburb House.png'
import realtor from '@/landing-pages/assets/couple-computer.png'

export default {
    props: {
        purchForm: {
            type: String,
            required: true
        },
        refiURL: {
            type: String,
            required: true
        },
        cashOutURL: {
            type: String,
            required: true
        }
    },
    
    data (){
        return {
            coupleHome: coupleHome,
            suburbHouse: suburbHouse,
            realtor: realtor
        }
    },

}

</script>

<style scoped>
@media screen and (max-width: 460px) {
    .block-image {
        height: 170px !important;
    }

    .info-blocks {
        display: flex !important;
        flex-direction: column !important;
    }

    .card-headers {
        height: 235px !important;
    }

}

@media (min-width: 461px) and (max-width: 680px) {
    .info-blocks {
        display: flex !important;
        flex-direction: column !important;
    }

    .block-image {
        min-height: 280px !important;
    }
}

@media (min-width: 681px) and (max-width: 770px) {
    .card-headers {
        height: 250px !important;
    }
}

@media (min-width: 461px) and (max-width: 768px) {
    h2 {
    font-size: 38px !important;
    }

    h3 {
        font-size: 38px !important;
    }

    p {
        font-size: 19px !important;
        min-width: 100% !important;
    }

    .block-image {
        max-height: 200px;
    }

    p2 {
        font-size: 16px !important;
        line-height: 1.3em;
        font-weight: 500 !important;
    }


}

@media (min-width: 767px) and (max-width: 1000px) {
    p {
        width: 80% !important;
    }

    .block-image {
        max-height: 230px;
        border-radius: 5px !important;
    }

}

@media (max-width: 1000px) {
    .info-blocks {
        grid-template-columns: 1fr 1fr !important;
    }
}

.card-headers {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    height: 200px;
}

.col-button2 {
    background-color: #b31919;
    color: white;
    border: none;
    border-radius: 8px;
    padding: 1em;
    font-weight: 800;
    margin-top: .7em;
}

.col-button {
    background-color: #062850;
    color: white;
    border: none;
    border-radius: 8px;
    padding: 1em;
    font-weight: 800;
    margin-top: .7em;
}

p3 {
    color: black;
    margin-left: 0em;
    text-align: left;
    line-height: 1.3em;
}

.inline {
    display: flex;
    flex-direction: row;
    text-align: left;
    margin: .3em 0em;
}

i {
    background-color: #bfd8f4;
    border-radius: 35px;
    color: #023774;
    width: 30px;
    height: 30px;
    padding: .5em;
}

.menu-item {
    text-decoration: none !important;
}

h4{
    text-align: left;
    color: #b31919;
    font-weight: 700;
    font-size: 25px;
    margin-top: 1em;
}

p2 {
    font-size: 15px;
    font-weight: 800;
    text-align: left;
    line-height: 1.25em;
    text-decoration: none;
    color: black;
    margin-bottom: 1em;
}

.info-blocks {
    display: grid;
    width: 100%;
    max-width: 1350px;
    justify-content: center;
    flex: 0 0 100%;
    flex: 1;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 3em;
    padding: 0em 0em;
}

.cards {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.block-image {
    width: 100%;
    height: 250px;
    border-radius: 8px;
    overflow: hidden; 
    position: relative;
}

img{
    width: 100%; 
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.info-background {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    background-size: cover;
    justify-content: flex-start;
    padding: 4em .7em 8em .7em;
}


h2 {
    font-weight: 500;
    font-size: 58px;
    line-height: 1em;
    font-family: 'Inter';
    margin: 0;
    color: #053873;
}

h3 {
    font-family: 'Playfair-Display';
    font-size: 58px;
    font-weight: 600;
    color: #b31919;
    margin: 0;
}

h1 {
    font-size: 55px;
    font-weight: 700;
    color: #8da7c2;
}

p {
    font-weight: 500;
    font-size: 24px;
    line-height: 1.3em;
    font-family: 'Inter';
    color: #7c7c80;
    width: 60%;
    margin-top: 1em;
}
</style>