<template>
    <form @submit.prevent="submitForm">
        <div class="step" :class="(providedRefiCounter === 1) ? 'active': ''">
        <div class="flex-class">
            <h4>What would you like to do with your refinance?</h4>
                <div class="home-type">
                    <span value="lowerPayment" class="home-type-selection" @click="setRefiReason('lower-payment')" :class=" (refiReason === 'lower-payment') ? 'selected' : ''">            
                        <img class="responsive-img" :src="lowerRate" alt=""><p2>Lower my monthly payment</p2>
                    </span>
                    <span value="shorten-term" class="home-type-selection" @click="setRefiReason('shorten-term')" :class=" (refiReason === 'shorten-term') ? 'selected' : ''">            
                        <img class="responsive-img" :src="shortenTerm" alt=""><p2>Shorten my mortgage term</p2>
                    </span>
                    <span value="cash-out" class="home-type-selection" @click="setRefiReason('cash-out')" :class=" (refiReason === 'cash-out') ? 'selected' : ''">               
                        <img class="responsive-img" :src="cashOut" alt=""><p2>Cash-out refinance</p2>
                    </span>
                </div>
            <span v-if="inputInvalid === true" class="warning">Please make a selection!</span>
            <div class="buttons-area">
                <span class="button" @click="checkRefiReason(), reactRefiReason()">Next</span>
            </div>
        </div>
        </div>
        <div class="step" :class="cashOutChecked ? (providedRefiCounter === 2 ? 'active' : '') : ''">
        <div class="flex-class">
            <h4>What is the purpose of your cash-out refinance?</h4>
                <div class="home-type">
                    <span value="homeImprovement" class="home-type-selection" @click="setCashOutReason('home-improvement')" :class=" (cashoutReason === 'home-improvement') ? 'selected' : ''">            
                        <p2>Home improvement</p2>
                    </span>
                    <span value="consolidateDebt" class="home-type-selection" @click="setCashOutReason('consolidate-debt')" :class=" (cashoutReason === 'consolidate-debt') ? 'selected' : ''">            
                        <p2>Consolidate debt</p2>
                    </span>
                    <span value="buyAnotherProperty" class="home-type-selection" @click="setCashOutReason('buy-another-property')" :class=" (cashoutReason === 'buy-another-property') ? 'selected' : ''">               
                        <p2>Buy another property</p2>
                    </span>
                    <span value="other" class="home-type-selection" @click="setCashOutReason('other')" :class=" (cashoutReason === 'other') ? 'selected' : ''">               
                        <p2>Other</p2>
                    </span>
                </div>
            <span v-if="inputInvalid === true" class="warning">Please make a selection!</span>
            <div class="buttons-area">
                <span class="button" @click="sendCounterDown()">Prev</span>
                <span class="button" @click="checkCashOutReason()">Next</span>
            </div>
        </div>
        </div>
        <div class="step" :class="cashOutChecked ? (providedRefiCounter === 3 ? 'active' : '') : (providedRefiCounter === 2 ? 'active' : '')">
            <div class="flex-class">
                <h5>Estimated property value?</h5>
                <div class="slider-container">
                    <span class="rangeValue"> ${{ formattedHomeValue() }}</span>
                    <input v-model="homeValue" class="mySlider" type="range" min="10000" max="1000000" step="1000">
                    
                </div>
                <br>
                <h5>Estimated amount owed on property?</h5>
                <div class="slider-container">
                    <span class="rangeValue2">${{ amountOwedCalc(amountOwed) }} | {{ amountOwed }}% </span>
                    <input v-model="amountOwed" class="mySlider2" type="range" min="0" max="100" step="5">
            
                </div>
                <br>
                <h5>ZIP code of property?</h5>
                    <div class="fields">
                        <input class="typed" type="number" id="zip" v-model="zipCode">
                    </div>
            </div>
            <span v-if="inputInvalid === true" class="warning">Please enter a valid zip code</span>
            <div class="buttons-area">
                <span class="button" @click="sendCounterDown()">Prev</span>
                <span class="button" @click="checkZip()">Next</span>
            </div>
        </div>
        <div class="step" :class="cashOutChecked ? (providedRefiCounter === 4 ? 'active' : '') : (providedRefiCounter === 3 ? 'active' : '')">
            <h5 style="margin-bottom: .5em;">Married?</h5>
                <div class="purch-refi">
                    <span class="yes-no-selection" @click="maritialStatus('yes')" :class=" (married === 'yes') ? 'selected' : ''">
                        <img :src="yes" alt="yes"><p>Yes</p>
                    </span>
                    <span class="yes-no-selection" @click="maritialStatus('no')" :class=" (married === 'no') ? 'selected' : ''"><img :src="no" alt="no"><p>No</p>
                    </span>
                </div>
            <h5 style="margin-top: 1.3em; margin-bottom: .5em;">Have you served in the military?</h5>
                <div class="purch-refi">
                    <span class="yes-no-selection" @click="checkMilitary('yes')" :class=" (military === 'yes') ? 'selected' : ''">
                        <img :src="yes" alt="yes"><p>Yes</p>
                    </span>
                    <span class="yes-no-selection" @click="checkMilitary('no')" :class=" (military === 'no') ? 'selected' : ''"><img :src="no" alt="no"><p>No</p>
                    </span>
                </div>
            <span v-if="inputInvalid === true" class="warning">Please make a selection for both!</span>
            <div class="buttons-area">
                <span class="button" @click="sendCounterDown()">Prev</span>
                <span class="button" @click="checkMarriedAndMilitary()">Next</span>
            </div>
        </div>
        <div class="step" :class="cashOutChecked ? (providedRefiCounter === 5 ? 'active' : '') : (providedRefiCounter === 4 ? 'active' : '')">
            <div class="centering2">
            <h5 style="margin-bottom: .8em;">Estimate your credit score</h5>
                <div class="slider-container">
                    <input v-model="creditScore" class="mySlider" type="range" min="500" max="800" step="10">
                    <span class="rangeValue"> {{ creditScore }}</span>
                </div>
            <h5 style="margin-top: 1.2em; margin-bottom: .5em;">Any prior forclosures or bankruptcies?</h5>
                <div class="purch-refi">
                    <span class="yes-no-selection" @click="wasBankrupt('yes')" :class=" (bankruptcy === 'yes') ? 'selected' : ''">
                        <img :src="yes" alt="yes"><p>Yes</p>
                    </span>
                    <span class="yes-no-selection" @click="wasBankrupt('no')" :class=" (bankruptcy === 'no') ? 'selected' : ''">
                        <img :src="no" alt="no"><p>No</p>
                    </span>
                </div>
            </div>
            <span v-if="inputInvalid === true" class="warning">Please make a selection!</span>
            <div class="buttons-area">
                <span class="button" @click="sendCounterDown()">Prev</span>
                <span class="button" @click="checkBankruptcy()">Next</span>
            </div>
        </div>
        <div class="step" :class="cashOutChecked ? (providedRefiCounter === 6 ? 'active' : '') : (providedRefiCounter === 5 ? 'active' : '')">
            <h5>What is your current employment status?</h5>
                <div class="purch-refi">
                    <span class="long-text-selection" @click="employmentSituation('employed')" :class=" (employment === 'employed') ? 'selected' : ''">
                        <img :src="employed" alt="employed" class="grid-image"><p class="selection-text">Employed</p>
                    </span>
                    <span class="long-text-selection" @click="employmentSituation('unemployed')" :class=" (employment === 'unemployed') ? 'selected' : ''"><img :src="unemployed" alt="unemployed" class="grid-image"><p class="selection-text">Unemployed</p>
                    </span>
                </div>
                <div class="purch-refi" style="margin-top: .5em;">
                    <span class="long-text-selection" @click="employmentSituation('self-employed')" :class=" (employment === 'self-employed') ? 'selected' : ''">
                        <img :src="selfEmployed" alt="selfEmployed" class="grid-image"><p class="selection-text">Self-employed</p>
                    </span>
                    <span class="long-text-selection" @click="employmentSituation('public-service')" :class=" (employment === 'public-service') ? 'selected' : ''"><img :src="publicServiceJobImg" alt="publicServiceJobImg" class="grid-image"><p class="selection-text" >Military, teacher or public service</p>
                    </span>
                </div>
            <span v-if="inputInvalid === true" class="warning">Please make a selection!</span>
            <div class="buttons-area">
                <span class="button" @click="sendCounterDown()">Prev</span>
                <span class="button" @click="checkEmployment()">Next</span>
            </div>
        </div>
        <div class="step" :class="cashOutChecked ? (providedRefiCounter === 7 ? 'active' : '') : (providedRefiCounter === 6 ? 'active' : '')">
            <div class="centering">
            <h5>Gross household income?</h5>
                <select id="income" name="income" v-model="income" class="income-dropdown">
                    <option value="0-25,000">$0-25,000</option>
                    <option value="25,000-50,000">$25,000-$50,000</option>
                    <option value="25,000-50,000">$25,000-$50,000</option>
                    <option value="25,000-50,000">$50,000-$75,000</option>
                    <option value="25,000-50,000">$75,000-$100,000</option>
                    <option value="25,000-50,000">$100,000-$125,000</option>
                    <option value="25,000-50,000">$125,000-$150,000</option>
                    <option value="25,000-50,000">$150,000-$175,000</option>
                    <option value="25,000-50,000">$175,000-$200,000</option>
                    <option value="25,000-50,000">$200,000-$225,000</option>
                    <option value="25,000-50,000">$225,000-$250,000</option>
                    <option value="25,000-50,000">$250,000-$275,000</option>
                    <option value="275,000+">$275,000+</option>
                </select>
            </div>
            <span v-if="inputInvalid === true" class="warning">Please make a selection!</span>
            <div class="buttons-area">
                <span class="button" @click="sendCounterDown()">Prev</span>
                <span class="button" @click="checkIncome()">Next</span>
            </div>
        </div>
        <div class="step" :class="cashOutChecked ? (providedRefiCounter === 8 ? 'active' : '') : (providedRefiCounter === 7 ? 'active' : '')">
            <h5>Contact info</h5>
            <div class="contacts-area">
                <div class="contact-fields">
                    <label for="l-name">First Name</label>
                    <input type="text" id="f-name" v-model="firstName">
                </div>
                <div class="contact-fields">
                    <label for="l-name">Last Name</label>
                    <input type="text" id="l-name" v-model="lastName">
                </div>
                <div class="contact-fields">
                    <label for="email">Email</label>
                    <input type="text" id="email" v-model="email">
                </div>
                <div class="contact-fields">
                    <label for="phone">Phone</label>
                    <input type="tel" id="phone" v-model="phone" min="10" max="10" @input="acceptNumber">
                    <p class="disclaimer">By submitting, you agree to be reached at the information provided regarding your inquiry. Don't worry, we HATE SPAM as much as you!</p>
                </div>
            </div>
    
            <span v-if="inputInvalid === true" class="warning">Please complete fields!</span>
            <div style="margin-top: .5em;" class="buttons-area">
                <span class="button" @click="sendCounterDown()">Prev</span>
                <span class="button" @click="checkContact()">Next</span>
            </div>
        </div>
    
        <div class="step" :class="cashOutChecked ? (providedRefiCounter === 9 ? 'active' : '') : (providedRefiCounter === 8 ? 'active' : '')">
            <h5>OPTIONAL: Comments?</h5>
            <div class="comments-area">
                <textarea type="text" id="comments" v-model="comments" min="10" max="10" placeholder="Anything you'd like to tell us?"  class="comments" rows="5"></textarea>
                <div id="purch-form-captcha" class="g-recaptcha"></div>
            </div>
            <span v-if="inputInvalid === true" class="warning">Please retry the reCAPTCHA.</span>
            <div style="margin-top: .5em;" class="buttons-area">
                <span class="button" @click="sendCounterDown()">Prev</span>
                <button class="button" type="submit">Submit</button>
            </div>
        </div>
    </form>
    </template>
    
    <script>
        import publicServiceJobImg from '@/landing-pages/assets/public-service-job.png'
        import cashOut from '@/landing-pages/assets/Cash-out.png'
        import shortenTerm from '@/landing-pages/assets/Refinance Homes.png'
        import lowerRate from '@/landing-pages/assets/lower-interest-rate.png'
        import yes from '@/landing-pages/assets/check-yes.png'
        import no from '@/landing-pages/assets/x-no.png'
        import signedLetter from '@/landing-pages/assets/signed-offer-letter.png'
        import foundHome from '@/landing-pages/assets/found-home.png'
        import buyingInMonths from '@/landing-pages/assets/buying-in-months.png'
        import researchingOptions from '@/landing-pages/assets/researching-options.png'
        import employed from '@/landing-pages/assets/employed.png'
        import unemployed from '@/landing-pages/assets/unemployed.png'
        import selfEmployed from '@/landing-pages/assets/self-employed.png'
        
    
    export default{
        inject: ['getRefiSteps', 'getRefiCounter'],
    
        computed: {
            providedRefiSteps() {
                return this.getRefiSteps();
            },
            providedRefiCounter() {
                return this.getRefiCounter();
            },
        },
    
        data(){
            return{
                homeValue: 400000,
                amountOwed: 50,
                creditScore: 650,
                refiReason: null,
                cashoutReason: null,
                zipCode: null,
                married: null,
                military: null,
                bankruptcy: null,
                employment: null,
                income: null,
                firstName: null,
                lastName: null,
                email: null,
                phone: null,
                inputInvalid: false,
                comments: '',
    
                publicServiceJobImg: publicServiceJobImg,
                cashOut: cashOut,
                shortenTerm: shortenTerm,
                lowerRate,
                yes: yes,
                no: no,
                signedLetter: signedLetter,
                foundHome: foundHome,
                buyingInMonths: buyingInMonths,
                researchingOptions: researchingOptions,
                employed: employed,
                unemployed: unemployed,
                selfEmployed: selfEmployed,
    
                recaptchaID: null,
                cashOutChecked: null
            }
        },

        props: {
            webhook: {
                type: String,
                required: true
            },
            thankYou: {
                type: String,
                required: true
            }
    },
    
        mounted() {
            this.loadRecaptcha(() => {
                this.recaptchaID = grecaptcha.render('purch-form-captcha', {'sitekey': '6LcqAdMpAAAAAMIFZd4y8BTdSAmV_1mKFZFzeaeA'});
            });
        },
    
        methods: {
            acceptNumber() {
                var x = this.phone.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
                this.phone = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
            },

            loadRecaptcha(callback) {
                if(window.grecaptcha)
                {
                    callback();
                }
                else
                {
                    window.recaptchaCallback = callback;
                    const script = document.createElement('script');
                    script.type = 'text/javascript';
                    script.src = 'https://www.google.com/recaptcha/api.js?onload=recaptchaCallback&render=explicit';
                    script.async = true;
                    script.defer = true;
            
                    // Append the script to the document body
                    document.body.appendChild(script);
                }
            },

            reactRefiReason(){
                if (this.refiReason === 'cash-out') {
                    this.cashOutChecked = true
                } else {
                    this.cashOutChecked = false
                }
            },
    
            sendCounterUp(){
                const newCounter = this.providedRefiCounter + 1;
                this.$emit('send-counter-up', newCounter)
            },
    
            sendCounterDown(){
                const newDownCounter = this.providedRefiCounter - 1;
                this.$emit('send-counter-down', newDownCounter);
            },
    
            formattedHomeValue (){
            return new Intl.NumberFormat('en-US').format(Math.round(this.homeValue))
            },
    
            amountOwedCalc (percent) {
                return new Intl.NumberFormat('en-US').format(Math.round(this.homeValue * 0.01 * percent));
            },
    
            setRefiReason(selection){
                this.refiReason = selection;
            },

            setCashOutReason(selection){
                this.cashoutReason = selection;
            },
    
            maritialStatus(selection){
                this.married = selection;
            },
    
            checkMilitary(selection){
                this.military = selection;
            },
    
            wasBankrupt(selection){
                this.bankruptcy = selection;
            },
    
            whatsPurchSituation(selection){
                this.purchSituation = selection
            },
    
            employmentSituation(selection){
                this.employment = selection
            },
    
            checkRefiReason(){
                if (this.refiReason === null){
                    this.inputInvalid = true;
                } else {
                    const newCounter = this.providedRefiCounter + 1;
                    this.$emit('send-counter-up', newCounter);
                    this.inputInvalid = false;
                };
            },

            checkCashOutReason(){
                if (this.cashoutReason === null){
                    this.inputInvalid = true;
                } else {
                    const newCounter = this.providedRefiCounter + 1;
                    this.$emit('send-counter-up', newCounter);
                    this.inputInvalid = false;
                };
            },
    
            checkZip(){
                if (this.zipCode === null || this.zipCode < 501 || this.zipCode > 99950){
                    this.inputInvalid = true;
                } else {
                    const newCounter = this.providedRefiCounter + 1;
                    this.$emit('send-counter-up', newCounter);
                    this.inputInvalid = false;
                }
            },
    
            checkMarriedAndMilitary(){
                if (this.married === null || this.military === null){
                    this.inputInvalid = true;
                } else {
                    const newCounter = this.providedRefiCounter + 1;
                    this.$emit('send-counter-up', newCounter);
                    this.inputInvalid = false;
                }
            },
    
            checkBankruptcy(){
                if (this.bankruptcy === null){
                    this.inputInvalid = true;
                } else {
                    const newCounter = this.providedRefiCounter + 1;
                    this.$emit('send-counter-up', newCounter);
                    this.inputInvalid = false;
                }
            },
            
            checkPurchSituation(){
                if (this.purchSituation === null){
                    this.inputInvalid = true;
                } else {
                    const newCounter = this.providedRefiCounter + 1;
                    this.$emit('send-counter-up', newCounter);
                    this.inputInvalid = false;
                }
            },
    
            checkEmployment(){
                if (this.employment === null){
                    this.inputInvalid = true;
                } else {
                    const newCounter = this.providedRefiCounter + 1;
                    this.$emit('send-counter-up', newCounter);
                    this.inputInvalid = false;
                }
            },
    
            checkIncome(){
                if (this.income === null){
                    this.inputInvalid = true;
                } else {
                    const newCounter = this.providedRefiCounter + 1;
                    this.$emit('send-counter-up', newCounter);
                    this.inputInvalid = false;
                }
            },
    
            checkContact(){
                if (this.firstName === null || this.lastName === null || this.email === null || this.phone === null){
                    this.inputInvalid = true;
                } else {
                    const newCounter = this.providedRefiCounter + 1;
                    this.$emit('send-counter-up', newCounter);
                    this.inputInvalid = false;
                }
            },
    
            submitForm() {
                let captchaDone = grecaptcha.getResponse(this.recaptchaID)
                if (captchaDone === '')  {
                    this.inputInvalid = true;
                } else {
                fetch(this.webhook, {
                    method: 'POST',
                    
                    body: JSON.stringify({
                    refiReason: this.refiReason,
                    cashoutReason: this.cashoutReason,
                    homeValue: this.homeValue,
                    amountOwed: this.amountOwed,
                    zipCode: this.zipCode,
                    married: this.married,
                    military: this.military,
                    bankruptcy: this.bankruptcy,
                    employment: this.employment,
                    income: this.income,
                    firstName: this.firstName,
                    lastName: this.lastName,
                    email: this.email,
                    phone: this.phone,
                    comments: this.comments,
                    grecaptchaResponse: grecaptcha.getResponse(this.recaptchaID)
                    }),
                }).then(() => {
                    this.$router.push(this.thankYou)
                }).catch(() => {
                    this.requestFailed = true;
                });
                this.inputInvalid = false;
                }
            },
        },
    
    }
    
    </script>
    
    <style scoped>
    @media (max-width: 460px)  {
        select {
            width: 118% !important; 
        }
    
        .rangeValue,
        .rangeValue2{
            margin-left: 0em !important;
        }
    
        h5 {
            font-size: 22px !important;
        }
    
        .step-line {
            height: 3px !important;
        }
    
        .line-fill {
            height: 3px !important;
        }
    
        ul{
            min-width:345px !important;
            margin-right: 0px !important;
            margin-left: -35px;
            margin-bottom: 0;
            margin-top: -40px !important;
        }
    
    
        .container{
            min-width: 100% !important;
            max-width: 300px !important;
        }
    
    
        .leadform {
            margin-top: 0 !important;
        }
    
        .comments {
            max-width: 100% !important;
        }
    
    }
    
    @media (min-width: 460px) and (max-width: 1000px) {
    
        .container{
            min-width: 100% !important;
            max-width: 300px !important;
        }
    }
    
    @media (max-width: 1000px)  {
    
        .comments {
            width: 150% !important;
        }
    
        .container{
            min-width: 100% !important;
            max-width: 600px;
        }
    
    
        .yes-no-selection {
            max-width: 50%;
            height: 140px !important;
        }
    
    
        .home-type {
            width: 100%;
            gap: 8px !important;
        }
    
        p2 {
            font-size: 15px !important;
        }
    
        .step {
            padding: 0 !important;
            max-height: 500px;
        }
    
        .step-cover {
            padding: 0 !important;
            max-height: 500px;
        }
    
    
        h4 {
            font-size: 25px !important;
        }
    
    
        h5 {
            font-size: 22px !important;
        }
    
    
        input {
            max-width: 100% !important;
        }
    
        label {
            text-align: left;
        }
    
        .selection-text {
            font-size: 15px !important;
        }
    
        .grid-image {
            max-width: 120px !important;
        }
    
        .long-text-selection {
            max-height: 150px !important;
            max-width: 150px !important;
           
            padding: 1em 1em !important;
    
        }
    }

        .space-below {
            margin-bottom: 1em;
        }
    
        .comments {
            width: 200%;
            padding: 0.5em .5em;
            border-radius: 8px;
            border: 1px solid rgb(196, 194, 194);
            margin-bottom: 1.2em;
        }
    
        form {
            margin-top: 1.5em;
            max-width: 600px;
        }
    
        .warning {
            margin-bottom: -20px;
            color: red;
            font-weight: 700;
            font-size: 14px;
        }
    
        .button {
            color: white;
            background-color: #b31919;
            border-radius: 20px;
            border: 0;
            padding: .5em 1em;
        }
    
        .buttons-area {
            padding-top: 1.5em;
            display: flex;
            gap: 10px;
            left: 50%;
            right: 50%;
            justify-content: center;
        }
    
        .slider-container {
            width: 100%;
        }
    
        .mySlider[type="range"]::-webkit-slider-runnable-track {
            background: #e9e2e2;
            height: 1rem;
            border-radius: 10px;
        }
        
        .mySlider[type="range"]::-moz-range-track {
            background: #e9e2e2;
            height: 1rem;
            border-radius: 10px;
        }
    
        .mySlider[type="range"]::-webkit-slider-thumb {
            -webkit-appearance: none; 
            appearance: none;
            margin-top: -5px;
            background-color: #b31919;
            height: 1.5rem;
            width: 1.5rem;
            border-radius: 50%;  
        }
    
        input.mySlider {
            -webkit-appearance: none;
            appearance: none;
            cursor: pointer;
        }
    
        .mySlider2[type="range"]::-webkit-slider-runnable-track {
            background: #e2ecf7;
            height: 1rem;
            border-radius: 10px;
        }
        
        .mySlider2[type="range"]::-moz-range-track {
            background: #e2ecf7;
            height: 1rem;
            border-radius: 10px;
        }
    
        .mySlider2[type="range"]::-webkit-slider-thumb {
            -webkit-appearance: none; 
            appearance: none;
            margin-top: -5px;
            background-color: #062850;
            height: 1.5rem;
            width: 1.5rem;
            border-radius: 50%;  
        }
    
        .mySlider2 {
            width: 100% !important;
            -webkit-appearance: none;
            appearance: none;
            cursor: pointer;
            display: flex;
        }
    
        .mySlider {
            width: 100% !important;
            -webkit-appearance: none;
            appearance: none;
            cursor: pointer;
            display: flex;
        }
    
        select {
            width: 85%; 
            height: 50px; 
            border-radius: 10px;
            border: 1px solid #bebebe;
        }
    
        .disclaimer{
            font-size: 12.5px;
            font-weight: 700;
            line-height: 1.1em;
            margin-top: .5em;
            text-align: center;
        }
    
        .contacts-area {
            display: flex;
            justify-content: center;
            flex-direction: column;
            gap: 20px;
            margin-top: .5em;
            align-items: center;
        }
    
        .comments-area {
            display: flex;
            justify-content: center;
            flex-direction: column;
            gap: 20px;
            margin-top: .5em;
            align-items: center;
        }
    
        .contacts-area input{
            height: 40px;
            border: 1px solid #dcdcdc;
            border-radius: 8px;
        }

        input, select, textarea {
            background-color: transparent;
        }
    
        .contact-fields {
            width: 100%;
            display: flex;
            flex-direction: column;
            text-align: left;
        }
    
        .centering3 {
            position: relative; 
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
    
        .centering2 {
            position: relative; 
            top: 30%;
            margin-bottom: 10em;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
    
        .centering {
            position: relative; 
            top: 30%;
            margin-bottom: 10em;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
    
        .income-dropdown {
            width: 170%;
            margin-bottom: 2em;
        }
    
        .centering2 {
            position: relative; 
            top: 10%;
            margin-bottom: 4em;
            width: auto;
        }
    
        .selection-text {
            font-size: 18px;
            font-weight: 700;
            font-family: 'Figtree';
        }
    
        .rangeValue {
            height: 40px;
            width: 60px;
            right: -20px;
            top: 50%;
            transform: translate (-50%, -50%);
            color: #b31919;
            font-weight: 700;
            text-align: center;
            font-size: 25px;
            padding: 0em .5em;
            margin-bottom: .2em;
        }
    
        .rangeValue2 {
            height: 40px;
            width: 60px;
            right: -20px;
            top: 50%;
            transform: translate (-50%, -50%);
            color: #062850;
            font-weight: 700;
            text-align: center;
            font-size: 25px;
            padding: 0em .5em;
            margin-bottom: .2em;
        }
    
        /* .rangeValue::before,     
        .rangeValue2::before {
            position: absolute !important;
            content: ' ';
            height: 10px !important;
            width: 10px !important;
            background-color: white;
            margin-left: -15px;
            margin-top: 13px;
            border-top: 1px solid transparent;
            border-bottom: 1px solid #cecece;
            border-left: 1px solid #cecece;
            border-right: 1px solid transparent;
            transform: rotate(45deg) !important;
        } */
        
    
        .logo{
            margin-bottom: 1em;
            width: 120px;
        }
    
        .responsive-img {
            max-height: 43px;
            width: auto;
        }
    
        img {
            max-width: 68px;
        }
    
        .grid-image {
            max-width: 150px;
        }
    
    
        .step {
            display: none;
        }
    
        .step.active {
            display: block;
            height: 600px;
        }
    
        .step-cover {
            display: none;
        }
    
        .step-cover.active {
            display: block;
        }
    
    
        input.typed {
            width: 250px !important;
            box-sizing: border-box;
            border: none;
            border: 1px solid #dcdcdc;
            min-height: 45px;
            font-size: 1em;
            border-radius: 8px;
        }
        
    
        .flex-class {
            display: flex;
            flex-direction: column;
            border-radius: 20px;
            justify-content: center;
            gap: 10px;
    
        }
    
        p {
            font-size: 20px;
            font-weight: 700;
            line-height: 1.1em;
            margin: 0;
        }
    
        p2 {
            font-size: 18px;
            font-weight: 700;
            line-height: 1.2em;
            margin: 0em .5em;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    
        h4 {
            font-size: 35px;
            font-weight: 700;
        }
    
        h5 {
            font-size: 30px;
            font-weight: 700;
        }
    
        .long-text-selection {
            max-width: 200px;
            max-height: 200px;
            border: 1px solid #cecece;
            display: flex;
            flex-direction: column;
            padding: 1em 1.5em;
            border-radius: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            aspect-ratio: 1/.9 ;
        }
    
        .yes-no-selection {
            width: 150px;
            height: 150px;
            border: 1px solid #cecece;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 1em;
            border-radius: 10px;
            gap: .7em;
        }
    
        .home-type-selection {
            width: 100%;
            height: 70px;
            border: 1px solid #cecece;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            padding: .5em 1em;
            border-radius: 15px;
        }
    
        .selected{
            background-color: #f1fdfe;
            border: 2px solid #afcdf0;
        }
    
    
        .pagination {
            justify-content: center;
        }
    
        .leadform {
            width: 100%;
            flex-direction: column;
            margin: auto;
            justify-content: center ;
            display: flex;
            height: 500px;
        }
    
    
        .container {
            height: 590px;
            min-width: 650px;
        }
    
    
        .purch-refi {
            display: flex;
            min-width: 100%;
            gap: .5em;
            justify-content: center;
            align-items: center;
        }
    
    
        .home-type {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 15px;   
        }
    
    </style>