import { createStore } from 'vuex';

export default createStore({
  state() {
    return {
      cashOutSelected: false,
      purchSelected: false,
    };
  },
  mutations: {
    SET_CASH_OUT_SELECTED(state, value) {
      state.cashOutSelected = value;
    },

    RESET_CASH_OUT_SELECTED(state) {
        state.cashOutSelected = false;
    },

    SET_PURCH_SELECTED(state,value) {
      state.purchSelected = value;
    },

    RESET_PURCH_SELECTED(state) {
      state.purchSelected = false;
    }

  },
  actions: {
    resetCashOutClicked({ commit }) {
      commit('RESET_CASH_OUT_SELECTED');
    }
  },
  modules: {}
});
