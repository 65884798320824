<template>
    <CountiesNav
    realEstateURL="/dayton"
    realEstateForm="/dayton-real-estate-form"
    realEstateCounties="/dayton-counties"/>
    <CountiesContent
    :buttonPath="buttonPath"
    :countiesList="countiesList"/>

</template>

<script>
import CountiesNav from '@/landing-pages/components/navigations/CountiesNav.vue'
import CountiesContent from '@/landing-pages/components/counties/CountiesContent.vue'

import beavercreek from '@/landing-pages/assets/dayton-Beavercreek2.jpg'
import oregon from '@/landing-pages/assets/the-oregon-district-dayton.jpeg'
import middletown from '@/landing-pages/assets/middletown-ohio.jpeg'
import yellowSprings from '@/landing-pages/assets/yellow-springs.jpeg'
import oakwood from '@/landing-pages/assets/Oakwood-Dayton-OH.jpg'
import downtown from '@/landing-pages/assets/downtown-dayton.jpeg'

export default {
    components: {
        CountiesNav,
        CountiesContent
    },

    data (){
        return {
            beavercreek: beavercreek,
            oregon: oregon,
            middletown: middletown,
            yellowSprings: yellowSprings,
            oakwood: oakwood,
            downtown: downtown,

            buttonPath: '/dayton-real-estate-form',

            countiesList: [
                {
                    location: 'Oakwood, Dayton, OH',
                    src: oakwood,
                    idName: 'oakwood',
                    subtitle: 'Find your perfect retreat in Medina County, Ohio, offering peaceful living with easy access to urban amenities.',
                    affordability: 3.8,
                    affordablityText: "Oakwood is known as an affluent area with a higher quality of living compared to other parts of Dayton, reflecting in its housing prices and overall expenses.",
                    parksRec: 4,
                    parksRecText: "The suburb boasts well-maintained parks and recreational facilities, providing residents with ample opportunities for outdoor activities and community events.",
                    schools: 5,
                    schoolsText: "Oakwood is highly regarded for its excellent educational institutions, with public schools in the area often receiving top ratings for academic performance.",
                    shoppingNightlife: 3,
                    shoppingNightlifeText: "The suburb offers a selection of local shopping and dining options. However, for more extensive shopping and nightlife experiences, residents may travel to nearby Dayton or other larger areas.",
                    summaryLine1: "Oakwood, a suburb of Dayton, Ohio, is widely recognized as one of the most desirable places to live in the region, known for its picturesque neighborhoods and high quality of life. The area boasts beautiful tree-lined streets and a rich array of historic homes, ranging from charming bungalows to grand estates, reflecting a sense of timeless elegance and community pride. Oakwood's commitment to maintaining its architectural heritage, coupled with its well-kept public spaces, creates an aesthetically pleasing and welcoming environment. The suburb is also celebrated for its top-rated schools, which are some of the best in the state, making it an ideal choice for families prioritizing education.",
                    summaryLine2: "Beyond its educational excellence and stunning residential areas, Oakwood offers a strong sense of community and safety, often cited as one of the safest suburbs in the Dayton area. The community is tight-knit, with residents actively participating in local events, organizations, and civic initiatives, fostering a friendly and inclusive atmosphere. Additionally, Oakwood provides convenient access to downtown Dayton's amenities, including cultural institutions, restaurants, and shopping, while still retaining a peaceful suburban feel. With its blend of historical charm, educational excellence, and community-centric living, Oakwood stands out as a premier location for those seeking a balanced and fulfilling lifestyle."
                },
                {
                    location: 'The Oregon District, Dayton, OH',
                    src: oregon,
                    idName: 'oregon',
                    subtitle: "Dayton's historic and vibrant neighborhood, famous for its unique boutiques, lively arts scene, & eclectic nightlife.",
                    affordability: 4,
                    affordablityText: "The Oregon District offers a range of housing options, from historic homes to modern apartments, with prices that are generally reasonable for an urban neighborhood, though it can vary.",
                    parksRec: 4.5,
                    parksRecText: "This historic neighborhood is close to several parks and recreational areas, including easy access to the scenic RiverScape MetroPark, providing residents with ample green space and outdoor activities.",
                    schools: 3.7,
                    schoolsText: "While the Oregon District itself is more known for its nightlife and cultural scene, there are several schools in the surrounding areas. The quality can vary, so families often look at options in nearby neighborhoods.",
                    shoppingNightlife: 5,
                    shoppingNightlifeText: "The Oregon District is renowned for its vibrant nightlife and shopping scene. It boasts a variety of unique shops, boutiques, restaurants, bars, and entertainment venues, making it one of Dayton's most lively areas.",
                    summaryLine1: "The Oregon District, located in the heart of Dayton, Ohio, is renowned for its vibrant and eclectic atmosphere, making it an ideal place for those who enjoy urban living with a unique historical charm. This neighborhood is a cultural hotspot, boasting beautifully preserved 19th-century architecture alongside an array of modern amenities. The streets are lined with a diverse selection of boutiques, art galleries, coffee shops, and some of the city's best dining options, offering residents an unparalleled local experience. The Oregon District is also known for its lively nightlife, with a variety of bars and entertainment venues that cater to a wide range of tastes, ensuring there's always something exciting to do.",
                    summaryLine2: "Beyond its bustling commercial scene, the Oregon District appeals to residents with its strong sense of community and walkability. The neighborhood hosts numerous community events and street festivals throughout the year, fostering a tight-knit and inclusive community spirit. Living in the Oregon District means being part of a dynamic and creative environment, where the convenience of city life meets the charm of historic living. Additionally, its proximity to downtown Dayton provides easy access to the city's major employment centers, cultural institutions, and parks, making it a convenient and desirable location for professionals, artists, and anyone seeking an energetic urban lifestyle."
                },
                {
                    location: 'Middletown, OH',
                    src: middletown,
                    idName: 'middletown',
                    subtitle: 'An industrious city midway between Dayton & Cincinnati with a rich cultural heritage.',
                    affordability: 4.7,
                    affordablityText: "Middletown is generally considered affordable, especially in comparison to larger cities nearby. It offers a range of housing options at relatively lower prices, making it an attractive option for various budgets.",
                    parksRec: 3.6,
                    parksRecText: "The city has several public parks and recreational facilities, providing residents with spaces for outdoor activities and family-friendly amenities, though the options may not be as extensive as larger urban areas.",
                    schools: 3.2,
                    schoolsText: "Middletown has a mix of public and private schools. The quality of education varies, with some schools performing well, while others may face challenges.",
                    shoppingNightlife: 3.6,
                    shoppingNightlifeText: "The city offers basic shopping facilities and some nightlife options. While it may not have the variety and vibrancy of larger cities, there are enough amenities to cater to the everyday needs of residents.",
                    summaryLine1: "Middletown, Ohio, nestled between Cincinnati and Dayton, offers a unique blend of small-town charm and suburban convenience, making it an appealing choice for those seeking a quieter, more affordable lifestyle. The city's affordability is a significant draw, with a cost of living that is lower than many surrounding areas, providing residents with a variety of housing options that cater to different budgets. Middletown's location is ideal for those who work or frequently travel to the larger neighboring cities but prefer the tranquility and close-knit community feel of a smaller town. The city also boasts a rich industrial history and a growing arts scene, adding to its unique character.",
                    summaryLine2: "In addition to its economic advantages, Middletown offers a range of recreational activities and amenities. The city is home to several parks and green spaces, including the scenic Great Miami Riverway, which provides opportunities for outdoor activities like biking, hiking, and fishing. Middletown also has a commitment to community events and cultural activities, including local festivals, parades, and an emerging arts district that fosters a sense of community involvement and pride. With its blend of affordability, strategic location, and community-focused living, Middletown presents itself as an attractive option for families, professionals, and retirees alike."
                },
                {
                    location: 'Yellow Springs, OH',
                    src: yellowSprings,
                    idName: 'yellowSprings',
                    subtitle: 'A charming and progressive small town renowned for its artistic community, liberal values, & scenic natural surroundings.',
                    affordability: 3.9,
                    affordablityText: "Yellow Springs is known for its unique, artistic community, which can sometimes command higher prices, but overall, it offers a range of housing options that are generally considered affordable compared to larger urban areas.",
                    parksRec: 5,
                    parksRecText: "The village excels in public parks and recreational opportunities, especially with its close proximity to nature preserves, hiking trails, and scenic areas like Glen Helen Nature Preserve and John Bryan State Park.",
                    schools: 4,
                    schoolsText: "Yellow Springs has a reputation for good quality education within its public schools, which are known for their progressive values and strong community support.",
                    shoppingNightlife: 4.5,
                    shoppingNightlifeText: "The village offers a unique shopping experience with a variety of independent shops, galleries, and boutiques. Nightlife is more subdued but vibrant, with local bars and events that reflect the community's artistic and eclectic spirit.",
                    summaryLine1: "Yellow Springs, Ohio, is a picturesque and vibrant village that stands out for its strong sense of community, artistic spirit, and connection to nature, making it an ideal place for those who appreciate a unique and eclectic lifestyle. Known for its progressive values and inclusive atmosphere, Yellow Springs fosters a creative environment that is evident in its colorful downtown area, filled with independent shops, art galleries, and cafes. The village is a haven for artists, intellectuals, and nature lovers, offering a diverse range of cultural events, from street fairs to music festivals, that bring the community together in celebration of its unique character.",
                    summaryLine2: "In addition to its cultural richness, Yellow Springs is surrounded by natural beauty, with easy access to outdoor activities in places like Glen Helen Nature Preserve, John Bryan State Park, and the Little Miami Scenic Trail. These areas provide residents with endless opportunities for hiking, biking, and exploring the scenic landscapes of Ohio. The village's commitment to environmental sustainability and local businesses adds to its appeal as a place that values and nurtures both its natural and social environment. With its blend of artistic culture, community engagement, and natural beauty, Yellow Springs offers a distinctive and fulfilling quality of life for its residents."
                },
                {
                    location: 'Beavercreek, Dayton, OH',
                    src: beavercreek,
                    idName: 'beavercreek',
                    subtitle: "The nation's historical capital, brimming with iconic landmarks and vibrant urban energy.",
                    affordability: 4.1,
                    affordablityText: "Beavercreek offers a range of housing options, from more affordable homes to upscale properties. While it's generally considered a middle to upper-middle-class area, it offers more affordable living compared to larger cities.",
                    parksRec: 4,
                    parksRecText: "The suburb is well-equipped with public parks and recreational facilities, including nature reserves, golf courses, and sports complexes, providing residents with ample opportunities for outdoor activities.",
                    schools: 4.2,
                    schoolsText: "Beavercreek is known for its strong public school system, with schools that are well-regarded for their academic performance and extracurricular offerings.",
                    shoppingNightlife: 3.5,
                    shoppingNightlifeText: "The suburb offers a variety of shopping centers, including The Mall at Fairfield Commons and The Greene Town Center. Nightlife options are more limited compared to a larger city but still provide a selection of restaurants and bars.",
                    summaryLine1: "Beavercreek, located in the suburbs of Dayton, Ohio, is widely recognized as an ideal place to live for those seeking a balance between suburban tranquility and city convenience. Known for its safe neighborhoods and high-quality living standards, Beavercreek appeals to families and professionals alike. The area boasts well-maintained parks, numerous recreational facilities, and excellent public schools, making it a top choice for those prioritizing education and an active lifestyle. The community is characterized by its spacious homes, well-planned neighborhoods, and an overall sense of well-being and security.",
                    summaryLine2: "In addition to its residential appeal, Beavercreek offers a variety of shopping and dining options, with prominent centers like The Mall at Fairfield Commons and The Greene Town Center providing residents with both national retailers and local boutiques. The suburb's proximity to Dayton means easy access to the city's cultural and professional opportunities, while still enjoying the benefits of suburban living. Beavercreek's commitment to community development, combined with its natural beauty and strategic location, makes it a highly desirable place to call home for those looking for a harmonious blend of comfort, convenience, and quality."
                },
                {
                    location: 'Downtown Dayton',
                    src: downtown,
                    idName: 'downtown',
                    subtitle: 'The bustling heart of the city, featuring high-rise living, diverse cultural attractions, and a revitalized waterfront.',
                    affordability: 4.1,
                    affordablityText: "Downtown Dayton offers a variety of housing options, catering to a range of budgets. The area is generally considered affordable, especially when compared to larger metropolitan areas. With a mix of modern apartments, historic homes, and new developments, residents can find reasonably priced living spaces that provide urban amenities without the high costs typically associated with city living.",
                    parksRec: 4.7,
                    parksRecText: "Downtown Dayton offers several green spaces and recreational areas, including the beautiful RiverScape MetroPark along the Great Miami River. The park features bike paths, walking trails, and seasonal activities, providing residents and visitors with ample outdoor enjoyment opportunities.",
                    schools: 3.7,
                    schoolsText: "While downtown schools offer convenience and a diverse environment, families seeking specialized programs or higher-rated schools may consider neighboring areas for more options.",
                    shoppingNightlife: 5,
                    shoppingNightlifeText: "The area boasts a variety of local boutiques, markets, and specialty stores. The nightlife is vibrant, with numerous bars, restaurants, and entertainment venues, including the Schuster Performing Arts Center.",
                    summaryLine1: "Downtown Dayton, Ohio, stands out as a vibrant and dynamic urban center, offering a rich tapestry of cultural, recreational, and professional opportunities. The heart of the city is known for its impressive array of cultural institutions, including the esteemed Dayton Art Institute, the Victoria Theatre, and the Schuster Performing Arts Center, which host a variety of performances and events year-round. This cultural richness is complemented by a bustling nightlife and dining scene, with an array of restaurants, cafes, bars, and clubs that cater to diverse tastes and preferences. Downtown Dayton also boasts beautiful green spaces like RiverScape MetroPark, which offers scenic river views and a range of outdoor activities, making it a lively and engaging place to live and visit.",
                    summaryLine2: "The revitalization efforts in Downtown Dayton have played a significant role in its resurgence as a hub of activity and growth. The area has seen significant development in residential spaces, attracting a mix of young professionals, families, and creatives. The presence of innovative businesses and startups, along with established companies, provides ample employment opportunities and contributes to the area's economic vitality. The ease of access provided by efficient public transit and the walkability of the downtown area further enhance its appeal. With its blend of historical charm, modern amenities, and a strong sense of community, Downtown Dayton offers an enriching urban living experience that is hard to match."
                }
            ],
        }
    },

}

</script>

<style scoped>
@media (max-width: 786px)  {
    .paragraph {
        margin: 2em 0 3.5em 0 !important;
    }

    .content {
        margin-top: 3em !important;
    }

    .image-content {
        flex-direction: column !important;
    }

    .block-image {
        height: 200px !important;
    }

    h2 {
        font-size: 42px !important;
        line-height: 1em;
    }

    h4 {
        font-size: 42px !important;
    }

    h3 {
        font-size: 42px !important;
    }

    p {
        font-size: 17px !important;
        line-height: 1.3em;
        width: 100% !important;
    }

    p1 {
        line-height: 1.2em !important;
    }

    p4 { 
        font-size: 17px !important;
    }

    p6 { 
        font-size: 17px !important;
    }
}

@media (max-width: 1000px)  {
    .block-image {
        max-height: 250px;
    }
}

button {
    color: white;
    background-color: #b31919;
    border: none;
    border-radius: 35px;
    font-weight: 800;
    padding: .5em 1em;
}

.paragraph-container {
    max-width: 1350px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1.5em .7em;
}

.paragraph {
    background-color: #efefef;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 2em 0 5em 0;
}

.inline-summary {
    margin-top: 1em;
}

.inline {
    text-align: left;
}

.summary {
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: left;
}

.image-content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    max-width: 1350px;
    padding: 0 .7em;
    gap: 20px;
}

.block-image {
    height: 350px;
}

.content {
    margin-top: 5em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.location-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

ul{
    margin: 0;
    padding: 0;
    width: 100%;
}

.bg-image {
    background: url("@/landing-pages/assets/summit-county-ohio.png");
    box-shadow: inset 0 0 0 1100px #00000076;
    position: relative;
    background-position: 0px 800px;
    background-size: cover no-repeat;
    height: 24em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-attachment: fixed;
}

.blocks {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    max-width: 1350px;
    padding: 4em .7em 0 .7em;
}

p6 {
    text-align: left;
    margin-bottom: 1em;
    font-size: 17px;

}

p4 {
    font-weight: 800;
    line-height: 1.2em;
    font-size: 17px;
}

h5 {
    color: rgb(185, 28, 14);
    font-size: 17px;
    font-weight: 800;
    line-height: 1.5rem;
    display: inline-block;
    margin-right: .3em;
}

h6 {
    color: #062850;
    font-size: 17px;
    font-weight: 800;
    line-height: 1.5rem;
    display: inline-block;
}

p1 {
    color: rgb(173, 173, 173);
    width: 100%;
    font-size: 19px;
    line-height: 1.3;
    font-weight: 800;
}

h1 {
    color: rgb(6, 40, 80);
    width: 100%;
    font-size: 26px;
    line-height: 1.3;
    font-weight: 800;
}

h2 {
    font-weight: 500;
    font-size: 58px;
    line-height: 1em;
    font-family: 'Inter';
    margin: 0;
    color: white;
    text-align: left;
}

h4 {
    font-family: 'Playfair-Display';
    font-size: 58px;
    font-weight: 600;
    color: #b31919;
    margin: 0;
    text-align: left;
    display: inline-block;
    margin-right: .3em;
    /* text-shadow: 0px 1px 3px rgb(0, 0, 0); */
}

h3 {
    font-family: 'Playfair-Display';
    font-size: 58px;
    font-weight: 600;
    color: #062850;
    margin: 0;
    text-align: left;
    display: inline-block;
}

p {
    font-weight: 500;
    font-size: 24px;
    line-height: 1.3em;
    font-family: 'Inter';
    color: white;
    width: 60%;
    margin-top: 1em;
    text-align: left;
}

</style>