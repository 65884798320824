<template>
    <div class="full-footer-row">
        <div class="footer-max-width">
            <div class="footer-container">
                <div class="logo-area">
                    <img class="logo" :src="navLogoWhite" alt="liberty.logo">
                </div>
                
                <div class="socials-info">
                    <div class="socials">
                        <div class="row">
                            <div class="col">
                                <a href="https://twitter.com/rehabloans1"><img class="social" :src="twitterLogo" alt="twitter.logo"></a>
                            </div>
                            <div class="col">
                                <a href="https://www.instagram.com/liberty_home_mortgage/?igshid=YmMyMTA2M2Y%3D"><img class="social" :src="igLogo" alt="instagram.logo"></a>
                            </div>
                            <div class="col">
                                <a href="https://www.facebook.com/people/Liberty-Home-Mortgage-Corporation/100063818623036/?mibextid=LQQJ4d"><img class="social" :src="fbLogo" alt="facebook.logo"></a>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <a href="https://www.google.com/maps/place//data=!4m3!3m2!1s0x8830e435765376cd:0x73a5136324bac4ee!12e1?source=g.page.default"><img class="social" :src="googleLogo" alt="google.logo"></a>
                            </div>
                            <div class="col">
                                <a href="https://www.youtube.com/@khash_Saghafi"><img class="social" :src="ytLogo" alt="youtube.logo"></a>
                            </div>
                            <div class="col">
                                <a href="https://www.linkedin.com/company/libertyhomemortgagecorporation"><img class="social" :src="linkedinLogo" alt="linkedin.logo"></a>
                            </div>
                        </div>
                    </div>
                    <div class="consumer-access">
                        <img class="consumer-logo" :src="equalHousing" alt="equalHousing.logo">
                        <a href="https://mortgage.nationwidelicensingsystem.org/about/sitepages/NMLSConsumerAccess.aspx"><img class="consumer-logo" :src="nmlsLogo" alt="nmlsLogo.logo"></a>
                    </div>
                    <div class="info">
                        <p2>Liberty Home Mortgage Corporation</p2>
                        <p2><a href="tel:440-665-7627"><b>440-665-7627</b></a> | <a href="tel:844-744-0010"><b>844-744-0010</b></a></p2>
                        <p2><a href="mailto:webmaster@example.com"><u>info@libertyhm.com</u></a></p2>
                        <p2 style="font-weight: 900;">NMLS# 1114762</p2>
                        <p2><a href="https://www.google.com/maps?sca_esv=0e7f67614aa28a61&output=search&q=liberty+home+mortgage+corp&source=lnms&entry=mc&ved=1t:200715&ictx=111">6225 Oak Tree Blvd. Independence, OH 44131</a></p2>
                    </div>
                </div>
            </div>
            <div class="footer-disclaimer">
                <p>© All Rights Reserved 2024, Liberty Home Mortgage Corporation | Liberty Home Mortgage Corporation is not associated with Liberty Home Equity Solutions or Liberty Reverse Mortgage | <a href="https://www.libertyhomemortgage.org/information-submission-consent?_gl=1*iccikj*_ga*NTE0MTkwMjI0LjE3MTM3OTc2Mzc.*_ga_469MJXWQ4R*MTcxNDQ4NzUxNi4xMi4xLjE3MTQ0ODc3NjIuNjAuMC4w*_gcl_au*MTIwMjUzMDk1MS4xNzEzNzk3NjM3" style="color: skyblue;">Information Submission Consent</a> | <a href="https://www.libertyhomemortgage.org/privacypolicy?_gl=1*1cs6yin*_ga*NTE0MTkwMjI0LjE3MTM3OTc2Mzc.*_ga_469MJXWQ4R*MTcxNDQ4NzUxNi4xMi4xLjE3MTQ0ODc3MDAuNTguMC4w*_gcl_au*MTIwMjUzMDk1MS4xNzEzNzk3NjM3" style="color: red;">LEGAL</a> | <a href="https://www.libertyhomemortgage.org/california-privacy-policy?_gl=1*1lxdnb9*_ga*NTE0MTkwMjI0LjE3MTM3OTc2Mzc.*_ga_469MJXWQ4R*MTcxNDQ4NzUxNi4xMi4xLjE3MTQ0ODc4MzAuNjAuMC4w*_gcl_au*MTIwMjUzMDk1MS4xNzEzNzk3NjM3">CALIFORNIA PRIVACY POLICY</a></p>
            </div>
        </div>
    </div>
</template>

<script>
import navLogoWhite from '@/landing-pages/assets/lhm_hori_logo_white-transparent.png';
import igLogo from '@/landing-pages/assets/ig-logo-white.svg';
import fbLogo from '@/landing-pages/assets/fb-white-logo.svg';
import twitterLogo from '@/landing-pages/assets/twitter-logo-white.svg';
import googleLogo from '@/landing-pages/assets/google-png.png';
import ytLogo from '@/landing-pages/assets/YouTube-Icon-White-Logo.wine.png';
import linkedinLogo from '@/landing-pages/assets/linkedin-white.png';
import equalHousing from '@/landing-pages/assets/equal housing lender logo white.png';
import nmlsLogo from '@/landing-pages/assets/nmls-logo-white.png';

export default {
    data (){
        return {
            navLogoWhite: navLogoWhite,
            igLogo: igLogo,
            fbLogo: fbLogo,
            twitterLogo: twitterLogo,
            googleLogo: googleLogo,
            ytLogo: ytLogo,
            linkedinLogo: linkedinLogo,
            equalHousing: equalHousing,
            nmlsLogo: nmlsLogo
        }
    }
}

</script>

<style scoped>
@media (max-width: 800px) {
    .footer-container {
        justify-content: center !important;
        align-items: center;
        flex-direction: column;
    }

    .socials-info {
        flex-direction: column;
    }

    p2 {
        text-align: center !important;
    }

    .logo {
        margin-bottom: 1em;
    }
}

.consumer-access {
    display: flex;
    justify-content: center;
    align-content: center;
}

.consumer-logo {
    max-height: 30px;
}

p2 {
    color: white;
    text-align: right;
}

p2 a{
    text-decoration: none;
    color: white;
}

.socials {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.row {
    margin-bottom: 1em;
}

.social {
    max-width: 60px;
    max-height: 30px;
}

.info {
    display: flex;
    flex-direction: column;
}

p1 {
    color: white;
    text-align: right;
}

p {
    font-size: 13px;
}

.footer-disclaimer {
    border-top: 1px solid white;
    padding: 1em 0em;
}

.socials-info {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.logo-area {
    display: flex;
}

.full-footer-row {
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2em .7em;
}

.footer-container {
    display: flex;
    justify-content: space-between;
    padding: 1em 0em;
}

.footer-max-width {
    max-width: 1350px;
    width: 100%;
}

.logo {
    max-height: 40px;
    margin-bottom: 2em;
}

p {
    color: white;
}
</style>