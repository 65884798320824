<template>
    <div class="map-container">
        <div class="map-wrapper">
            <div class="map">
                <img class="map-pic" :src=map>
                <div class="right">
                    <h1>We're licensed in <span>46 states.</span></h1>
                    <div class="states-list">
                        <div v-for="(state, index) in states" :key="index" style="width: 100%;">
                            <div class="state-label" @click="openLicense(index)">
                                <p1>{{ state.abbrev }}</p1>
                                <i class="fa-solid fa-caret-down"></i>
                            </div>
                            <div v-if="openIndex === index" class="dropdown">
                                <p2>{{ state.license }}</p2>
                                <p3>{{ state.type }}</p3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
   
</template>

<script>
import map from '@/main-site/assets/liberty-license-map-6-2024.png'

export default{
    data(){
        return {
            openIndex: null,
            map: map,
            states: [
                {
                    state: "Alabama",
                    abbrev: "AL",
                    license: '#22606',
                    type: "Consumer Credit License"
                },
                {
                    state: "Arizona",
                    abbrev: "AZ",
                    license: '#945532',
                    type: "Mortgage Banker License"
                },
                {
                    state: "Arkansas",
                    abbrev: "AK",
                    license: '#119859',
                    type: "Combination Mortgage Banker-Broker-Servicer License"
                },
                {
                    state: "California",
                    abbrev: "CA",
                    license: '#60DBO82350',
                    type: "Financing Law License"
                },
                {
                    state: "Colorado",
                    abbrev: "CO",
                    license: 'N/A',
                    type: "Mortgage Company Registration"
                },
                {
                    state: "Connecticut",
                    abbrev: "CT",
                    license: '#ML-1114762',
                    type: "Mortgage Lender License"
                },
                {
                    state: "Delaware",
                    abbrev: "DE",
                    license: '#30748',
                    type: "Lender License"
                },
                {
                    state: "DC",
                    abbrev: "DC",
                    license: '#MLB1114762',
                    type: "Mortgage Dual Authority License"
                },
                {
                    state: "Florida",
                    abbrev: "FL",
                    license: '#MLD1100',
                    type: "Mortgage Lender Servicer License"
                },
                {
                    state: "Georgia",
                    abbrev: "GA",
                    license: '#60670',
                    type: "Mortgage Lender License/Registration"
                },
                {
                    state: "Idaho",
                    abbrev: "ID",
                    license: '#MBL-2081114762',
                    type: "Mortgage Broker/Lender License"
                },
                {
                    state: "Illinois",
                    abbrev: "IL",
                    license: '#MB.6761322',
                    type: "Residential Mortgage License"
                },
                {
                    state: "Indiana",
                    abbrev: "IN-DFI",
                    license: '#42462',
                    type: "Mortgage Lending License"
                },
                {
                    state: "Iowa",
                    abbrev: "IA",
                    license: '#2020-0012',
                    type: "Mortgage Banker License"
                },
                {
                    state: "Kansas",
                    abbrev: "KS",
                    license: '#MC.0025661',
                    type: "Mortgage Company License"
                },
                {
                    state: "Kentucky",
                    abbrev: "KY",
                    license: '#MC701328',
                    type: "Mortgage Company License"
                },
                {
                    state: "Louisiana",
                    abbrev: "LA",
                    license: 'N/A',
                    type: "Residential Mortgage Lending License"
                },
                {
                    state: "Maine",
                    abbrev: "ME",
                    license: '#1114762',
                    type: "Supervised Lender License"
                },
                {
                    state: "Maryland",
                    abbrev: "MD",
                    license: '#1114762',
                    type: "Mortgage Lender License"
                },
                {
                    state: "Massachusetts",
                    abbrev: "MA",
                    license: '#MC1114762',
                    type: "Mortgage Lender License, Mortgage Broker License"
                },
                {
                    state: "Michigan",
                    abbrev: "MI",
                    license: '#FL0021202',
                    type: "1st Mortgage Broker/Lender License, 2nd Mortgage Broker/Lender Registrant"
                },
                {
                    state: "Minnesota",
                    abbrev: "MN",
                    license: '#MN-MO-1114762',
                    type: "Residential Mortgage Originator License"
                },
                {
                    state: "Mississippi",
                    abbrev: "MS",
                    license: '#1114762',
                    type: "Mortgage Lender License"
                },
                {
                    state: "Montana",
                    abbrev: "MO",
                    license: '#1114762',
                    type: "Mortgage Broker License, Mortgage Lender License, Mortgage Servicer License"
                },
                {
                    state: "New Hampshire",
                    abbrev: "NH",
                    license: '#23615-MB',
                    type: "Mortgage Banker License"
                },
                {
                    state: "Nebraska",
                    abbrev: "NE",
                    license: 'N/A',
                    type: "Mortgage Banker License"
                },
                {
                    state: "New Jersey",
                    abbrev: "NJ",
                    license: 'N/A',
                    type: "Residential Mortgage Lender License"
                },
                {
                    state: "New Mexico",
                    abbrev: "NM",
                    license: 'N/A',
                    type: "Mortgage Loan Company License"
                },
                {
                    state: "North Caroliona",
                    abbrev: "NC",
                    license: '#L-182185',
                    type: "Mortgage Lender License"
                },
                {
                    state: "North Dakota",
                    abbrev: "ND",
                    license: '#MB103931',
                    type: "Residential Mortgage Lender, Residential Mortgage Loan Servicer"
                },
                {
                    state: "Ohio",
                    abbrev: "OH",
                    license: '#RM.850241.000',
                    type: "Residential Mortgage Lending Act Certificate of Registration"
                },
                {
                    state: "Oklahoma",
                    abbrev: "OK",
                    license: '#ML011865',
                    type: "Mortgage Lender License"
                },
                {
                    state: "Oregon",
                    abbrev: "OR",
                    license: '#1114762',
                    type: "Mortgage Lending License"
                },
                {
                    state: "Pennsylvania",
                    abbrev: "PA",
                    license: '#62784',
                    type: "Mortgage Lender License"
                },
                {
                    state: "Rhode Island",
                    abbrev: "RI",
                    license: '#20214247LL',
                    type: "Lender License, Loan Broker License"
                },
                {
                    state: "South Carolina",
                    abbrev: "SC-BFI",
                    license: '#1114762',
                    type: "Mortgage Lender / Servicer License"
                },
                {
                    state: "South Dakota",
                    abbrev: "SD",
                    license: '#1114762.ML',
                    type: "Mortgage Lender License"
                },
                {
                    state: "Tennessee",
                    abbrev: "TN",
                    license: '#175017',
                    type: "Mortgage License"
                },
                {
                    state: "Texas",
                    abbrev: "TX-SML",
                    license: 'N/A',
                    type: "Mortgage Banker Registration"
                },
                {
                    state: "Utah",
                    abbrev: "UT-DFI",
                    license: '#LL-1114762',
                    type: "Residential First Mortgage Notification"
                },
                {
                    state: "Utah",
                    abbrev: "UT-DRE",
                    license: '#LL-1114762',
                    type: "Mortgage Entity License"
                },
                {
                    state: "Vermont",
                    abbrev: "VT",
                    license: '#LL-1114762',
                    type: "Lender License, Loan Servicer License"
                },
                {
                    state: "Virginia",
                    abbrev: "VA",
                    license: '#MC-6766',
                    type: "Lender License"
                },
                {
                    state: "Washington",
                    abbrev: "WA",
                    license: '#CL-1114762',
                    type: "Consumer Loan Company License"
                },
                {
                    state: "West Virginia",
                    abbrev: "WV",
                    license: '#ML-38052',
                    type: "Mortgage Broker License, Mortgage Lender License"
                },
                {
                    state: "Wisconsin",
                    abbrev: "WI",
                    license: '#1114762BA',
                    type: "Mortgage Banker License"
                },
                {
                    state: "Wyoming",
                    abbrev: "WY",
                    license: '#4172',
                    type: "Consumer Lender License, Mortgage Lender/Broker License"
                },
            ]
        }
    },

    methods: {
        openLicense(index) {
        if (this.openIndex === index) {
            this.openIndex = null; 
        } else {
            this.openIndex = index; 
        }
        }
    }
}
</script>

<style scoped>

.state-label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #ececec;
    color: black;
    padding: .2em .5em;
    border-radius: 8px 8px 0px 0px;
}

p3 {
    text-align: left;
    line-height: 1.3em;
}

p2 {
    text-align: left;
    font-weight: 700;
}

p1 {
    text-align: left;
    font-weight: 700;
}

i {
    color: #b31919;
}

.dropdown {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: #ececec;
    padding: .5em;
}

.states-list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    justify-items: left;
    gap: 10px;
    margin-top: 2em;
}

span {
    color: #b31919;
}

h1 {
    font-size: 55px;
    font-weight: 700;
    color: #042245;
    text-align: left;
    line-height: 1em;
}

.map-pic {
    max-width: 650px;
    max-height: 500px;
    margin-top: 3em;
}

.map {
    display: flex;
    flex-direction: row;
    gap: 50px;
}

.map-wrapper {
    max-width: 1350px;
    width: 100%;
}

.map-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 5em 0.7em;
}
</style>