<template>
    <div class="module-background">
        <!-- <CreditScoreQuiz/>
        <FederalLoansQuiz/>
        <RealEstateQuiz/>
        <EscrowQuiz/>
        <RefiQuiz/> -->

        <LowDownPaymentScenarios v-if="currentScenario === 'low-down-payment-scenarios'"/>
        <LowCreditScenarios v-if="currentScenario === 'low-credit-scenarios'"/>
        <SelfEmployedScenarios v-if="currentScenario === 'self-employed-scenarios'"/>
        <BankruptcyScenarios v-if="currentScenario === 'bankruptcy-scenarios'"/>
        <JointScenarios v-if="currentScenario === 'joint-scenarios'"/>
    </div>

    <div class="modules">
    <div class="modules-container">
        <div class="scenario-box">
        <h3 class="header-name1">Case Scenarios</h3>    
        <div class="flex-contain">
            <li class="module1" v-for="(scenario, index) in scenarios" 
            :key="index" 
            @click="setScenario(index), displayCase(scenario.setting)"
            :class="{ active: this.currentScenarioIndex === index }">
                <h1 :class="{ hactive: this.currentScenarioIndex === index }">{{ scenario.name }}</h1>
                <button class="module-button" :class="{ hide: this.currentScenarioIndex === index }">View</button>
            </li>
        </div>
        </div>

        <div class="scenario-box">
        <h3 class="header-name2">Homebuying Quizzes</h3>
        <div class="flex-contain">
            <li class="module2" v-for="(quiz, index) in quizzes" :key="index"
            @click="setQuiz(index)"
            :class="{ active2: this.currentQuizIndex === index }">
                <h1 :class="{ hactive: this.currentQuizIndex === index }">{{ quiz.name }}</h1>
                <button class="module-button2" :class="{ hide: this.currentQuizIndex === index }">View</button>
            </li>
        </div>
        </div>

    </div>
    </div>
</template>

<script>
import MortgageQuiz from '@/landing-pages/components/quizzes/MortgageQuiz.vue';
import JointScenarios from '@/landing-pages/components/scenarios/JointScenarios.vue';
import BankruptcyScenarios from '@/landing-pages/components/scenarios/BankruptcyScenarios.vue'
import LowCreditScenarios from '@/landing-pages/components/scenarios/LowCreditScenarios.vue';
import LowDownPaymentScenarios from '@/landing-pages/components/scenarios/LowDownPaymentScenarios.vue'
import SelfEmployedScenarios from '@/landing-pages/components/scenarios/SelfEmployedScenarios.vue'

export default{
    components: {
    MortgageQuiz,
    JointScenarios,
    BankruptcyScenarios,
    LowCreditScenarios,
    LowDownPaymentScenarios,
    SelfEmployedScenarios
},

    data(){
        return {
            currentScenarioIndex: 0,
            currentQuizIndex: null,
            currentScenario: 'low-down-payment-scenarios',

            scenarios:[
            {
                name: 'Low Down Payment Scenarios',
                setting: 'low-down-payment-scenarios'
            },
            {
                name: 'Low Credit Scenarios',
                setting: 'low-credit-scenarios'
            },
            {
                name: 'Self-Employed Scenarios',
                setting: 'self-employed-scenarios'
            },
            {
                name: 'Bankrupcy Scenarios',
                setting: 'bankruptcy-scenarios'
            },
            {
                name: 'Marriage & Joint Scenarios',
                setting: 'joint-scenarios'
            }
            ],

            quizzes:[
            {
                name: 'Credit Score Quiz',
                selected: 'a'
            },
            {
                name: 'Federal Loans Quiz',
                selected: 'a'
            },
            {
                name: 'Home-buying/Real Estate Quiz',
                selected: 'a'
            },
            {
                name: 'Escrow/Taxes/Insurance Quiz',
                selected: 'a'
            },
            {
                name: 'Refinancing Quiz',
                selected: 'a'
            },
            ]
        }
    },

    computed: {
        isLearningModulesRoute() {
            if (this.$route.path === '/learning-modules') {
                this.$emit('learning-modules', true)
            }
        } 
    },

    methods: {
        displayCase(setting){
            this.currentScenario = setting;
        },

        setScenario(index) {
            this.currentScenarioIndex = index;
        },
        
        setQuiz(index) {
            this.currentQuizIndex = index;
        },
    },

    }
</script>

<style scoped>
@media screen and (max-width: 920px) {

    .modules-container {
        flex-direction: row !important;
        width: 100% !important;
        gap: 0px !important;
    }

    .modules {
        border: none !important;
        width: 100% !important;
    }

    h1 {
        max-width: 100% !important;
    }

    .module-background {
        max-width: 100% !important;
        margin-top: 2em;
        margin-bottom: 2em !important;
    }
}

@media (min-width: 631px) and (max-width: 920px)
{
    .scenario-box {
        width: 50%;
    }

    .module-background {
        margin-top: 3em !important;
    }
}
@media screen and (max-width: 1000px) {

    .modules {
        box-shadow: -6px 2px 10px rgba(0, 0, 0, 0.298) !important;
    }
}

@media screen and (max-width: 630px) {
    .modules-container {
        flex-direction: column !important;
    }

    .scenario-box {
        width: 100% !important;
    }

    h4, h5 {
        font-size: 16px !important;
    }

    .header-name2 {
        margin-top: 1em !important
    }
}

.module-button {
    padding: .5em 1em;
    background-color: white;
    color: #b31919;
    font-weight: 800;
    border-radius: 25px;
    border: none;
    line-height: 1.1em;
}

.module-button2 {
    padding: .5em 1em;
    background-color: white;
    color: #062850;
    font-weight: 800;
    border-radius: 25px;
    border: none;
    line-height: 1.1em;
}

.active {
    background-color: white !important;
    border: 1px solid #b31919 !important;
}

.active2 {
    background-color: white !important;
    border: 1px solid #062850 !important;
}

.hactive {
    color: black !important;
    font-weight: 700 !important;
}

h4 {
    font-size: 22px;
    color: #062850;
    padding-right: .35em;
}

h5 {
    font-size: 22px;
    color: #b31919;
}

h4, h5 {
    margin: 0;
    text-align: left;
    text-wrap: nowrap;
    font-weight: 800;
}


.module-background {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    width: 100%;
    padding: 0em .7em;
}

h1 {
    text-align: left;
    font-size: 16px;
    color: white;
    font-weight: 600;
    margin: 0;
    max-width: 300px;
}

.module1 {
    padding: .8em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #b31919;
    margin: .2em 0;
    border-radius: 10px;
    height: 60px;
}

.module2 {
    padding: .8em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #062850;
    margin: .2em 0;
    border-radius: 10px;
    height: 60px;
}

.flex-contain {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: relative;
    padding: 0em .5em;
}

.modules-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 40px;
}

.modules {
    display: flex; 
    justify-content: flex-end;
    align-items: flex-start;   
    width: 40%;
    background-color: none;
    height: 100%;
    overflow-y: auto;
    padding-top: 2em;
    box-shadow: -6px 2px 10px rgba(0, 0, 0, 0.142);
}

p {
    font-size: 16px;
    font-weight: 700;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}


.header-name1 {
    color: #b31919;
    font-size: 20px;
    font-weight: 800;
    margin-bottom: .4em;
    text-align: left;
    padding-left: .5em;
}

.header-name2 {
    color: #033269;
    font-size: 20px;
    font-weight: 800;
    margin-bottom: .4em;
    text-align: left;
    padding-left: .5em;
}

</style>