<template>
  <div class="bg-image" id="about">
    <div class="row">
      <div class="reviews-text">
        <img :src="zillowLogo" alt="zillow.logo" class="zillow">
          <div class="inline-text">
            <p>Top-rated choice for</p><span>refinaning.</span>
          </div>
      </div>
      <div class="col-right">
          <img :src="navLogo" alt="liberty.logo" class="logo">
          <div class="reviews">
            <div class="zillow-reviews">
                <p3>Zillow</p3>
                <div class="stars">
                  <p4>4.9 </p4>
                  <i class="fa-solid fa-star"></i><i class="fa-solid fa-star"></i><i class="fa-solid fa-star"></i><i class="fa-solid fa-star"></i><i class="fa-solid fa-star"></i>
                </div>
            </div>
            <div class="google-reviews">
                <p3>Google</p3>
                  <div class="stars">
                    <p4>4.7 </p4>
                    <i class="fa-solid fa-star"></i><i class="fa-solid fa-star"></i><i class="fa-solid fa-star"></i><i class="fa-solid fa-star"></i><i class="fa-solid fa-star"></i>
                  </div>
            </div>
          </div>
      </div>
      <!-- <div class="reviews" id="bf-revz-widget-987654321301116"></div> -->
    </div>
    <div class="reviews-container">
      <div class="reviews-row">
          <div v-for="review in reviews" :key="index" class="flex">
            <p6>{{ review.name }}</p6>
            <hr>
            <p5>{{ review.text }}</p5>
      </div>
        
      </div>
    </div>
  </div> 
</template>

<script>
import navLogo from '@/landing-pages/assets/lhm_hori_logo_bluebell_redtype.svg';
import zillowLogo from '@/landing-pages/assets/Zillow_logo.svg';

export default {
  mounted() {
    this.loadBirdEyeScript();
  },
  methods: {
    loadBirdEyeScript() {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = 'https://birdeye.com/embed/v7/152284788004314/11/987654321301116';
      script.async = true;

      // Append the script to the document body
      document.body.appendChild(script);
    }
  },
  data (){
    return {
      navLogo: navLogo,
      zillowLogo: zillowLogo,

      reviews: [
          {
              name: 'Nathaniel M.',
              text: "What an unbelievable experience with LMH. Chad Plummer is the best. Down to earth, listened to my needs, and put in 115% effort to make it happen. My situation was so unique but the entire team including Chad at LMH made it happen. You got something unique—been kind of through the process with other companies and they made you feel not wanted not at all there. Thank you so much to Chad and the team at LMH.",
          },
          {
              name: 'R. Scott',
              text: "After coming out of a foreclosure 2019, we weren't sure if we'd qualify for a refi. However, Thomas worked non-stop on our behalf and six weeks later, the impossible was made possible thanks to the hard work and determination of LHM. Thanks Thomas and Korey for giving us a new start!",
          },
          {
              name: 'Louis S.',
              text: "We recently refinanced our home. Mitch helped us every step of the way. He provided great service and was always available to answer our questions. He was amazing. Liberty made the whole process so quick and easy. I would recommend them to anyone looking to buy a home or refinance!",
          }
        ]
    }
  }
};
</script>

<style scoped>
@media (max-width: 500px) {
  p {
    font-size: 35px !important;
  }

  .inline-text span {
    font-size: 35px !important;
  }

  .inline-text {
    line-height: .8em !important;
  }

  .reviews {
    gap: 15px !important;
  }

  p3 {
    font-size: 15px !important;
  }

  p4 {
    font-size: 28px !important;
  }

  .logo {
    width: 120px !important;
  }
}

@media (min-width: 501px) and (max-width: 900px) {
    p {
    font-size: 38px !important;
  }

  .inline-text span {
    font-size: 38px !important;
  }

    .inline-text {
    line-height: 1em !important;
  }
}

@media (max-width: 900px) {
  p6 {
    margin-top: 1.2em;
  }

  .reviews-row {
    margin: 3em 0em 0em 0em !important;
    flex-direction: column !important;
  }

  .row {
    flex-direction: column !important;
  }

  .reviews {
    width: 100% !important;
  }

  .reviews-text {
    width: 100% !important;
    justify-content: center !important;
    align-items: center;
    margin-bottom: 1.5em;
  }

  .inline-text {
    text-align: center !important;
    width: 100% !important;
    line-height: 1em;
  }


  .col-right {
    max-width: 100% !important;
    align-items: center !important;
  }

  .reviews {
    justify-content: center;
  }

  .reviews-text {
    max-width: 100% !important;
  }

  .zillow {
    width: 160px !important;
    margin-bottom: 1em;
  }


  .bg-image {
      padding: 3em 0 !important;
  }
}

hr {
  width: 80%; 
  margin: .5em;
}

p6 {
  font-weight: 700;
  font-size: 20px;
}

.review-col {
  text-align: left !important;
  display: flex;
  flex-direction: column;
}

.reviews-row {
  width: 100%;
  display: flex;
  padding: 0 .7em;
  justify-content: center;
  flex-direction: row;
  flex: 0 0 100%;
  flex: 1;
  gap: 1.4em;
  margin: 3em 0em;
}

.flex {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.reviews-container {
  max-width: 1350px;
}

p4 {
  font-size: 35px;
  font-weight: 800;
}

p5 {
  text-align: left;
}

p3 {
  font-size: 20px;
  font-weight: 500;
}

.zillow-reviews {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.google-reviews {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.zillow {
  width: 200px;
  margin-bottom: 1em;
}

.logo {
  width: 150px;
  margin-left: -.5em;
}

img {
  width: 120px;
  margin-bottom: .5em;
}

.inline-text p, .inline-text span {
    display: inline; 
}

.inline-text span {
  margin: 0em 0em 0em .2em;
  font-size: 45px;
  display: inline-block;
}

p {
  text-align: left;
  color: black;
  font-size: 45px;
  font-weight: 600;
  display: inline-block;
  margin: 0;
  padding: 0;
}

.inline-text {
  text-align: left;
  color: #b31919;
  font-size: 45px;
  font-weight: 800;
  line-height: 1.25em;
  display: inline-block;
  margin: 0;
  padding: 0;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1350px;
}

.col-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  max-width: 50%;
}

.reviews {
  display: flex;
  flex-direction: row;
  gap: 80px;
}

.reviews-text {
  max-width: 50%;
  display: flex;
  flex-direction: column;
}

.bg-image {
    /* background-image: url("@/assets/stripes-navy-gradient@2x.png"); */
    background-color: #e1ecf9;
    position: relative;
    background-size: cover;
    padding: 5em 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 2em 0;
}
</style>

