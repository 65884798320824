<template>
    <div class="bubble-container">
    <div class="image-bubble">
        <img :src="logo" alt="logo">
    </div>
    <ul>
        <li class="bubble" v-for="(step,index) in providedRefiSteps"
        :key="step.id"
        :class="((index+1) === providedRefiCounter) ? 'active-bubble' : '', ((index+1) < providedRefiCounter) ? 'step-done': '', ((index+1) === providedRefiCounter) ? 'step-done-in-advance': ''"
        >
            <div class="step-bubble">
                <div class="step-count"></div>
            </div>
            <div class="step-line">
                <div class="line-fill"></div>
            </div>
        </li>
    </ul>
    </div>
</template>

<script>
import logo from '@/landing-pages/assets/lhm_vert_logo_bluebell_redtype.svg'

export default{
    inject: ['getRefiSteps', 'getRefiCounter'],

    computed: {
        providedRefiSteps() {
            return this.getRefiSteps();
        },
        providedRefiCounter() {
            return this.getRefiCounter();
        }
    },

    data(){
        return {
            logo: logo
        }
    }

}
</script>

<style scoped>

@media (max-width: 410px){
    ul{
        width: 120% !important;
        margin-left: .5em !important;
        margin-right: 0em !important;
    }
}

@media (min-width: 410px) and (max-width: 500px){
    ul{
        width: 120% !important;
        margin-left: 1.5em !important;
        margin-right: 0em;
    }
}

@media (max-width: 500px){


    .step-line {
        height: 4px !important;
    }

    .line-fill {
        height: 4px !important;
    }
}

    img {
        width: 80px;
        height: 80px;
        padding: .5em .5em .7em .7em;
    }

    .image-bubble {
        background-color: white;
        border: 1px solid rgb(216, 213, 213);
        border-radius: 45px;
        position: absolute;
        z-index: 100;
    }

    ul{
        display: flex; 
        flex-direction: row; 
        width: 100%;
        top: 0;
        justify-content: center;
        align-items: center;
        max-width: 1350px;
        margin-left: 1em;
    }

    .bubble-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        flex-direction: column;
    }

    .bubble-list {
        display: flex;
        list-style: none;
        z-index:999;
        position: relative;
        top: 1.8em;
    }

    .active-bubble .step-count {
        display: block;
        z-index: 20;
    }

    .active-bubble .step-bubble,
    .step-done .step-bubble {
        width: 0px;
        height: 0px;
        background-color: #b31919;
    }

    .bubble {
        display: flex;
        align-items: center;
        flex-grow: 1;
        width: 100% !important;
        position: relative;
        height: 60px;
        z-index: 20;
    }

    .no-bubble {
        display: none;
    }

    .step-bubble {
        width: 0px;
        height: 0px;
        border-radius: 50%;
        background-color: #062850;
        transition: all .3s ease;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .step-count {
        color: white;
        font-weight: 500;
        font-size: 20px;
        z-index: 20;
    }

    .step-line {
        width: 100%;
        height: 4px;
        background-color: #a5c5e9;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        z-index: 10;
        position: absolute;
    }

    .line-fill {
        width: 0;
        height: 4px;
        background-color: #b31919;
        transition: all .1s ease;
    }

    .step-done .line-fill {
        width: 100%;
    }

    .bubble:last-child .step-line {
        display: none;
    }

    .bubble:last-child {
        max-width:60px;
    } 

    .step-done-in-advance .line-fill {
        width: 75% !important;
        transition: all .65s ease;

    }

</style>