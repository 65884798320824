<template>
    <div class="blog-box-back">
        <div class="image">
            <img class="blog-image" :src=resolvedImage>
        </div>
        <div class="content">
            <h>{{ blog.title }}</h>
            <h1>{{ blog.date }}</h1>
            <h2>{{ blog.subtitle }}</h2>
            <p>{{ truncatedContent }}</p>
        </div>
    </div>
</template>

<script>

export default {
    data(){
        return {
        }
    },

    props: {
        blog: {
            type: Object,
            required: true
        }
    }, 

    computed: {
    resolvedImage() {
        try {
            // Dynamically require the image based on the JSON path
            return require(`@/main-site/assets/${this.blog.image}`);
        } catch (e) {
            console.error(e);
            return ''; // Or a default image path if image not found
        }
    },

    truncatedContent() {
      return this.blog.content.length > 250
        ? this.blog.content.slice(0, 250) + '...'
        : this.blog.content;
    }
  }
}
</script>

<style scoped>
p {
    margin-top: 2em;
}

h2 {
    font-size: 18px;
}

h1 {
    font-size: 15px;
    margin-top: .5em;
}

h {
    font-size: 35px;
    font-weight: 700;
    line-height: 1.1em;
}

h, h1, h2, p {
    text-align: left;
}

.blog-image {
    object-fit: cover;
    width: 100%;
    height: 330px;
    overflow: hidden;
}

.content {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: 100%;
    padding: 1em;
}

.image {
    width: 50%;
    height: 100%;
}

.blog-box-back {
    background-color: transparent;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    max-width: 1250px;
    height: 330px;
    gap: 15px;
    padding-right: 1em;
    padding-bottom: 1em;
}
</style>