<template>
    <MainNav/>
    <div class="blog-banner"></div>
    <div class="blog-container">
        <div class="blog-flex-box">
            <div class="blog-top-box">
                <h1><span>Liberty Blog</span></h1>
                <p1>All you need to know about homebuying, qualifying, and more.</p1>
            </div>

            <div class="blogs-block">
                <div class="blogs">
                    <BlogItemOutline v-for="(blog, index) in filteredBlogs" :key="index" :blog="blog"/>
                </div>
                <div class="blog-menu-column">
                    <div class="search-block">
                        <div class="blog-search">
                            <h2>Search Blog</h2>
                            <input class="input" type="text" placeholder="Enter mortgage topics, terminology and more..."/>
                        </div>
                        <div class="blog-categories">
                            <h2>Browse categories</h2>
                            <label v-for="(category, index) in categories" :key="index">
                                <input type="checkbox" :value="category" v-model="selectedCategories" @change="filterBlogs">
                                {{ category }}
                            </label>
                        </div>
                    </div>
                    
                    <div class="popular-articles">
                        <h2>Popular articles</h2>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import MainNav from '@/main-site/components/MainNav.vue';
import blogs from '@/main-site/blog-posts.json';
import BlogItemOutline from '@/main-site/ui-elements/BlogItemOutline.vue';

export default {
components: {
    MainNav,
    BlogItemOutline,
},

data() {
    return {
        blogs: blogs.blogs,
            categories: [
                "Purchase", 
                "Refinance", 
                "First Time Homebuyer",
                "Low Credit", 
                "Self-Employed", 
                "Down Payment",
                "Mortgage Types", 
                "Assistance Programs", 
                "Mortgage News"
            ],
            selectedCategories: [], 
    };
},

computed: {
    filteredBlogs() {
        if (this.selectedCategories.length === 0) {
            return this.blogs;
        } else {
            return this.blogs.filter(blog =>
                this.selectedCategories.includes(blog.category1) ||
                this.selectedCategories.includes(blog.category2)
            );
        }
    }
},

methods: {
    filterBlogs() {
        if (this.selectedCategories.length === 0) {
            return this.blogs;
        } else {
            return this.blogs.filter(blog =>
                this.selectedCategories.includes(blog.category1) ||
                this.selectedCategories.includes(blog.category2)
            )
        }
    }
    }
}
</script>

<style scoped>


h2 {
    font-weight: 700;
    font-size: 20px;
    margin-bottom: .5em;
}

.blog-categories {
    display: flex;
    flex-direction: column;
    height: auto;
    align-items: flex-start;
}

.blogs-block {
    display: flex;
    flex-direction: row;
    gap: 100px;
    margin-top: 5em;
    align-items: flex-start;
}

.blog-menu-column {
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.search-block {
    width: 100%;
    background-color: #f1f1f1;
    border: 1px solid #e8e8e8;
    display: flex;
    flex-direction: column;
    height: auto;
    align-items: flex-start;
    margin-bottom: 2em;
    padding: 1em;
    border-radius: 8px;
}

.no-blogs {
    width: 100%;
}

.blogs {
    width: 70%;
    display: flex;
    flex-direction: column;
    gap: 30px;
}

p1 {
    color: white;
    font-size: 23px;
    font-weight: 600;
    margin-top: 1em;
    line-height: 1.4em;
    max-width: 80%;
}

span {
    color: white;
    text-shadow: none !important;
}

h1 {
    font-size: 65px;
    font-weight: 700;
    color: #a2a2a2;
    line-height: 1em;
    text-shadow: 1px 1px 9px #00000082, 0 0 1em #00000028, 
    0 0 0.1em rgba(0, 0, 0, 0.146);
}

.blog-top-box {
    background: linear-gradient(0.5deg, #a40c0c, #a40c0cf1, #b30909e9,  #b31919e9, #c60d0de9, #b3191978, #b3191900);
    max-width: 700px;
    min-height: 230px;
    margin-top: -12em;
    border-radius: 8px;
    padding: 2em 2.5em 3em 2.5em;
    display: flex;
    flex-direction: column;
}

.blog-flex-box {
    width: 100%;
    max-width: 1350px;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.blog-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 0em 0.7em;
}

.blog-banner {
    background-image: url("@/main-site/assets/blog-banner1.png");
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-size: cover; 
    background-position: center;
    background-repeat: no-repeat;
    overflow: hidden; 
    height: 25em;
    background-position: top -400px left 0px !important;
}
</style>
  