<template>
    <div class="counties-background">
        <div class="header">
            <h>Explore the hidden gems of <span>{{ areaDescription }}</span>.</h>
        </div>
        <div class="county-blocks">
            <div v-for="county in counties" :key="index" :id="county.id">
                <div class="cards">
                    <router-link class="menu-item" :to="{ path: this.pageName , hash: county.id }">
                        <img :src="county.src" :alt="county.idName" class="block-image">
                        <h4>{{ county.location }}</h4>
                        <p2>{{ county.subtitle }}</p2>
                    </router-link>
                    <div class="buttons-area">
                        <router-link :to="{ path: this.leadForm }">
                            <button class="get-help">Real Estate Help</button>
                        </router-link>
                        <router-link :to="{ path: pageName, hash: county.id }">
                            <button class="poi">Points of Interest</button>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        pageName: {
            type: String,
            required: true
        },
        areaDescription: {
            type: String,
            required: true
        },
        leadForm: {
            type: String,
            required: true
        },
        counties: {
            type: Array,
            required: true
        }
    }
}

</script>

<style scoped>
@media screen and (max-width: 460px) {
    .block-image {
        height: 130px !important;
    }
}

@media screen and (max-width: 768px) {
    h2 {
    font-size: 38px !important;
    }

    h3 {
        font-size: 38px !important;
    }

    p {
        font-size: 19px !important;
        min-width: 100% !important;
    }

    .block-image {
        max-height: 200px;
    }

    p2 {
        font-size: 16px !important;
        line-height: 1.3em;
        font-weight: 500 !important;
    }

    h4 {
        font-size: 18px !important;
    }
}

@media screen and (max-width: 1000px) {
    p {
        width: 80% !important;
    }

    .county-blocks {
        grid-template-columns: 1fr 1fr !important;
        gap: .8em !important;
    }

    .block-image {
        max-height: 230px;
        border-radius: 5px !important;
    }

}

.poi {
    color: white;
    background-color: #062850;
    border: none;
    border-radius: 10px;
    padding: .5em;
    font-weight: 700;
    width: 100%;
}

.get-help {
    color: white;
    background-color: #b31919;
    border: none;
    border-radius: 10px;
    padding: .5em;
    font-weight: 700;
    width: 100%;
}

.buttons-area {
    margin: .6em 0em;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

h {
    font-weight: 700;
    font-size: 35px;
    line-height: 1.2em;
    margin-bottom: .5em;
    display: inline-block;
    text-align: left;
    color: #062850;
}

span {
    color: #b31919;
    font-family: 'Merriweather-Italic'
}

.header {
    padding: 2em .7em 1em .7em;
    text-align: left;
    width: 100%;
    max-width: 1350px;
}

.blocks {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    max-width: 1350px;
    padding: 4em .7em 0 .7em;
}

.menu-item {
    text-decoration: none !important;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

h4{
    text-align: left;
    color: #b31919;
    font-weight: 800;
    font-size: 18px;
    margin-top: 1em;
}

p2 {
    font-size: 17px;
    font-weight: 600;
    text-align: left;
    line-height: 1.3em;
    text-decoration: none;
    color: black;
}

.county-blocks {
    display: grid;
    padding: 0 .7em;
    width: 100%;
    max-width: 1350px;
    justify-content: center;
    flex: 0 0 100%;
    flex: 1;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1.2em;
}

.cards {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.block-image {
    width: 100%;
    height: 280px;
    border-radius: 10px;
}

.counties-background {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    background-size: cover;
    justify-content: flex-start;
    /* background-image: url('@/assets/liberty-stars-red-stripes.svg'); */
}

h2 {
    font-weight: 500;
    font-size: 58px;
    line-height: 1em;
    font-family: 'Inter';
    margin: 0;
    color: #053873;
}

h3 {
    font-family: 'Playfair-Display';
    font-size: 58px;
    font-weight: 600;
    color: #b31919;
    margin: 0;
}

p {
    font-weight: 500;
    font-size: 24px;
    line-height: 1.3em;
    font-family: 'Inter';
    color: #7c7c80;
    width: 60%;
    margin-top: 1em;
}
</style>