<template>
    <div class="master-container">
        <div class="section-container">
            <div class="header">
                <h>Everything You Need for Refinancing, <span>All in One Place</span></h>
            </div>
            <div class="sections">
                <div class="section">
                    <i class="fa-solid fa-dollar-sign"></i>
                    <p>Finding a home is easy with our realtors.</p>
                    <p1>Find a house you'll love with Liberty's network of agents.</p1>
                </div>
                <div class="section">
                    <i class="fa-solid fa-calculator"></i>
                    <p>Work with dedicated underwriters.</p>
                    <p1>Every Liberty officer is backed by a dedicated underwriter, ensuring a smooth path to closing.</p1>
                </div>
                <div class="section">
                    <i class="fa-solid fa-user-tie"></i>
                    <p>The best mortgage expertise—at your disposal.</p>
                    <p1>Our 10+ years of experience helping thousands of homeowners is under your belt.</p1>
                </div>
                <div class="section">
                    <i class="fa-solid fa-clock"></i>
                    <p>Close in as fast as 3 days.</p>
                    <p1>We're pros at helping you close on-time.</p1>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>

@media (min-width: 500px) and (max-width: 850px) {
    p {
        max-height: 60px !important;
    }
}

@media screen and (max-width: 851px) {
    .sections {
        grid-template-columns: 1fr 1fr !important;
        gap: 1em !important;
    }

    h {
        font-size: 35px !important;
        margin-bottom: .8em !important;
        line-height: 1.1em !important;
    }

    p {
        height: 85px !important;
    }

    .master-container {
        margin: 5em 0.7em !important;
    }

    i {
        margin-bottom: .7em !important;
    }

}

span {
    color: #b31919;
}

i {
    background-color: rgb(180, 210, 248);
    padding: .9em;
    width: 45px;
    height: 45px;
    border-radius: 10px;
    color: #062850;
    margin-bottom: 1em;
}

p {
    text-align: left;
    font-weight: 800;
    font-size: 20px;
    height: 60px;
    line-height: 1.2em;
}

p1 {
    text-align: left;
}

.section {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
}

h {
    font-weight: 700;
    font-size: 50px;
    line-height: 1.2em;
    margin-bottom: 1.2em;
    width: 80%;
    text-align: left;
    color: #062850;
}

.header {
    display: flex;
}

.sections {
    display: grid;
    padding: 0;
    width: 100%;
    max-width: 1350px;
    justify-content: center;
    flex: 0 0 100%;
    flex: 1;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 2em;
}

.section-container { 
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    max-width: 1350px;

}

.master-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 8em .7em;
}
</style>