<template>
    <MainNav/>
    <div class="resources-banner">
        <div class="resources-container1">
            <div class="resources-top-box">
                <h1>Liberty <span>Resource Center</span></h1>
                <p1>Homebuyer guides, latest mortgage news, real-life case scenarios & mortgage calculators—all at your disposal.</p1>
            </div>
        </div>
    </div>
    
    <div class="resources-container">
        <div class="resources-flex-box">
            <div class="resources-content">
                <div class="blogs">
                    <h5>Latest Mortgage Topics</h5>
                    <BlogItem v-for="(blog, index) in blogs.slice(0, 4)" :key="index" :blog="blog"/>
                </div>
                <div class="right">
                    <div class="modules">
                        <button class="resource-area1"><i class="fa-solid fa-award"></i>Quizzes</button>

                        <button class="resource-area1"><i class="fa-solid fa-briefcase"></i>Case Scenarios</button>

                        <button class="resource-area"><i class="fa-solid fa-house-laptop"></i>Blog</button>

                        <button class="resource-area"><i class="fa-brands fa-youtube"></i>Mortgage Videos</button>

                        <button class="resource-area"><i class="fa-solid fa-calculator"></i>Mortgage Calculator</button>

                        <button class="resource-area"><i class="fa-solid fa-book"></i>Homebuying Guide</button>

                        </div>
                    <div class="menu">
                        <h2>Latest Videos</h2>
                        <div v-if="!error && videos.length" class="video-list">
                            <div v-for="video in videos.slice(0, 4)" :key="video.id.videoId" class="video-item">
                            <iframe
                                :src="`https://www.youtube.com/embed/${video.id.videoId}`"
                                frameborder="0"
                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscreen
                            ></iframe>
                            <div class="video-title">{{ video.snippet.title }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    </div>
    
</template>

<script>
import MainNav from '@/main-site/components/MainNav.vue';
import BlogItem from '@/main-site/ui-elements/BlogItem.vue';
import blogs from '@/main-site/blog-posts.json';
import axios from 'axios';


export default {
    components: {
        MainNav,
        BlogItem,
    },

    data(){
        return {
            blogs: blogs.blogs,
            videos: [],
            error: null,
            apiKey: 'AIzaSyC1cMTSNm5zesvyVGhi0-wguo1RZKALqs8',
            // apiKey: 'AIzaSyCetacE6LzjL_EBGIVaKPS4euJ7IOXv93k',
            channelId: 'UCvacKi4NkFvpXLyE0L28B1w',
            // channelId: 'UCItcRhFS0kAgFvmgzKrYdkQ',
            search: ''
        }
    },

    mounted() {
        this.fetchVideos();
    },

    methods: {
        async fetchVideos() {
        const url = `https://www.googleapis.com/youtube/v3/search?key=${this.apiKey}&channelId=${this.channelId}&part=snippet,id&order=date&maxResults=50`;

        try {
            const response = await axios.get(url);
            this.videos = response.data.items;
            this.filteredVideos = this.videos; // Initialize filteredVideos with all videos
        } catch (error) {
            this.error = 'Failed to fetch videos.';
        }
        },
    },
    }
</script>

<style scoped>

h5 {
    font-size: 30px;
    font-weight: 700;
    color: #062850;
}

h2 {
    font-size: 30px;
    font-weight: 700;
    margin-top: 2em;
    margin-bottom: .5em;
    color: #959595;
}

i {
    margin-right: 1em;
    height: auto;
    font-size: 30px;
}

.modules {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.resource-area1 {
    background-color: #b31919;
    width: 100%;
    border: none;
    color: white;
    padding: 1em;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    border-radius: 8px;
    font-weight: 700;
    font-size: 18px;

}

.resource-area {
    background-color: #062850;
    width: 100%;
    border: none;
    color: white;
    padding: 1em;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    border-radius: 8px;
    font-weight: 700;
    font-size: 18px;
}

button {
    width: 100%;
    border: none;
    color: white;
    padding: 1em;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    border-radius: 8px;
    font-weight: 700;
    font-size: 18px;
}

.right {
    width: 30%;
}

.blogs {
    width: 70%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.resources-content {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 80px;
    margin-top: 6em;
}

p1 {
    color: white;
    font-size: 20px;
    font-weight: 600;
    margin-top: 1em;
    line-height: 1.4em;
    max-width: 90%;
    text-align: left;
}

span {
    color: white;
    text-shadow: none !important;
}

h1 {
    font-size: 60px;
    font-weight: 700;
    color: #a4b4f7;
    line-height: 1em;
    text-align: left;
    text-shadow: 1px 1px 5px #0000004c, 0 0 1em #00000028, 
    0 0 0.1em rgba(0, 0, 0, 0.248);
}

.resources-top-box {
    /* background: linear-gradient(90deg, #ffffffe1, #ffffff91, #ffffff81,  #ffffff2e); */
    background: linear-gradient(90deg, #00000083, #0000005e, #0000003f,  #00000000);
    max-width: 750px;
    min-height: 230px;
    border-radius: 8px;
    padding: 2em 2.5em 3em 2.5em;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 5em;
}

.resources-flex-box {
    width: 100%;
    max-width: 1350px;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.resources-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.resources-container1 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    max-width: 1350px;
    width: 100%;
}


.resources-banner {
    background-image: url("@/main-site/assets/1.png");
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-size: cover; 
    background-position: center;
    background-repeat: no-repeat;
    overflow: hidden; 
    height: 28em;
    background-position: top -90px left 0px !important;
}
</style>