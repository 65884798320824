<template>
    <div class="banner-container">
    <div class="flex-box">
        <div class="text-block">
            <div class="column">
                <div class="header-text">
                    <h1>Simpler, faster, more affordable refinancing.</h1> 
                    <h2>The average homebuyer saves</h2> 
                    <h3>$160/mo. refinancing with us.</h3>                     
                </div>
                <div class="buttons-area">
                    <div class="row">
                        <div class="col">
                            <router-link class="menu-item" :to="refiForm"><button class="purchase">Shorten my term</button></router-link>
                        </div>
                        <div class="col">
                            <router-link class="menu-item" :to="refiForm">
                            <button class="refi">Lower my payments</button></router-link>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <router-link :to="cashOutForm">
                            <button class="cash-out">Get cash quick</button></router-link>
                        </div>
                    </div>
                </div> 
            </div> 
        </div>
        <div class="right-block">
            <div class="right">
                <img class="house-painting" :src="housePainting" alt="liberty.logo">
            </div> 
        </div>
    </div>
    </div>
</template>

<script>
import housePainting from '@/landing-pages/assets/house-painting2.png'

export default{
    data(){
        return{
            housePainting: housePainting
        }
    },

    props: {
        refiForm: {
            type: String,
            required: true
        },
        cashOutForm: {
            type: String,
            required: true
        }
    },
}

</script>

<style scoped>
 @media (max-width: 460px) {

    h1 {
        font-size: 35px !important;
    }


    h2, h3 {
        font-size: 18px !important;
        line-height: 1.2em !important;
        margin-bottom: .3em;
    }

    .house-painting {
        display: none;
    }

    .text-block {
        width: 100% !important;
        margin-top: 0 !important;
    }

    .header-text {
        margin-right: 1em;
    }

 }

 @media (min-width: 461px) and (max-width: 650px) {

    h1 {
        font-size: 35px !important;
    }


    h2, h3 {
        font-size: 18px !important;
    }

    h {
        font-size: 20px !important;
    }

    .house-painting {
        display: none;
    }

    .text-block {
        width: 100% !important;
        margin-top: 0 !important;
    }

    .header-text {
        margin-right: 1em;
    }
  
 }

 @media (min-width: 651px) and (max-width: 850px) {

    h {
        font-size: 25px !important;
    }

    h1 {
        font-size: 35px !important;
    }


    h2, h3 {
        font-size: 20px !important;
    }

    .house-painting {
        max-width: 350px;
    }

    .text-block {
        margin-top: 0em !important;
    }

 }

 @media (min-width: 851px) and (max-width: 1280px) {
    .house-painting {
        max-width: 450px;
    }

    h1 {
        font-size: 40px !important;
    }
 }


 .buttons-area {
    margin-top: 2em;
    padding-left: .7em;
    margin-right: -.7em;
 }

 .row {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin-bottom: 10px;
}

.row>* {
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: 0;
    padding-left: 0;
    margin-top: var(--bs-gutter-y);
}

 .purchase {
    background-color: #b31919;
    color: white;
    width: 100%;
    border: none;
    font-family: 'Figtree';
    padding: 1em 0;
    border-radius: 12px;
    font-weight: 700;
    line-height: 1.15em;
    height: 65px;
 }

 .refi {
    line-height: 1.15em;
    background-color: #062850;
    color: white;
    width: 100%;
    border: none;
    font-family: 'Figtree';
    padding: .6em 0;
    border-radius: 12px;
    font-weight: 700;
    height: 65px;
 }

 .cash-out {
    background-color: #aec7e3;
    color: black;
    width: 100%;
    border: none;
    font-family: 'Figtree';
    padding: 1em 0;
    border-radius: 12px;
    font-weight: 700;
 }

 .house-painting {
    width: 650px;
 }

.button {
    border: 1px solid white;
    background-color: white;
    color: #b31919;
    padding: .5em 2em;
    font-weight: 600;
    font-size: 18px;
}

.button-refi {
    border: 1px solid white;
    background-color: white;
    color: #062850;
    padding: .5em 2em;
    font-weight: 600;
    font-size: 18px;
}

h1 {
    color: black;
    font-weight: 700;
    font-size: 50px;
    /* text-shadow: 1px 1px 12px rgba(0, 0, 0, 0.343); */
    font-family: 'Figtree';
    line-height: 1.2em;
    margin-bottom: .5em;
    text-align: left;
}

h2 {
    color: #b31919;
    font-weight: 600;
    font-size: 25px;
    line-height: 1em;
    font-family: 'Merriweather';
    text-align: left;
}

h3 {
    color: #062850;
    font-weight: 600;
    font-size: 25px;
    line-height: 1em;
    font-family: 'Merriweather';
    text-align: left;
}

h {
    color: white;
    font-size: 30px;
    font-weight: 700;
    line-height: 1.2em;
    margin-bottom: .5em;
}

.text-block {
    display: flex;
    justify-content: center;
    margin-top: 2.5em;
    width: 50%;
}

.buttons-block {
    display: flex;
    justify-content: center;
    width: 50%;
}

.gallery {
    display:flex;
    margin-right:.8em;
    margin-left: .8em;
    align-items: center;
    justify-content: center;
    position: absolute;
    margin-top: 150px;
    padding: 1em .7em;
    flex-direction: column;
    gap: 0 !important;
}

.right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    /* background-color: #062850e8; */
}

.flex-box {
    width: 100%;
    max-width: 1350px;
    padding-top: 8rem;
    padding-bottom: 1rem;
    text-align: left;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.banner-container {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    background-size: cover;
    justify-content: center;
    padding: 0em .7em;
}



</style>

