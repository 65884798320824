<template>
    <div class="bg-image">
        <div class="blocks">
            <h2>Explore some of the most</h2>
            <div class="inline"><h4>vibrant</h4><h3>neighborhoods.</h3></div>
            <p>Not sure where to start looking? Check out these popular neighborhoods.</p>
        </div>
    </div>

    <div class="content">
        <ul>
        <div v-for="(county,index) in countiesList" :key="index" class="location-block" :id="county.idName">
            <div class="image-content">
                <div class="summary">
                    <h1>{{ index + 1 }}. {{ county.location }}</h1>
                    <p1>{{ county.subtitle }}</p1>

                    <div class="inline-summary"><h5>Affordability:</h5><h6>{{ county.affordability}}</h6></div>
                    <p4>{{ county.affordablityText }}</p4>
                    <div class="inline-summary"><h5>Parks & Recreation:</h5><h6>{{ county.parksRec }}</h6></div>
                    <p4>{{ county.parksRecText }}</p4>
                    <div class="inline-summary"><h5>Schools:</h5><h6>{{ county.schools }}</h6></div>
                    <p4>{{ county.schoolsText }}</p4>
                    <div class="inline-summary"><h5>Shopping & Nightlife:</h5><h6>{{ county.shoppingNightlife }}</h6></div>
                    <p4>{{ county.shoppingNightlifeText }}</p4>
                </div>
                <div class="image">
                    <img :src="county.src" :alt="county.idName" class="block-image">
                </div>
            </div>
            <div class="paragraph2" style="width: 100%;">
            <div class="paragraph">
                <div class="paragraph-container">
                    <p6>{{ county.summaryLine1 }}</p6>
                    <p6>{{ county.summaryLine2 }}</p6>
                    
                    <router-link :to="{ path: this.buttonPath }"><button>Get real estate help</button></router-link>
                </div>
            </div>
            </div>
        </div>
        </ul>
    </div>

</template>

<script>
export default {
    props: {
        buttonPath: {
            type: String,
            required: true
        },
        countiesList: {
            type: Array,
            required: true
        }
    }

}

</script>

<style scoped>

@media (max-width: 786px)  {

    .paragraph {
        margin: 2em 0 3.5em 0 !important;
    }

    .content {
        margin-top: 3em !important;
    }

    .image-content {
        flex-direction: column !important;
    }

    .block-image {
        height: 200px !important;
    }

    h2 {
        font-size: 42px !important;
        line-height: 1em;
    }

    h4 {
        font-size: 42px !important;
    }

    h3 {
        font-size: 42px !important;
    }

    p {
        font-size: 17px !important;
        line-height: 1.3em;
        width: 100% !important;
    }

    p1 {
        line-height: 1.2em !important;
    }

}

@media (max-width: 1000px)  {
    .block-image {
        max-height: 250px;
    }
}

button {
    color: white;
    background-color: #b31919;
    border: none;
    border-radius: 35px;
    font-weight: 800;
    padding: .5em 1em;
}

.paragraph-container {
    max-width: 1350px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1.5em .7em;
}

.paragraph {
    background-color: #efefef;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 2em 0 5em 0;
}

.inline-summary {
    margin-top: 1em;
}

.inline {
    text-align: left;
}

.summary {
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: left;
}

.image-content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    max-width: 1350px;
    padding: 0 .7em;
    gap: 20px;
}

.block-image {
    height: 350px;
}

.content {
    margin-top: 5em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.location-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

ul{
    margin: 0;
    padding: 0;
    width: 100%;
}

.bg-image {
    background: url("@/landing-pages/assets/summit-county-ohio.png");
    box-shadow: inset 0 0 0 800px #00000076;
    position: relative;
    background-position: 0px 0px !important;
    background-size: cover;
    height: 24em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-attachment: fixed;
}

.blocks {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    max-width: 1350px;
    padding: 4em .7em 0 .7em;
    width: 100%;
}

p6 {
    text-align: left;
    margin-bottom: 1em;
    font-size: 16px;
    font-weight: 500;

}

p4 {
    font-weight: 600;
    line-height: 1.2em;
    font-size: 16px;
}

h5 {
    color: rgb(185, 28, 14);
    font-size: 17px;
    font-weight: 800;
    line-height: 1.5rem;
    display: inline-block;
    margin-right: .3em;
}

h6 {
    color: #062850;
    font-size: 17px;
    font-weight: 800;
    line-height: 1.5rem;
    display: inline-block;
}

p1 {
    color: rgb(173, 173, 173);
    width: 100%;
    font-size: 17px;
    line-height: 1.3;
    font-weight: 800;
}

h1 {
    color: rgb(6, 40, 80);
    width: 100%;
    font-size: 22px;
    line-height: 1.3;
    font-weight: 800;
}

h2 {
    font-weight: 500;
    font-size: 50px;
    line-height: 1em;
    font-family: 'Figtree';
    margin: 0;
    color: white;
    text-align: left;
}

h4 {
    font-family: 'Playfair-Display';
    font-size: 50px;
    font-weight: 600;
    color: #b31919;
    margin: 0;
    text-align: left;
    display: inline-block;
    margin-right: .3em;
    line-height: 1em;
    /* text-shadow: 0px 1px 3px rgb(0, 0, 0); */
}

h3 {
    font-family: 'Playfair-Display';
    font-size: 50px;
    font-weight: 600;
    color: #062850;
    margin: 0;
    text-align: left;
    display: inline-block;
}

p {
    font-weight: 500;
    font-size: 24px;
    line-height: 1.3em;
    font-family: 'Figtree';
    color: white;
    width: 60%;
    margin-top: 1em;
    text-align: left;
}

</style>