<template>
    <div class="contain">
        <div class="col1">
            <img class="logo" :src=logo>
            <h1>Veterans Division Branches:</h1>
            <h8>honoring fallen veterans</h8>
        </div>
        <div class="col2">
            <div v-for="branch in branches" :key=index class="branch-box">
                <img class="image" :src=ChrisDoyle>
                <h4 class="branch-name">{{ branch.name }}</h4>
                <h5 class="branch-address">{{ branch.location }}</h5>
                <h6 class="branch-nmls">NMLS #{{ branch.nmls }}</h6>
                <a :href=branch.url><button class="visit-branch">Visit Branch Page</button></a>
            </div>
        </div>
    </div>
</template>

<script>
import ChrisDoyle from '@/main-site/assets/chris_doyle-copy.jpg'
import logo from '@/main-site/assets/lhm_hori_logo_white-transparent.png'

export default{
    data(){
        return{
            ChrisDoyle: ChrisDoyle,
            logo: logo,
            branches: [
                {
                    name: 'Branch 1',
                    location: '2716 Parkside Dr. Flint, MI 48503',
                    nmls: 2483849,
                    url: 'google.com'
                },
                {
                    name: 'Branch 2',
                    location: '2716 Parkside Dr. Flint, MI 48503',
                    nmls: 2483849,
                    url: 'google.com'
                },
                {
                    name: 'Branch 3',
                    location: '2716 Parkside Dr. Flint, MI 48503',
                    nmls: 2483849,
                    url: 'google.com'
                },
            ]
        }
    }
}
</script>

<style scoped>

.logo {
    max-width: 100px;
    margin-bottom: .7em;
}

.visit-branch {
    color: white;
    background-color: #b31919;
    border-radius: 35px;
    border: none;
    padding: .5em 1em;
}

h6 {
    font-size: 18px;
    font-weight: 700;
    color: #b31919;
}

h5 {
    font-size: 16px;
    font-weight: 700;
    color: #000000;
}

h4 {
    font-size: 20px;
    font-weight: 800;
    color: #062850;
    margin-top: 1em;
}

h8 {
    color: rgba(255, 255, 255, 0.574);
    font-size: 40px;
    line-height: 1em;
    font-weight: 700;
    text-align: left;

}

h1 {
    color: white;
    text-align: left;
    font-size: 55px;
    font-weight: 700;
    line-height: 1.1em;
}

.image {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    overflow: hidden;
    position: relative;
    object-fit: cover;
}

.branch-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* border: 2px solid #014faa; */
    border-radius: 8px;
    padding: 1.5em 1em;
    background-color: #dfe8f2;
}

.col2 {
    width: 75%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;
}

.col1 {
    width: 25%;
    background-color: #b31919;
    padding: 2.5em 3em 2.5em 1em;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    border-radius: 8px;
}

.contain {
    display: flex;
    flex-direction: row;
    max-width: 1350px;
    width: 100%;
    gap: 20px;
}
</style>