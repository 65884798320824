<template>
    <div class="container">
        <div class="form-header">
            <div class="back-home">
                <router-link class="menu-item" :to="{ path: '/alabama'}"><i class="fa-solid fa-caret-left back-button"></i></router-link>
            </div>

            <a href="tel:440-665-7627">
            <div class="call">
                <i class="fa-solid fa-phone phone"></i>
                <p>Need help? </p><span class="hide"><b>440-665-7627</b></span>
            </div>
            </a>
        </div>
        <div class="fix-height">
            <div v-if="!selectionMade && !firstScreen" class="flex-class">
                <h4>What kind of financing do you need?</h4>
                    <div class="home-type">
                        <span class="home-type-selection" @click="madeSelection('purchase')" :class="(selection === 'purchase') ? 'selected' : ''">            
                            <img class="responsive-img" :src="singleFamilyHome" alt=""><p2>Purchase</p2>
                        </span>
                        <span class="home-type-selection" @click="madeSelection('refi')" :class="(selection === 'refi') ? 'selected' : ''">            
                            <img class="responsive-img" :src="shortenTerm" alt=""><p2>Refinance</p2>
                        </span>
                    </div>
                    <span v-if="inputInvalid" class="warning">Please make a selection!</span>
                    <div class="buttons-area">
                        <span class="button" @click="redirectUser()">Next</span>
                    </div>
            </div>

            <PurchStepBubbles v-if="purchPicked"/>
            <PurchContent v-if="purchPicked" @send-counter-up="sendPurchCounterUp" @send-counter-down="sendPurchCounterDown"
            :webhook="webhook"
            :thankYou="thankYou"/>
            <RefiStepBubbles v-if="refiPicked"/>
            <RefiContent v-if="refiPicked" @send-counter-up="sendRefiCounterUp" @send-counter-down="sendRefiCounterDown"
            :webhook="webhook"
            :thankYou="thankYou"/>
        </div>
    </div>
</template>
    
<script>
import singleFamilyHome from '@/landing-pages/assets/Single-Family-Home2.png'
import shortenTerm from '@/landing-pages/assets/Refinance Homes.png'
import PurchStepBubbles from '@/landing-pages/components/step-components/PurchStepBubbles.vue'
import PurchContent from '@/landing-pages/components/lead-forms/PurchContent.vue'
import RefiStepBubbles from '@/landing-pages/components/step-components/RefiStepBubbles.vue'
import RefiContent from '@/landing-pages/components/lead-forms/RefiContent.vue'
import CashOutContent from '@/landing-pages/components/lead-forms/CashOutContent.vue'

export default{
    components: {
        PurchStepBubbles,
        PurchContent,
        RefiStepBubbles,
        RefiContent,
        CashOutContent,
    }, 

    props: ['purchPicked', 'refiPicked', 'firstScreen'],

    data () {
        return {
            purchSteps:[1,2,3,4,5,6,7,8,9],
            purchCounter: 1,
            refiSteps:[1,2,3,4,5,6,7,8],
            refiCounter: 1,
            selection: null,
            selectionMade: false,
            singleFamilyHome: singleFamilyHome,
            shortenTerm: shortenTerm,
            inputInvalid: false,
            webhook: 'https://hooks.zapier.com/hooks/catch/14662938/3vltde2/',
            thankYou: '/alabama-thank-you'
        };
    },

    provide() {
        return {
            getPurchSteps: () => this.purchSteps,
            getPurchCounter: () => this.purchCounter,
            getRefiSteps: () => this.refiSteps,
            getRefiCounter: () => this.refiCounter,
        }
    },

    methods: {
        redirectUser() {
            if (this.selection === 'purchase') {
                this.$router.push('/alabama-purch-form-purch');
                this.selectionMade = true;
            } else if (this.selection === 'refi') {
                this.$router.push('/alabama-purch-form-refi');
                this.selectionMade = true;
            } else if (this.selection === null) {
                this.inputInvalid = true;
            }
        },

        madeSelection(selection){
            this.selection = selection;
        },

        sendPurchCounterUp(){
            this.purchCounter++
        },

        sendPurchCounterDown(){
            this.purchCounter = this.purchCounter - 1;
        },

        sendRefiCounterUp(){
            this.refiCounter++
        },

        sendRefiCounterDown(){
            this.refiCounter = this.refiCounter - 1;
        },

    },

}
</script>

<style scoped>

@media screen and (max-width: 500px) {
    .hide {
        display: none !important;
    }

    .fix-height{
        height: 700px !important;
    }

    h4 {
        font-size: 26px !important;
    }
}
    .button {
        color: white;
        background-color: #b31919;
        border-radius: 20px;
        border: 0;
        padding: .5em 1em;
    }
    
    .warning {
        margin-bottom: -20px;
        color: red;
        font-weight: 700;
        font-size: 14px;
    }

    p2 {
        font-size: 18px;
        font-weight: 700;
        line-height: 1.2em;
        margin: 0em .5em;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .responsive-img {
        max-height: 43px;
        width: auto;
    }
    
    h4 {
        font-size: 33px;
        font-weight: 700;
        margin-bottom: .8em;
    }

    .selected {
        background-color: #f1fdfe;
        border: 2px solid #afcdf0 !important;
    }

    .home-type-selection {
        width: 100%;
        height: 80px;
        border: 1px solid #cecece;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        padding: .5em 1em;
        border-radius: 15px;
    }

    .home-type {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 15px;   
    }

    .flex-class {
        display: flex;
        flex-direction: column;
        border-radius: 20px;
        justify-content: center;
        gap: 10px;
        margin-top: 4em;
    }

    .container {
        width: 100%;
        max-width: 1350px;
    }

    .hide {
        font-size: 16px;
        font-weight: 700;
        margin: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-left: .2em;
    }

    p {
        font-size: 16px;
        font-weight: 700;
        margin: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .phone {
        background-color: #ffd2d2;
        padding: .5em;
        width: 35px;
        height: 35px;
        border-radius: 30px;
        color: #b31919;
        margin-right: .5em;
    }

    .back-button {
        background-color: rgb(180, 210, 248);
        padding: .9em;
        width: 45px;
        height: 45px;
        border-radius: 10px;
        color: #062850;
    }

    .call {
        display: inline-flex;
        text-decoration: none;
        color: black;
    }

    .form-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        max-width: 1350px;
        width: 100%;
        padding: 1em 0;
    }

    .fix-height{
        height: 750px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
    }

    button {
        color: white;
        background-color: #b31919;
        border-radius: 20px;
        border: 0;
        padding: .5em 1em;
    }

    .buttons-area {
        margin-top: 1em;
        display: flex;
        gap: 10px;
        left: 50%;
        right: 50%;
        justify-content: center;
    }
</style>
