<template>
  <nav class="navbar" >
    <div class="container">
      <div class="logo">
        <router-link class="menu-item" 
        :to=refiURL><img :src="navLogo" alt="liberty.logo"></router-link>
      </div>
      
      <div class="menu-toggle">
        <router-link class="menu-item" 
        :to=refiForm><button>Get Approved</button></router-link>
      </div>
      <!-- <div class="menu-toggle" v-if="$route.path === '/ohio-refi-modules'">
        <router-link class="menu-item" 
        :to="{ path: '/refi-form'}"><button>Get Approved</button></router-link>
      </div>
      <div class="menu-toggle2" v-if="$route.path !== '/ohio-refi-modules'">
        <router-link class="menu-item" 
        :to="{ path: '/learning-modules'}"><button>Case scenarios</button></router-link>
      </div> -->

      <div class="menu-items">
        <router-link class="menu-item" 
        :to="{ path: refiURL, hash: '#calc' }">Mortgage Calculator</router-link>

        <router-link class="menu-item" 
        :to=refiForm>Refinance</router-link>

        <div >
          <router-link class="menu-item" 
          :to=cashOutForm>Cash-Out</router-link>
        </div>

        <router-link class="menu-item" 
        :to="{ path: refiURL, hash: '#about' }">About Us</router-link>

        <!-- <router-link class="menu-item" 
        :to="{ path: '/learning-modules' }"><button class="case-button">Case scenarios</button></router-link> -->

        <router-link class="menu-item" 
        :to=refiForm><button>Get Approved</button></router-link>

      </div>
    </div>
  </nav>
</template>

<script>
import navLogo from '@/landing-pages/assets/lhm_hori_logo_bluebell_redtype.svg';
import navLogoWhite from '@/landing-pages/assets/lhm_hori_logo_white-transparent.png';


export default {
  name: 'Navigation',
  data() {
    return {
      navLogo: navLogo,
      navLogoWhite: navLogoWhite,
    };
  },

  props: {
      refiURL: {
          type: String,
          required: true
      },
      refiForm: {
          type: String,
          required: true
      },
      cashOutForm: {
          type: String,
          required: true
      },
  }

}
</script>

<style scoped>
@media screen and (max-width: 1000px) {
  .logo img {
  width: 180px !important;
}

  .container {
    padding-left:5px;
  }

  .menu-items {
    display: none !important;
  }

  .menu-toggle {
    display: block !important;
    position: absolute;
    right: 20px;
    z-index: 1000;
  }

  .menu-toggle2 {
    display: block !important;
    position: absolute;
    right: 20px;
    z-index: 1000;
  }

  .mobile-menu-open .menu-items {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 60px;
    left: 0;
    right: 0;
    z-index: 999;
    background-color: black;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  button {
    margin-right: -2em !important;
    font-size: 14px !important;
    padding: .5em .8em !important;
  }

}

.case-button {
  background-color: #062850;
  color: white;
  border: none;
  border-radius: 35px;
  font-weight: 700;
  padding: .5em 1.2em;
  font-family: 'Merriweather';
  font-size: 15px;
  margin-right: -2em;
}

button {
  background-color: #b31919;
  color: white;
  border: none;
  border-radius: 35px;
  font-weight: 700;
  padding: .5em 1.2em;
  font-family: 'Merriweather';
  font-size: 15px;
}

i{
  color: white;
  margin-top:50%;
}

.navbar {
  background-color: #fbfffeeb;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  position: absolute; 
  top: 0; 
  left: 0;
  right: 0;
  z-index: 1000;
  max-height: 100px;
  padding: .5em .7em;
  display: flex;
  justify-content: center;
}

.container {
  max-width: 1350px;
  margin: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo img {
  width: 210px;
}

.menu-toggle {
  display: none;
}

.menu-toggle2 {
  display: none;
}

.menu-items {
  display: flex;
  align-items: center;
  gap: 15px;
}

.menu-item {
  margin-right: 20px;
  font-weight: 700;
  color: #062850;
  cursor: pointer;
  text-decoration: none;
}

</style> 
