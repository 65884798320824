<template>
    <MainNav/>
    <div class="videos-banner">
        <div class="videos-container1">
            <div class="videos-top-box">
                <h1><span>Mortgage </span>Videos</h1>
                <p1>All you need to know about homebuying, qualifying, and more.</p1>
            </div>
        </div>
    </div>
    <div class="videos-container">
        <div class="youtube-feed">
            <!-- <div class="error-messages">
                <div v-if="error" class="error">{{ error }}</div>
                <div v-if="!error && !videos.length" class="loading">Loading...</div>
            </div> -->

            <div class="search">
                <h4>Search videos</h4>
                <input type="text" v-model="search" @input="fetchVideos" placeholder="Enter mortgage topics, terminology and more..."/>
                <div class="button-categories">
                    <button @click="setSearchTerm('rinette')">Rinette</button>
                    <button @click="setSearchTerm('wisconsin')">Wisconsin</button>
                </div>
            </div>

            <div v-if="!error && videos.length" class="video-list">
                <div v-for="video in filteredVideos" :key="video.id.videoId" class="video-item">
                <iframe
                    :src="`https://www.youtube.com/embed/${video.id.videoId}`"
                    frameborder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                ></iframe>
                <div class="video-title">{{ video.snippet.title }}</div>
                </div>
            </div>
        </div>
    </div>

</template>
  
<script>
import axios from 'axios';
import MainNav from '@/main-site/components/MainNav.vue';

export default {
components: {
    MainNav,
},

name: 'YouTubeFeed',
data() {
    return {
    videos: [],
    filteredVideos: [],
    error: null,
    apiKey: 'AIzaSyC1cMTSNm5zesvyVGhi0-wguo1RZKALqs8',
    // apiKey: 'AIzaSyCetacE6LzjL_EBGIVaKPS4euJ7IOXv93k',
    channelId: 'UCvacKi4NkFvpXLyE0L28B1w',
    // channelId: 'UCItcRhFS0kAgFvmgzKrYdkQ',
    search: ''
    };
},


mounted() {
    this.fetchVideos();
},

methods: {
    async fetchVideos() {
      const searchTerm = this.search.trim();
      let url = `https://www.googleapis.com/youtube/v3/search?key=${this.apiKey}&channelId=${this.channelId}&part=snippet,id&order=date&maxResults=50`;

      if (searchTerm) {
        url += `&q=${encodeURIComponent(searchTerm)}`;
      }

      try {
        const response = await axios.get(url);
        this.videos = response.data.items;
        this.filterVideos(); // Filter videos based on the search term
      } catch (error) {
        this.error = 'Failed to fetch videos.';
        console.error(error); // Log any errors
      }
    },

    filterVideos() {
      const searchTerm = this.search.toLowerCase();

      if (!searchTerm) {
        this.filteredVideos = this.videos;
        return;
      }

      this.filteredVideos = this.videos.filter(video => {
        const title = video.snippet.title.toLowerCase();
        const description = video.snippet.description.toLowerCase();
        return title.includes(searchTerm) || description.includes(searchTerm);
      });
    },

    setSearchTerm(term) {
      this.search = term;
      this.fetchVideos();
    }
},
};
</script>

<style scoped>

button {
    color: white;
    background-color: #062850;
    border: none;
    padding: .5em 1em;
    border-radius: 35px;
}

.button-categories {
    display: flex;
    flex-direction: row;
    margin-top: 1em;
    gap: 10px;
}

h4 {
    font-weight: 700;
    color: #b31919;
    font-size: 30px;
}

input {
    width: 50%;
    padding: .5em;
    background-color: transparent;
    border: 1px solid #cdcdcd;
    border-radius: 5px;
}

.search {
    width: 100%;
    padding: 1em 0em;
    margin-bottom: 2em;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.youtube-feed {
display: flex;
flex-direction: column;
align-items: center;
margin-top: 5em;
width: 100%;
}

.loading,
.error {
font-size: 1.2em;
margin: 20px;
}

.video-list {
display: grid;
grid-template-columns: 1fr 1fr 1fr;
gap: 30px;
max-width: 1350px;
width: 100%;
}

iframe {
    width: 430px;
    height: 230px;
}

.video-title {
font-size: 1em;
margin-top: 10px;
text-align: center;
}

p1 {
    color: white;
    font-size: 23px;
    font-weight: 600;
    margin-top: 1em;
    line-height: 1.4em;
    max-width: 90%;
    text-align: left;
}

span {
    color: #9cc2ff;
    /* text-shadow: none !important; */
}

h1 {
    font-size: 65px;
    font-weight: 700;
    color: white;
    line-height: 1em;
    text-shadow: 1px 1px 9px #00000082, 0 0 1em #00000028, 
    0 0 0.1em rgba(0, 0, 0, 0.146);
    text-align: left;
}

.videos-top-box {
    /* background: linear-gradient(90deg, #00000083, #0000005e, #0000003f,  #00000000); */
    max-width: 700px;
    min-height: 230px;
    margin-top: 6em;
    border-radius: 8px;
    padding: 2em 2.5em 3em 2.5em;
    display: flex;
    flex-direction: column;
}

.videos-flex-box {
    width: 100%;
    max-width: 1350px;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.videos-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0em 0.7em;
}

.videos-container1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0em 0.7em;
    width: 100%;
    max-width: 1350px;
}

.videos-banner {
    background-image: url("@/main-site/assets/2.png");
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-size: cover; 
    background-position: center;
    background-repeat: no-repeat;
    overflow: hidden; 
    height: 25em;
    background-position: top -170px left 0px !important;
}
</style>
  