<template>
    <MainNav/>
    <div class="liberty-lends-banner"></div>
    <div class="liberty-lends-container">
        <div class="liberty-lends-flex-box">
            <div class="liberty-lends-top-box">
                <h10>Liberty <span>Lends</span></h10>
                <p1>The success of our community is our success. Liberty partners with a charitable cause each month.</p1>
            </div>
        </div>
    </div>
    <div class="lends-banner">
        <div class="lends-blocks">
            <h1>We are proud to <span>do more</span> for our community.</h1>
            <p1>For more than a decade, we’ve been dedicated to assisting customers achieve their home purchase and refinance goals.</p1>

            <div class="three-columns">
                <div class="lends-col">
                    <h3>$2.8B+</h3>
                    <h4>Loan Volume</h4>
                </div>
                <div class="lends-col">
                    <h3>10+ Years</h3>
                    <h4>Of serving customers</h4>
                </div>
                <div class="lends-col">
                    <h3><i class="fa-solid fa-star"></i>4.9</h3>
                    <h4>Average Rating</h4>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import MainNav from '@/main-site/components/MainNav.vue';

export default {
    components: {
        MainNav,
    }
}
</script>

<style scoped>

p1 {
    color: white;
    font-size: 23px;
    font-weight: 600;
    margin-top: 1em;
    line-height: 1.4em;
    max-width: 80%;
}

span {
    color: white;
    text-shadow: none !important;
}

h10 {
    font-size: 65px;
    font-weight: 700;
    color: #062850;
    line-height: 1em;
    text-shadow: 1px 1px 9px #ffffffa6, 0 0 1em #ffea001b, 
    0 0 0.1em rgba(241, 0, 0, 0.779);
}

.liberty-lends-top-box {
    background: linear-gradient(0deg, #b31919, #b31919dc, #b31919dc,  #ffffff00);
    max-width: 700px;
    min-height: 230px;
    margin-top: -13em;
    border-radius: 8px;
    padding: 2em 2.5em 3em 2.5em;
    display: flex;
    flex-direction: column;
}

.liberty-lends-flex-box {
    width: 100%;
    max-width: 1350px;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.liberty-lends-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 0em 0.7em;
}

.liberty-lends-banner {
    background-image: url("@/main-site/assets/charity-run3.jpeg");
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-size: cover; 
    background-position: center;
    background-repeat: no-repeat;
    overflow: hidden; 
    height: 30em;
}
</style>