<template>
    <MainNav/>
    <div class="veterans-container">
        <div class="veterans-flex-box">
            <div class="second-box">
                <p9>Bringing our customers and their homes together for 10+ years.</p9>
            </div>
            <div class="veterans-box">
                <h>Liberty <div class="white-text">Veterans Division</div></h>
                <h2>Supporting veterans in their next chapter.</h2>
            </div>
        </div>
        <div class="width">
            <div class="icons-banner">
                <div class="icon-box">
                    <div class="col-left">
                        <img class="icon" :src="honor">
                    </div>
                    <div class="col-right">
                        <h6>Recognizing</h6>
                        <h5>Military Personnel</h5>
                        <p5>Each of our branches recognizes and celebrates fallen service members. </p5>
                    </div>
                </div>
                <div class="icon-box">
                    <div class="col-left">
                        <img class="icon" :src="training">
                    </div>
                    <div class="col-right">
                        <h6>Providing real-world</h6>
                        <h5>Workplace Training</h5>
                        <p5>Liberty takes pride in assisting veterans in transitioning in their careers.</p5>
                    </div>
                </div>
                <div class="icon-box">
                    <div class="col-left">
                        <img class="icon" :src="employment">
                    </div>
                    <div class="col-right">
                        <h6>Opportunites for</h6>
                        <h5>Employment</h5>
                        <p5>Liberty provides employment for veterans in the Liberty training programs upon completion.</p5>
                    </div>
                </div>
            </div>
        </div>
        <BranchesBanner style="margin-top: 5em;"/>

        <div class="third-row">
            <div>
                <img class="officers" :src="stockImage">
            </div>
            <div>
                <h3>Empowering <div class="red-text">veteran journeys.</div></h3>
                <h4>Paying forward to the people who served our nation.</h4>
                <p>Liberty Home Mortgage Corporation is a direct lender which means that all of your mortgage loan decisions are made by us. We do not shop your credit around to multiple lenders. We are the lender. We offer a full array of lending products. From Conventional to FHA, VA and USDA to rehab loans, purchase, refinance, debt consolidation and mortgages for less than perfect credit and hard to verify income borrowers, we cover almost all residential type mortgage loans.</p>
            </div>
        </div>
        
    </div>
</template>

<script>
import MainNav from '@/main-site/components/MainNav.vue';
import BranchesBanner from '@/main-site/components/BranchesBanner.vue';
import stockImage from '@/main-site/assets/veterans-stock1.png'
import honor from '@/main-site/assets/military-icon-red.svg'
import training from '@/main-site/assets/training-red.svg'
import employment from '@/main-site/assets/veteran-employment-red.svg'

export default {
    components: {
        MainNav,
        BranchesBanner
    },

    data(){
        return {
            stockImage: stockImage,
            honor: honor,
            training: training,
            employment: employment,
        }
    }
}
</script>

<style scoped>

h6 {
    margin-bottom: .3em;
    color: rgb(148, 148, 148);
    font-weight: 700;
    font-size: 17px;
}

p5 {
    font-size: 16px;
    font-weight: 600;
    color: black;
    text-align: left;
    line-height: 1.3em;
}

h5 {
    font-size: 25px;
    color: #062850;
    font-weight: 800;
}

.col-right {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.col-left {
    width: 35%;
}

.icon-box {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    gap: 15px;
}

.icon {
    max-height: 85px;
    margin-bottom: 1em;
    width: 70px;
}

.width {
    width: 100%;
    max-width: 1350px;
}

.icons-banner {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-around;
    align-items: flex-start;
    gap: 20px;
    margin-top: 6em;
    border-top: 1px solid #e6e7ea;
    border-bottom: 1px solid #e6e7ea;
    padding: 2em 0em;
}

.red-text {
    display: inline;
    color: #b31919 !important;
    text-shadow: none !important;
}

p {
    text-align: left;
    margin-top: 2.5em;
    font-weight: 500;
    line-height: 1.6em;
    margin-bottom: 1.5em;
}

h4 {
    font-family: 'Montserrat';
    font-weight: 700;
    font-size: 25px;
    line-height: 1.1em;
    text-align: left;
    color: rgb(157, 157, 157);
    margin-top: .4em;
}

p9 {
    font-family: 'Montserrat';
    font-weight: 700;
    font-size: 25px;
    color: white;
    text-align: left;
    line-height: 0em;
}

h3 {
    font-family: 'Montserrat';
    font-weight: 700;
    font-size: 50px;
    line-height: 1.1em;
    color: #062850;
    text-align: left;
}

.officers {
    height: 400px;
    padding-right: 20px;
}

.third-row {
    max-width: 1350px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 40px;
    margin-top: 8em;
}

h2 {
    color: rgba(255, 255, 255, 0.734);
    font-weight: 600;
}

.white-text {
    display: inline;
    color: white;
    text-shadow: none !important;

}

.second-box {
    background-color: #062950e0;
    color: black;
    margin-left: -2em;
    margin-top: 21em;
    max-width: 800px;
    padding: 1.8em 1em;
    line-height: 2.2em;
    border-radius: 8px;
}

.veterans-box {
    /* background: linear-gradient(-1deg, #a40c0c, #a40c0cf1, #b30909e9,  #b31919e9, #c60d0de9, #b3191978, #b3191900);  */
    background: linear-gradient(.5deg, #b3191900,  #b3191978, #c60d0de9,  #b31919e9, #a40c0c,  #b30909e9, #a40c0cf1  );
    margin-right: -4em;
    margin-top: -3.5em;
    max-width: 800px;
    padding: 2.5em 2em;
    line-height: 3em;
    border-radius: 8px;
}

span {
    color: #b31919;
}

h {
    font-size: 65px;
    font-weight: 700;
    color: #042245;
    text-shadow: 2px 2px 3px #b31919, 0 0 1em #ffea001b, 
    0 0 0.2em white;
}

.veterans-flex-box {
    width: 100%;
    height: 400px;
    max-width: 1350px;
    text-align: left;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    background-image: url('@/main-site/assets/brandon-banner3.png');
    background-size: cover; 
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 8px;
}

.veterans-container {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding: 0em 0.7em;
    margin-top: 11em;
    margin-bottom: 7em;
  }
</style>