<template>
    <MainNav/>
    <div class="purchase-banner"></div>
    <div class="purchase-container">
        <div class="purchase-flex-box">
            <div class="top-box">
                <h10>Liberty <span>Conventional Loans</span></h10>
                <p1>I want a home with a price that exceeds the conventional loan limit.</p1>
            </div>
        </div>
    </div>
    <div class="purchase-container2">
        <div class="purchase-flex-box2">
            <div class="text">
                <h>Liberty Conventional Loans</h>
                <p3>None of Liberty Home Mortgage's loans have prepayment penalties, which means that you can make additional payments as you choose and pay your loan off as soon as you would like.

                With our special, 5% down conventional loan, YOU have the option to have no Private Mortgage Insurance (PMI) and save even more money on a monthly basis. Our multiple PMI options puts the power into YOUR hands to help with your financial decisions.</p3>
            </div>
            <div class="qualifications">
                <h2>Perks of Conventional Qualifying</h2>
                <div class="perks">
                    <i class="fa-solid fa-check"></i>
                    <p4>Range of down payment options</p4>
                </div>  
                <div class="perks">
                    <i class="fa-solid fa-check"></i>
                    <p4>Range of down payment options</p4>
                </div> 
                <div class="perks">
                    <i class="fa-solid fa-check"></i>
                    <p4>Range of down payment options</p4>
                </div>           
            </div>
            
        </div>
    </div>
    <SearchBar/>
</template>

<script>
import MainNav from '@/main-site/components/MainNav.vue';
import SearchBar from '@/main-site/ui-elements/SearchBar.vue';

export default {
    components: {
        MainNav,
        SearchBar
    },
}
</script>

<style scoped>
@media screen and (max-width: 350px) {
    h10 {
        font-size: 43px !important;
    }
}

@media (min-width: 351px) and (max-width: 699px) {
    h10 {
        font-size: 48px !important;
    }

}

@media screen and (max-width: 700px){

    p3 {
        font-size: 16px !important;
    }

    .top-box {
        padding: 1.5em 1em 1.5em 1em !important;
    }

    .purchase-flex-box2 {
        flex-direction: column-reverse !important;
    }

    .purchase-container2 {
        margin-top: 1.5em !important;
    }

    .qualifications {
        width: 100% !important;
    }

    .text {
        width: 100% !important;
    }

    p1 {
        font-size: 19px !important;
    }

    h {
        font-size: 40px !important;
    }
}

p4 {
    font-weight: 600;
    font-size: 17px;
    width: 100%;
}

i {
    background-color: #b31919;
    color: white;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.perks {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    margin-top: 1em;
    width: 100%;
}

p3 {
    font-size: 17px;
}

h2 {
    font-weight: 800;
    font-size: 25px;
    margin-bottom: 1em;
    width: 400px;
    word-wrap: break-word !important;
    width: 95%;
}

h {
    font-weight: 800;
    font-size: 45px;
    margin-bottom: .5em;
    line-height: 1.1em;;
}

.text {
    width: 65%;
    display: flex;
    flex-direction: column;
}

.qualifications {
    background-color: white;
    box-shadow: 3px 3px 10px 6px rgba(0, 0, 0, 0.079); 
    min-width: 35%;
    height: auto;
    padding: 2em 1em;
}

p1 {
    color: white;
    font-size: 23px;
    font-weight: 600;
    margin-top: 1em;
    line-height: 1.4em;
    max-width: 80%;
}

span {
    color: white;
    text-shadow: none !important;
}

h10 {
    font-size: 65px;
    font-weight: 700;
    color: #062850;
    line-height: 1em;
    text-shadow: 1px 1px 9px #ffffffa6, 0 0 1em #ffea001b, 
    0 0 0.1em rgba(241, 0, 0, 0.779);
}

.purchase-flex-box2 {
    width: 100%;
    max-width: 1350px;
    text-align: left;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    gap: 50px;
}

.purchase-container2 {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding: 0em 0.7em;
    margin: 6em 0em;
}

.top-box {
    background: linear-gradient(0deg, #b31919, #b31919dc, #b31919dc,  #ffffff00);
    max-width: 700px;
    min-height: 230px;
    margin-top: -13em;
    border-radius: 8px;
    padding: 2em 2.5em 3em 2.5em;
    display: flex;
    flex-direction: column;
}

.purchase-flex-box {
    width: 100%;
    max-width: 1350px;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.purchase-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 0em 0.7em;
}

.purchase-banner {
    background-image: url("@/main-site/assets/home-interior1.png");
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-size: cover; 
    background-position: center;
    background-repeat: no-repeat;
    overflow: hidden; 
    height: 30em;
}
</style>