<template>
    <div class="banner-back">
        <div class="banner-restrict">
            <a href="tel:440-665-7627">
                <div class="call">
                    <i class="fa-solid fa-phone phone"></i>
                    <p>Questions? </p><span class="hide"><b>440-665-7627</b></span>
                </div>
            </a>
        </div>
    </div>
</template>

<style scoped>
@media (max-width: 650px) {
    .banner-back {
        margin: .3em 0em !important;
    }
}

@media (min-width: 651px) and (max-width: 850px) {
    .banner-back {
        margin: 1.5em 0em !important;
    }
}

@media (min-width: 851px) and (max-width: 1280px) {
    .banner-back {
        margin: 1.5em 0em !important;
    }
}


.banner-restrict {
    max-width: 1350px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
}

.banner-back {
    border-top: 1px solid #dcdff4;
    border-bottom: 1px solid #dcdff4;
    padding: .6em 1em;
    display: flex;
    justify-content: center;
    margin-bottom: 1em;
}

.hide {
    font-size: 16px;
    font-weight: 700;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: .2em;
}

 p {
    font-size: 16px;
    font-weight: 700;
    margin: 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.call {
    display: inline-flex;
    text-decoration: none;
    color: black;
}

.phone {
    background-color: #ffd2d2;
    padding: .5em;
    width: 35px;
    height: 35px;
    border-radius: 30px;
    color: #b31919;
    margin-right: .5em;
}
</style>