<template>
    <div class="about-background">
        <div class="about-blocks">
            <h1>We cherish our commitment and are <span>proud of our journey.</span></h1>
            <p1>For more than a decade, we’ve been dedicated to assisting customers achieve their home purchase and refinance goals.</p1>

            <div class="three-columns">
                <div class="col">
                    <h3>$2.8B+</h3>
                    <h4>Loan Volume</h4>
                </div>
                <div class="col">
                    <h3>10+ Years</h3>
                    <h4>Of serving customers</h4>
                </div>
                <div class="col">
                    <h3><i class="fa-solid fa-star"></i>4.9</h3>
                    <h4>Average Rating</h4>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>

span {
    color: #b31919;
}

h4 {
    color: #b31919;
    font-size: 25px;
    font-weight: 700;
}

h3 {
    color: #062850;
    font-size: 40px;
    font-weight: 700;
}

p1 {
    font-weight: 600;
    font-size: 21px;
    max-width: 600px;
    line-height: 1.4em;
    margin-top: .5em;
}

h1 {
    font-weight: 700;
    color: #062850;
    font-size: 45px;
    max-width: 800px;
}

.col {
    width: 100%;
    background-color: #f4f8fc;
    padding: 2em;
    display: flex;
    flex-direction: column;
    border-radius: 100px;
    border: 1px solid #eef4f9;
    box-shadow: 3px 3px 5px #0000000e;
}

.three-columns {
    display: flex;
    flex-direction: row;
    gap: 25px;
    margin-top: 3em;
    width: 95%;
}

.about-background {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    background-size: cover;
    justify-content: flex-start;
    padding: 0 .7em;
    margin: 9em 0em 0em 0em;
}

.about-blocks {
    display: flex;
    width: 100%;
    max-width: 1350px;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    flex: 0 0 100%;
    flex: 1;
    padding: 4em 0em;
    border-top: 1px solid #dadada;
}
</style>