<template>
    <div class="veterans-back">
        <div class="veterans-restrict">
            <div class="veterans-content">
                <div class="veterans-flex">
                    <i class="fa-solid fa-flag-usa vets" style="width: 50px; height: 50px;"></i>
                </div>
                <div class="veterans-flex">
                    <p>See how we're partnering with ___ to support & hire veterans. <b>Read more here.</b></p>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>

p {
    display: inline !important;
}

b {
    display: inline !important;
}

.veterans-flex {
    display: flex;
    text-align: left;
    align-items: center;
    line-height: 1.5em;
}

.veterans-restrict {
    max-width: 1350px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
}

.veterans-back {
    border-top: 2px solid #edeef3;
    border-bottom: 2px solid #edeef3;
    padding: .6em 1em;
    display: flex;
    justify-content: center;
}

.hide {
    font-size: 18px;
    font-weight: 700;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: .2em;
}

 p {
    font-size: 19px;
    font-weight: 700;
    margin: 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.veterans-content {
    display: flex;
    text-decoration: none;
    color: black;
    flex-direction: row;

}

.vets {
    background-image: linear-gradient(#d11111, #023b6d);;
    padding: .5em;
    width: 50px;
    height: 50px;
    border-radius: 30px;
    margin-right: .5em;
}

i {
    font-size: 25px;
    color: white;

}

</style>