<template>
    <div class="about-background">
        <div class="about-blocks">
            <h1>We cherish our commitment and are proud of our journey.</h1>
            <p1>For more than a decade, we’ve been dedicated to assisting customers achieve their home purchase and refinance goals.</p1>

            <div class="three-columns">
                <div class="col">
                    <h3>$2.8B+</h3>
                    <h4>Loan Volume</h4>
                </div>
                <div class="col">
                    <h3>10+ Years</h3>
                    <h4>Of servicing customers</h4>
                </div>
                <div class="col">
                    <h3><i class="fa-solid fa-star"></i>4.9</h3>
                    <h4>Average Rating</h4>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>

h4 {
    color: #b31919;
    font-size: 25px;
    font-weight: 700;
}

h3 {
    color: #062850;
    font-size: 40px;
    font-weight: 700;
}

p1 {
    font-weight: 500;
    font-size: 20px;
}

h1 {
    font-weight: 700;
}

.col {
    width: 100%;
    background-color: white;
    padding: 2em;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
}

.three-columns {
    display: flex;
    flex-direction: row;
    gap: 20px;
    margin-top: 3em;
}

.about-background {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    background-size: cover;
    justify-content: flex-start;
    padding: 0 .7em;
    margin: 7em 0em 0em 0em;
    background-color: #ebecf8;
}

.about-blocks {
    display: flex;
    width: 100%;
    max-width: 1350px;
    justify-content: center;
    flex-direction: column;
    flex: 0 0 100%;
    flex: 1;
    padding: 5em 0em;
}
</style>