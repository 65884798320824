<template>
    <div class="searchable-dropdown">
        <div class="dropdown">
        <select>
            <option 
            v-for="(state, index) in states" 
            :key="index" 
            @mousedown="selectState(state)"
            >
            {{ state }}
            </option>
        </select>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return{
            states: [
                "Alabama",
                "Alaska",
                "Arizona",
                "Arkansas",
                "California",
                "Colorado",
                "Connecticut",
                "Delaware",
                "Florida",
                "Georgia",
                "Hawaii",
                "Idaho",
                "Illinois",
                "Indiana",
                "Iowa",
                "Kansas",
                "Kentucky",
                "Louisiana",
                "Maine",
                "Maryland",
                "Massachusetts",
                "Michigan",
                "Minnesota",
                "Mississippi",
                "Missouri",
                "Montana",
                "Nebraska",
                "Nevada",
                "New Hampshire",
                "New Jersey",
                "New Mexico",
                "New York",
                "North Carolina",
                "North Dakota",
                "Ohio",
                "Oklahoma",
                "Oregon",
                "Pennsylvania",
                "Rhode Island"
            ],
        }
    },
}
</script>

<style scoped>
select {
    width: 300px;
    background-color: transparent;
    border: none;
    font-family: 'Figtree';
    font-weight: 600;
    font-size: 18px;
    border-bottom: 1px solid black;
}

</style>