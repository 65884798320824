<template>
<div class="search-background">
    <div class="search-banner">
        <div class="banner-center">
            <div>
                <h>Find a <span>Loan Officer</span></h>
            </div>
            <div class="box">
                <div class="search-row">
                    <h1>Search by state</h1>
                    <SearchByState/>
                </div>
                <div>
                    <p>OR</p>
                </div>
                <div class="search-row">
                    <h1>Search by name</h1>
                    <SearchByName/>
                </div>
            </div> 
        </div>
    </div>
</div>
<div class="specialties-area">
    <div class="specialties-container">
        <div class="specialty" :class="{ expanded: isExpanded }">
            <h3>Officers by Specialty</h3>
            <div class="grid">
                <div class="grid-square" v-for="specialty in specialties" :key="index">
                    <div class="top-box">
                        <p3>{{ specialty.text }}</p3>
                    </div>
                    <div class="bottom-box">
                        <p4>{{ specialty.program }}</p4>
                    </div>
                </div>
            </div>
        </div> 
    </div>
</div>


</template>

<script>
import SearchByState from '@/main-site/ui-elements/SearchByState.vue';
import SearchByName from '@/main-site/ui-elements/SearchByName.vue';

export default {
    components: {
        SearchByState,
        SearchByName,
    },

    data(){
        return {
            specialties: [
                {
                    text: "I need a range of downpayment options.",
                    program: "Fannie Mac Home Ready"

                },
                {
                    text: "I want to build a home.",
                    program: "Fannie Mac Home Ready"

                },
                {
                    text: "I want a renovation home.",
                    program: "Fannie Mac Home Ready"

                },
                {
                    text: "My credit's great but my down payment is low.",
                    program: "Fannie Mac Home Ready"

                },
                {
                    text: "I want affordable options & assistance.",
                    program: "Fannie Mac Home Ready"

                },
                {
                    text: "I want to live rural/semi-rural.",
                    program: "Fannie Mac Home Ready"

                },
                {
                    text: "I'm a veteran.",
                    program: "Fannie Mac Home Ready"

                },
                {
                    text: "I need to refinance.",
                    program: "Fannie Mac Home Ready"

                },
                {
                    text: "I want to cash-out.",
                    program: "Fannie Mac Home Ready"

                },
            ]
        }
    },
}
</script>

<style scoped>

.specialties-container {
    max-width: 1350px;
    width: 1350px;
}

.specialties-area {
    display: flex;
    justify-content: center;
    align-items: center;
}

span {
    color: #c71313;
    text-shadow: 1px 2px 8px #000000f4;
}

h {
    font-size: 65px;
    font-weight: 700;
    color: white;
}

 p {
    margin: 0;
    font-weight: 800;
    color: #033a79;
    font-size: 25 px;
 }

p4 {
    color: #b31919;
    font-weight: 800;
    font-size: 17px;
}

p3 {
    font-weight: 700;
    font-size: 25px;
    line-height: 1.1em;
    color: black;
}

h3 {
    color: #032b59;
    font-size: 50px;
    font-weight: 700;
    margin-bottom: .7em;
}

h1 {
    font-size: 22px;
    font-weight: 700;
    margin: 0;
}

.top-box {
    background-color: transparent;
    border-radius: 3px 3px 0px 0px;
    padding: 1em;
    color: #062850;
    line-height: 1.3em;
    min-height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 5px 3px rgba(0, 0, 0, 0.056);
    z-index: 10;
    width: 100%;
    height: 100%;
}

.bottom-box {
    background-color: #efefef;
    border-radius: 0px 0px 3px 3px;
    padding: .8em .3em;
    line-height: 1.2em;
    width: 100%;
}

.grid-square {
    color: transparent;
    padding: 0em;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    border: 2px solid #efefef;
    width: auto;
    height: auto;
    max-width: 100%;
}


.grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;    
    padding: 0em 0em 3em 0em;
    width: 100%;
    gap: 20px;
}

.search-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.specialty {
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    position: relative;
    margin-top: 5em;
}

.box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    gap: 20px;
    width: 100%;
    background-color: #ffffffde;
    padding: 2em 1em;
    border-radius: 5px;
}

.banner-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.search-banner {
    width: 100%;
    max-width: 1350px;
}

.search-background {
    background:linear-gradient(0deg, #000000b1, #0000006f), url("@/main-site/assets/wagner_office6_12_24-17.jpg");
    justify-content: center;
    align-items: center;
    display: flex;
    height: 23em;
    background-size: cover; 
    background-position: center;
    background-repeat: no-repeat;
}
</style>