<template>
    <div class="info-background">
        <div class="info-blocks">
            <div class="right1">
                <router-link :to="{ path: '/', hash: '#quiz' }" class="overlay">
                    <div>
                        <h5>Experienced loan officers</h5>
                        <p3>You're about to meet some of the most amazing loan officers.</p3>
                    </div>
                    <div>
                        <router-link :to="{ path: '/', hash: '#quiz' }"><button class="column-button">See Loan Officers</button></router-link>
                    </div>
                </router-link>
            </div>
            <div class="right2">
                <router-link :to="{ path: '/', hash: '#quiz' }" class="overlay">
                    <div>
                        <h5>Home Purchase & Refinance</h5>
                        <p3>Financing solutions that meet your needs.</p3>
                    </div>
                    <div>
                        <router-link :to="{ path: '/', hash: '#quiz' }"><button class="column-button">Get Approved Fast</button></router-link>
                    </div>
                </router-link>
            </div>
            <div class="right3">
                <router-link :to="{ path: '/', hash: '#quiz' }" class="overlay">
                    <div>
                        <h5>Case Scenarios: How ready are you to buy a home?</h5>
                        <p3>Free resources, case scenarios & knowledge center.</p3>
                    </div>
                    <div>
                        <router-link :to="{ path: '/', hash: '#quiz' }"><button class="column-button">Do these cases apply to you?</button></router-link>
                    </div>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
import officers from '@/main-site/assets/liberty-loan-officers1.png';
import momSon from '@/main-site/assets/mom+son.jpg';
import family1 from '@/main-site/assets/family-in-home.png';


export default {
    props: {
        purchForm: {
            type: String,
            required: true
        },
        refiURL: {
            type: String,
            required: true
        },
        cashOutURL: {
            type: String,
            required: true
        }
    },
    
    data (){
        return {
            officers: officers,
            momSon: momSon,
            family1: family1,
        }
    },

}

</script>

<style scoped>
@media screen and (max-width: 460px) {
    .block-image {
        height: 170px !important;
    }

    .info-blocks {
        display: flex !important;
        flex-direction: column !important;
    }

    h4 {
        font-size: 20px !important;
    }

    p2 {
        font-size: 17px !important;
    }
}

@media (min-width: 461px) and (max-width: 680px) {
    .info-blocks {
        display: flex !important;
        flex-direction: column !important;
    }

    .block-image {
        min-height: 280px !important;
    }
}

@media (min-width: 461px) and (max-width: 768px) {
    h2 {
    font-size: 38px !important;
    }

    h3 {
        font-size: 38px !important;
    }

    p {
        font-size: 19px !important;
        min-width: 100% !important;
    }

    .block-image {
        max-height: 200px;
    }

    p2 {
        font-size: 16px !important;
        line-height: 1.3em;
        font-weight: 500 !important;
    }

}

@media (min-width: 767px) and (max-width: 1000px) {
    p {
        width: 80% !important;
    }

    .block-image {
        max-height: 230px;
        border-radius: 5px !important;
    }

}

@media (max-width: 900px) {
    .info-background {
        margin: 4em 0em !important;
    }
}

.column-button {
    background-color: white;
    border: none;
    border-radius: 10px;
    padding: 1em 2em;
    margin-top: 1em;
    font-weight: 700;
}

a {
    text-decoration: none !important;
}

p3 {
    color: white !important;
    font-weight: 500;
    font-size: 18px;
    margin-left: 0 !important;
    line-height: 1.1em;
}

h5 {
    color: white;
    font-weight: 700;
    font-size: 35px;
    line-height: 1.1em;
}

.right3 .overlay {
    margin-top: 2em;
    border-radius: 10px;
    text-align: left;
    padding: 1.4em 3.3em 1em .8em;
    display: flex;
    flex-direction: column;
    background:linear-gradient(0deg, #062850, #00000000), url("@/main-site/assets/working-on-computer.png");
    position: relative;
    background-size: cover;
    height: 100%;
    width: 100%;
    width: auto;
    border-radius: 10px;
    justify-content: flex-end;
}

.right2 .overlay {
    margin-top: 1em;
    border-radius: 10px;
    text-align: left;
    padding: 1.4em 3.3em 1em .8em;
    display: flex;
    flex-direction: column;
    background:linear-gradient(0deg, #b31919, #00000000), url("@/main-site/assets/family-in-home.png");
    position: relative;
    background-size: cover;
    height: 100%;
    width: 100%;
    width: auto;
    border-radius: 10px;
    justify-content: flex-end;
}

.right1 .overlay {
    border-radius: 10px;
    text-align: left;
    padding: 1.4em 3.3em 1em .8em;
    display: flex;
    flex-direction: column;
    background:linear-gradient(0deg, #000000, #00000000), url("@/main-site/assets/liberty-loan-officers1.png");
    position: relative;
    background-size: cover;
    height: 100%;
    width: 100%;
    width: auto;
    border-radius: 10px;
    justify-content: flex-end;
}

.card-headers {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    height: 120px;
}

.col-button2 {
    background-color: #b31919;
    color: white;
    border: none;
    border-radius: 8px;
    padding: 1em;
    font-weight: 800;
    margin-top: .7em;
}

.col-button {
    background-color: #062850;
    color: white;
    border: none;
    border-radius: 8px;
    padding: 1em;
    font-weight: 800;
    margin-top: .7em;
}

p3 {
    color: black;
    margin-left: .5em;
}

.inline {
    display: flex;
    flex-direction: row;
    text-align: left;
    margin: .3em 0em;
}

i {
    background-color: #bfd8f4;
    border-radius: 35px;
    color: #023774;
    width: 30px;
    height: 30px;
    padding: .5em;
}

.menu-item {
    text-decoration: none !important;
}

h4{
    text-align: left;
    color: #b31919;
    font-weight: 800;
    font-size: 25px;
    margin-top: 1em;
}

p2 {
    font-size: 18px;
    font-weight: 600;
    text-align: left;
    line-height: 1.25em;
    text-decoration: none;
    color: black;
    margin-bottom: 1em;
    font-family: 'Figtree';
}

.info-blocks {
    display: grid;
    width: 100%;
    max-width: 1350px;
    justify-content: center;
    flex: 0 0 100%;
    flex: 1;
    grid-template-columns: 1fr 1fr 1fr;
    gap: .7em;
    min-height: 500px;
}

.cards {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.block-image {
    width: 100%;
    height: 250px;
    border-radius: 8px;
    overflow: hidden; /* Ensures any excess image is clipped */
    position: relative;
}

img{
    width: 100%;  /* Fill the width of the container */
    height: 100%;
    object-fit: cover; /* Cover the container while maintaining aspect ratio */
    object-position: center;
}

.info-background {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    background-size: cover;
    justify-content: flex-start;
    padding: 0 .7em;
    margin: 7em 0em;
}


h2 {
    font-weight: 500;
    font-size: 58px;
    line-height: 1em;
    font-family: 'Inter';
    margin: 0;
    color: #053873;
}

h3 {
    font-family: 'Playfair-Display';
    font-size: 58px;
    font-weight: 600;
    color: #b31919;
    margin: 0;
}

p {
    font-weight: 500;
    font-size: 24px;
    line-height: 1.3em;
    font-family: 'Inter';
    color: #7c7c80;
    width: 60%;
    margin-top: 1em;
}
</style>