<template>
        <MainNav/>

</template>

<script>
import MainNav from '@/main-site/components/MainNav.vue';

export default {
    components: {
        MainNav,
    }
}
</script>