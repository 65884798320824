<template>
    <div class="container">
        <div id="calc">
            <div class="col">
                <h1>How much home can I afford?</h1>
                <form class="mortgage-calculator" @submit.prevent="estPropertyValue">
                    <div class="monthly-payment">
                        <div class="top-row">
                                <span for="desired-monthly-payment" class="input-label">
                                Desired monthly payment
                                </span>
                                <span class="output">
                                $ {{ formattedMonthlyPayment() }}
                                 </span>
                        </div>
                        <div class="lower-row">
                            <input v-model="monthlyPayment" class="mySlider" id="desired-monthly-payment" type="range" min="0" max="7000" step="50" required=""/>
                        </div>
                    </div>

                    <div class="monthly-payment">
                        <div class="top-row">
                                <span for="desired-monthly-payment" class="input-label">
                                Down payment
                                </span>
                                <span class="output">
                                ${{ formattedDownPayment() }}
                                </span>
                        </div>
                        <div class="lower-row">
                            <input v-model="downPayment" class="mySlider2" id="desired-down-payment" type="range" min="0" max="200000" step="100" required=""/> 
                        </div>
                    </div>

                    <div class="monthly-payment">
                        <label for="desired-loan-term" class="input-label">
                        Loan term
                        </label>
                        <select v-model="mortgageYears" id="desired-loan-term" class="mortgage-calculator-input">
                            <option value="10" selected="">10 years</option>
                            <option value="15">15 years</option>
                            <option value="20">20 years</option>
                            <option value="30">30 years</option>
                        </select>
                    </div>

                    <div class="more-details">
                        <span @click="moreDetails" ><input type="checkbox" id="more-details-checkbox" class="more-details-checkbox"/>
                        </span>
                            <span for="more-details-checkbox" class="details-text">I have a home in mind and want to enter more details
                            </span>      
                    </div>

                    <div v-if="moreDetailsChecked" id="details-input-container">
                        <div class="monthly-payment">
                            <div class="top-row">
                                <span 
                                :class="{ 'disabled': !moreDetailsChecked }"
                                for="desired-monthly-payment" class="input-label">
                                    Monthly property taxes
                                </span>
                                <span class="output" :class="{ 'disabled': !moreDetailsChecked }">
                                    ${{ formattedPropertyTaxes() }}
                                </span>
                            </div>
                            <div class="lower-row">
                                <input :class="['mySlider3', moreDetailsChecked ? 'slider-enabled' : '']" :disabled="!moreDetailsChecked" v-model.number="monthlyPropertyTaxes" class="mySlider3 monthly-taxes-disabled" id="monthly-property-taxes" type="range" min="0" max="3000" step="100"/>
                            </div>
                        </div>

                        <div class="monthly-payment">
                            <div class="top-row">
                                <span :class="{ 'disabled': !moreDetailsChecked }" for="monthly-home-insurance" class="input-label">
                                    Monthly home insurance payment
                                </span>
                                <span class="output" :class="{ 'disabled': !moreDetailsChecked }">
                                ${{ formattedInsurance() }}
                            </span>
                            </div>
                            <div class="lower-row">
                                <input :class="['mySlider3', moreDetailsChecked ? 'slider-enabled' : '']" :disabled="!moreDetailsChecked" v-model="monthlyHomeInsurance" class="mySlider3 monthly-taxes-disabled" id="monthly-home-insurance" type="range" min="0" max="800" step="100"/>
                            </div>
                    </div>
                    </div>

                    <button id="calc-button" type="submit" class="calc-button">
                    Calculate
                    </button>

                </form>
            </div>

            <div class="col right" id="guide">
                <div class="result">
                    <div v-if="inputInvalid" style="color: red; font-weight: 800; font-size: 15px;">Please enter monthly property taxes & insurance</div>
                    <div class="home-val">Potential home value:</div>
                    <div v-if="!calculated && !tooLow" id="result-text" class="calc-result">
                    Press Calculate to find out.
                    </div>
                    <div v-if="calculated" class="home-value-result">${{ formattedHomeValue() }}</div>
                    <div v-if="tooLow">
                        <h4>Please <u>increase values</u>!!</h4>
                    </div>
                </div>

                <div class="calc-paragraph">
                    <h3>Use our free calculator! </h3>
                    <p2>Find out how much you can afford with our mortgage affordability calculator. Important!! Your monthly payment may include additional costs, including HOA fees, condo fees and utilities, which are not included. Loan terms and mortgage interest rates may vary based on credit score and your individual situation. <b>*interest rates are based on most current available market rates. </b><a href="tel:440-665-7627">Please consult a mortgage professional for the most accurate estimate .</a></p2>
                </div>

            </div>     
        </div>
    </div>
</template>

<script>
function calcMaxPrincipal(payment, interest_rate, num_payments, principal_payment_ratio, constant_payments)
{
    let ir_ratio = 1.0 - (1.0 + interest_rate) ** (-num_payments);
    return (payment - constant_payments) * ir_ratio / (interest_rate + principal_payment_ratio * ir_ratio);
}

export default {

    data (){
        return{
            monthlyPayment: 1500,
            downPayment: 10000,
            mortgageYears: 10,
            monthlyPropertyTaxes: 300,
            monthlyHomeInsurance: 150,
            moreDetailsChecked: false,
            propertyValue: 0,
            calculated: false,
            tooLow: false
        }
    },

    methods: {

        formattedInsurance () {
            return new Intl.NumberFormat('en-US').format(Math.round(this.monthlyHomeInsurance));
        },

        formattedPropertyTaxes () {
            return new Intl.NumberFormat('en-US').format(Math.round(this.monthlyPropertyTaxes));
        },

        formattedDownPayment () {
            return new Intl.NumberFormat('en-US').format(Math.round(this.downPayment));
        },

        formattedMonthlyPayment (){
            return new Intl.NumberFormat('en-US').format(Math.round(this.monthlyPayment))
        },

        formattedHomeValue (){
            return new Intl.NumberFormat('en-US').format(Math.round(this.propertyValue))
        },

        moreDetails (){
            this.moreDetailsChecked = !this.moreDetailsChecked
        },

        estPropertyValue()
        {
            let interestRates = {10: 0.0675, 15: 0.06625, 20: 0.07375, 30: 0.07375};

            if(this.moreDetailsChecked && (this.monthlyPropertyTaxes > 0 && this.monthlyHomeInsurance > 0))
            {
                this.propertyValue = calcMaxPrincipal(this.monthlyPayment, interestRates[this.mortgageYears] / 12.0, 12 * this.mortgageYears, 0.0, this.monthlyPropertyTaxes + this.monthlyHomeInsurance) + this.downPayment;
            }
            else
            {
                let defaultLTV = 0.8574;
                let defaultPropertyTaxRatio = 0.000461;
                let defaultHomeInsuranceRatio = 0.00429;
                let monthlyPropertyTaxRatio = (defaultPropertyTaxRatio / defaultLTV) / 12.0;
                let monthlyHomeInsuranceRatio = (defaultHomeInsuranceRatio / defaultLTV) / 12.0;

                this.propertyValue = calcMaxPrincipal(this.monthlyPayment, interestRates[this.mortgageYears] / 12.0, 12 * this.mortgageYears, monthlyPropertyTaxRatio + monthlyHomeInsuranceRatio, 0.0) + this.downPayment;

            };
            
            if (this.propertyValue > 0) {
                this.calculated = true;
                this.tooLow = false;
            } else {
                this.tooLow = true;
                this.calculated = false;
            }
            
        }
    },

}
</script>


<style scoped>
@media (max-width: 415px)  {

.guide-button {
    width: 90% !important;
}

.monthly-ins-input-size {
    max-width:48px;
}

p2 {
    font-size:16px !important;
    line-height: 0em !important;
}
}

@media (max-width: 799px)  {

    h1 {
        font-size: 27px !important;
    }

    #calc{
        flex-direction: column !important;
        gap: 20px !important;

    }

    form {
        text-align: center !important;
    }

    .input-label {
        font-size: 17px !important;
    }

    .more-details {
        text-align: left;
    }

    h2 {
        font-size: 17px !important;
        line-height: 1.3em;
        text-align: center;
    }

    .navy-box {
        display: none !important;
    }

    .guide-button {
        font-size: 16px !important;
    }

    .right {
        max-width: 100% !important;
        padding: 2em 1em !important;
    }

    .container {
        margin-top: 5em !important;
        margin-bottom: 4em !important;
    }

}

@media (min-width: 800px) and (max-width: 1100px)  {
    #calc {
        gap: 60px !important;
    }
}

h4 {
    font-size: 22px;
    font-weight: 700;
}

.output {
    font-size: 18px;
    font-weight: 800;
}

.slider-enabled::-webkit-slider-thumb {
    background-color: red !important;
}

.slider-enabled::-moz-range-thumb {
    background-color: red !important;
}

.disabled {
    color: rgb(162, 162, 162);
}

.mySlider3[type="range"]::-webkit-slider-runnable-track {
    background: #e2ecf7;
    height: 1rem;
    border-radius: 10px;
}

.mySlider3[type="range"]::-moz-range-track {
    background: #e2ecf7;
    height: 1rem;
    border-radius: 10px;
}

input .mySlider3[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none; 
    appearance: none;
    margin-top: -5px;
    background-color: #062850;
    height: 1.5rem;
    width: 1.5rem;
    border-radius: 50%;  
}

.mySlider3 {
    width: 100% !important;
    -webkit-appearance: none;
    appearance: none;
    cursor: pointer;
    display: flex;
}

input :disabled .mySlider3[type="range"]::-webkit-slider-thumb {
    color: blue;
}

.mySlider[type="range"]::-webkit-slider-runnable-track {
    background: #e9e2e2;
    height: 1rem;
    border-radius: 10px;
}

.mySlider[type="range"]::-moz-range-track {
    background: #e9e2e2;
    height: 1rem;
    border-radius: 10px;
}

.mySlider[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none; 
    appearance: none;
    margin-top: -5px;
    background-color: #b31919;
    height: 1.5rem;
    width: 1.5rem;
    border-radius: 50%;  
}

.mySlider {
    width: 100% !important;
    -webkit-appearance: none;
    appearance: none;
    cursor: pointer;
    display: flex;
}

input.mySlider {
    -webkit-appearance: none;
    appearance: none;
    cursor: pointer;
}

.mySlider2[type="range"]::-webkit-slider-runnable-track {
    background: #e2ecf7;
    height: 1rem;
    border-radius: 10px;
}

.mySlider2[type="range"]::-moz-range-track {
    background: #e2ecf7;
    height: 1rem;
    border-radius: 10px;
}

.mySlider2[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none; 
    appearance: none;
    margin-top: -5px;
    background-color: #062850;
    height: 1.5rem;
    width: 1.5rem;
    border-radius: 50%;  
}

.mySlider2 {
    width: 100% !important;
    -webkit-appearance: none;
    appearance: none;
    cursor: pointer;
    display: flex;
}

b {
    font-weight: 800 !important;
}

select {
    padding: .6em;
    border: 1px solid rgb(217, 217, 217);
    font-weight: 700;
    margin-top: .3em;
}

input, select {
    background-color: transparent;
}

.details-text {
    font-size: 17px;
    font-weight: 600;
    margin-top: -.2em;
    line-height: 1.15em;
}

b {
    font-weight: 900;
}

p2 {
    font-weight: 500;
    font-size: 17px;
}

h3 {
    font-weight: 800;
    font-size: 19px;
    color: #062850;
}
.calc-paragraph {
    text-align: left;
    margin-top: 2.5em;
}

.calc-paragraph1 {
    display: none;
}

.guide-button {
    background-color: white;
    border: none;
    color: #b31919;
    width: 65%;
    border-radius: 30px;
    padding: .6em 0;
    font-weight: 800;
    font-size: 17px;
    padding: .5em 1em;
    text-decoration: none;
}

h2 {
    font-weight: 800;
    font-size: 19px;
    line-height: 1.3em;
    color: white;
    margin-bottom: 1em;
}

.home-val {
    font-weight: 800;
    font-size: 25px;
    color: #b31919;
}

.calc-result {
    font-size: 20px;
    font-weight: 700;
}

.home-value-result {
    font-size: 25px;
    color: #b31919;
    font-weight: 800;
}

.calc-button {
    background-color: #b31919;
    border: none;
    color: white;
    width: 100%;
    border-radius: 30px;
    padding: .5em 0;
    font-weight: 800;
    margin: 1em 0;
    font-size: 18px;
}

.mortgage-calculator-input {
    border-radius: 8px;
}

.input-label {
    text-align: left;
    font-size: 18px;
    font-weight: 700;
}

input {
    box-sizing: border-box;
    border: none;
    font-size: 17px;
}

.more-details {
    margin: 1.3em 0em;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: 700;
}


.more-details-checkbox {
    margin-right: .6em;
    height: 23px;
    width: 23px;
    border: 2px solid rgb(244, 0, 0) !important;
}

.right {
    max-width: 500px;
    background-color: rgb(233, 240, 249);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2.5em 1em;
    border-radius: 10px;
    height: 100%;
}


h1 {
    font-size: 30px;
    font-weight: 800;
    margin-bottom: 1em;
    color: #062850;
    text-align: left;
}

.lower-row {
    margin-top: .7em;
    margin-bottom: 1.5em;
}


.top-row {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}

.monthly-payment {
    display: flex;
    flex-direction: column;
    margin-bottom: .5em;
}

#calc {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width:100%;
    gap: 100px;
}

.container {
    max-width: 1350px;
    min-height: 450px;
    margin-top: 8em;
    margin-bottom: 7em;
}

.navy-box {
    padding: 2em 2em;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

p {
    color: white;
    margin: 0;
}

form {
    text-align: left;
}

</style>