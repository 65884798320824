<template>
<div class="wrapper">
    <div class="result-bar">
        <p>Filter results</p>
    </div> 
    <div class="filter-content">
        <p1>abc</p1>
    </div>
</div>
</template>

<style scoped>
.filter-content {
    padding: 1em;
}

.result-bar {
    background-color: #f2f2f2;
    width: 100%;
    display: flex;
    justify-content: center;
    padding: .7em;
    border-radius: 8px 8px 0px 0px;
}

p {
    margin: 0;
    font-weight: 700;
}

.wrapper {
    border: 1px solid #e8e8e8;
    border-radius: 8px;
}
</style>