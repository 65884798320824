import Vue from 'vue'
import { createRouter, createWebHashHistory, createWebHistory } from 'vue-router'
import AlabamaThankYou from '@/landing-pages/views/alabama/AlabamaThankYou.vue'
import AlabamaHome from '@/landing-pages/views/alabama/AlabamaHome.vue'
import AlabamaPurchForm from '@/landing-pages/views/alabama/AlabamaPurchForm.vue'
import AlabamaRefiForm from '@/landing-pages/views/alabama/AlabamaRefiForm.vue'
import AlabamaModules from '@/landing-pages/views/alabama/AlabamaModules.vue'

import ColumbusThankYou from '@/landing-pages/views/columbus/ColumbusThankYou.vue'
import ColumbusHome from '@/landing-pages/views/columbus/ColumbusHome.vue'
import ColumbusPurchForm from '@/landing-pages/views/columbus/ColumbusPurchForm.vue'
import ColumbusRefiForm from '@/landing-pages/views/columbus/ColumbusRefiForm.vue'
import ColumbusModules from '@/landing-pages/views/columbus/ColumbusModules.vue'

import NEOThankYou from '@/landing-pages/views/neo/NEOThankYou.vue'
import NEOHome from '@/landing-pages/views/neo/NEOHome.vue'
import NEOPurchForm from '@/landing-pages/views/neo/NEOPurchForm.vue'
import NEORefiForm from '@/landing-pages/views/neo/NEORefiForm.vue'
import NEOModules from '@/landing-pages/views/neo/NEOModules.vue'

import MedinaThankYou from '@/landing-pages/views/medina/MedinaThankYou.vue'
import MedinaHome from '@/landing-pages/views/medina/MedinaHome.vue'
import MedinaRealEstateForm from '@/landing-pages/views/medina/MedinaRealEstateForm.vue'
import MedinaModules from '@/landing-pages/views/medina/MedinaModules.vue'
import MedinaCountiesPage from '@/landing-pages/views/medina/MedinaCountiesPage.vue'

import DaytonThankYou from '@/landing-pages/views/dayton/DaytonThankYou.vue'
import DaytonHome from '@/landing-pages/views/dayton/DaytonHome.vue'
import DaytonRealEstateForm from '@/landing-pages/views/dayton/DaytonRealEstateForm.vue'
import DaytonModules from '@/landing-pages/views/dayton/DaytonModules.vue'
import DaytonCountiesPage from '@/landing-pages/views/dayton/DaytonCountiesPage.vue'

import MarylandThankYou from '@/landing-pages/views/md-dc/MarylandThankYou.vue'
import MarylandHome from '@/landing-pages/views/md-dc/MarylandHome.vue'
import MarylandRealEstateForm from '@/landing-pages/views/md-dc/MarylandRealEstateForm.vue'
import MarylandModules from '@/landing-pages/views/md-dc/MarylandModules.vue'
import MarylandCountiesPage from '@/landing-pages/views/md-dc/MarylandCountiesPage.vue'

import OhioRefiThankYou from '@/landing-pages/views/ohio-refi/OhioRefiThankYou.vue'
import OhioRefiHome from '@/landing-pages/views/ohio-refi/OhioRefiHome.vue'
import OhioRefiForm from '@/landing-pages/views/ohio-refi/OhioRefiForm.vue'
import OhioRefiModules from '@/landing-pages/views/ohio-refi/OhioRefiModules.vue'
///////////////////////////////////////////////////////

import MainHome from '@/main-site/views/MainHome.vue'
import FindALoanOfficer from '@/main-site/views/FindALoanOfficer.vue'
import SearchResultsPage from '@/main-site/views/SearchResultsPage.vue'
import VeteransPage from '@/main-site/views/VeteransPage.vue'
import AboutUs from '@/main-site/views/AboutUs.vue'
import LibertyLends from '@/main-site/views/LibertyLends.vue'
import ResourceCenter from '@/main-site/views/ResourceCenter.vue'
import QuizAndCases from '@/main-site/views/QuizAndCases.vue'
import VideosPage from '@/main-site/views/VideosPage.vue'
import BlogPage from '@/main-site/views/BlogPage.vue'
import BuyPage from '@/main-site/views/BuyPage.vue'
import RefiPage from '@/main-site/views/RefiPage.vue'
import CashOutPage from '@/main-site/views/CashOutPage.vue'
import CompanyPurchForm from '@/main-site/views/CompanyPurchForm.vue'
import ConventionalPage from '@/main-site/views/purchase-loans/ConventionalPage.vue'
import FHAPage from '@/main-site/views/purchase-loans/FHAPage.vue'
import FixedInterestPage from '@/main-site/views/purchase-loans/FixedInterestPage.vue'
import HomeReadyPage from '@/main-site/views/purchase-loans/HomeReadyPage.vue'
import ManufacturedPage from '@/main-site/views/purchase-loans/ManufacturedPage.vue'
import RenovationPage from '@/main-site/views/purchase-loans/RenovationPage.vue'
import USDAPage from '@/main-site/views/purchase-loans/USDAPage.vue'
import VAPage from '@/main-site/views/purchase-loans/VAPage.vue'

import BlogPageTwo from '@/main-site/views/BlogPageTwo.vue'


import NotFound from '@/landing-pages/views/404.vue'

// Vue.use(VueRouter);

const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: '/main-home',
            name: 'Main Home',
            component: MainHome
        },
        {
            path: '/about',
            name: 'About Us',
            component: AboutUs,
        },
        {
            path: '/find-a-loan-officer',
            name: 'Find a Loan Officer',
            component: FindALoanOfficer,
        },
        {
            path: '/search-results-page',
            name: 'Search Results Page',
            component: SearchResultsPage,
        },
        {
            path: '/veterans-division',
            name: 'Veterans Division',
            component: VeteransPage,
        },
        {
            path: '/liberty-lends',
            name: 'Liberty Lends',
            component: LibertyLends,
        },
        {
            path: '/resource-center',
            name: 'Resource Center',
            component: ResourceCenter,
        },
        {
            path: '/quiz-and-cases',
            name: 'Learning Center',
            component: QuizAndCases,
        },
        {
            path: '/videos',
            name: 'Videos',
            component: VideosPage,
        },
        {
            path: '/blogs',
            name: 'Blogs',
            component: BlogPage,
        },
        {
            path: '/blogs-two',
            name: 'Blogs Two',
            component: BlogPageTwo,
        },
        {
            path: '/buy',
            name: 'Buy',
            component: BuyPage,
        },
        {
            path: '/refinance',
            name: 'Refinance',
            component: RefiPage,
        },
        {
            path: '/cash-out',
            name: 'Cash Out',
            component: CashOutPage,
        },
        {
            path: '/purchase-form',
            name: 'Purchase Form',
            component: CompanyPurchForm,
        },
        {
            path: '/conventional-loans',
            name: 'Liberty Conventional Loans',
            component: ConventionalPage,
        },
        {
            path: '/fha-loans',
            name: 'Liberty FHA Loans',
            component: FHAPage,
        },
        {
            path: '/fixed-interest-rate-loans',
            name: 'Liberty Fixed Interest Rate Loans',
            component: FixedInterestPage,
        },
        {
            path: '/home-ready-loans',
            name: 'Fannie Mae Home Ready Loans',
            component: HomeReadyPage,
        },
        {
            path: '/manufactured-home-loans',
            name: 'Liberty Manufactured Home Loans',
            component: ManufacturedPage,
        },
        {
            path: '/renovation-loans',
            name: 'Liberty Renovation Loans',
            component: RenovationPage,
        },
        {
            path: '/usda-loans',
            name: 'Liberty USDA Loans',
            component: USDAPage,
        },
        {
            path: '/va-loans',
            name: 'Liberty VA Loans',
            component: VAPage,
        },
///////////////////////////////////////////////////////

        {
            path: '/alabama',
            name: 'Alabama',
            component: AlabamaHome
        },
        {
            path: '/alabama-thank-you',
            name: 'Alabama Thank You',
            component: AlabamaThankYou
        },
        {
            path: '/alabama-purch-form',
            name: 'Alabama Purchase Form',
            component: AlabamaPurchForm,
            props: { firstScreen: false }
        },
        {
            path: '/alabama-purch-form-purch',
            name: 'Alabama Purchase Form Purch',
            component: AlabamaPurchForm,
            props: { purchPicked: true, firstScreen: true }
        },
        {
            path: '/alabama-purch-form-refi',
            name: 'Alabama Purchase Form Refi',
            component: AlabamaPurchForm,
            props: { refiPicked: true }
        },
        {
            path: '/alabama-refi-form',
            name: 'Alabama Refi Form',
            component: AlabamaRefiForm,
            props: { cashOutSelected: false }
        },
        {
            path: '/alabama-refi-form-cash-out',
            name: 'Alabama Refi Form Cash Out',
            component: AlabamaRefiForm,
            props: { cashOutSelected: true }
        },
        {
            path: '/alabama-modules',
            name: 'Columbus Purchase Learning Modules',
            component: AlabamaModules
        },
///////////////////////////////////////////////////////
        {
            path: '/columbus',
            name: 'Columbus',
            component: ColumbusHome
        },
        {
            path: '/columbus-thank-you',
            name: 'Columbus Thank You',
            component: ColumbusThankYou
        },
        {
            path: '/columbus-purch-form',
            name: 'Columbus Purchase Form',
            component: ColumbusPurchForm,
            props: { firstScreen: false }
        },
        {
            path: '/columbus-purch-form-purch',
            name: 'Columbus Purchase Form Purch',
            component: ColumbusPurchForm,
            props: { purchPicked: true, firstScreen: true }
        },
        {
            path: '/columbus-purch-form-refi',
            name: 'Columbus Purchase Form Refi',
            component: ColumbusPurchForm,
            props: { refiPicked: true }
        },
        {
            path: '/columbus-refi-form',
            name: 'Columbus Refi Form',
            component: ColumbusRefiForm,
            props: { cashOutSelected: false}
        },
        {
            path: '/columbus-refi-form-cash-out',
            name: 'Columbus Refi Form Cash Out',
            component: ColumbusRefiForm,
            props: { cashOutSelected: true}
        },
        {
            path: '/columbus-modules',
            name: 'Columbus Purchase Learning Modules',
            component: ColumbusModules
        },
///////////////////////////////////////////////////////
        {
            path: '/neo',
            name: 'NEO',
            component: NEOHome
        },
        {
            path: '/neo-thank-you',
            name: 'NEO Thank You',
            component: NEOThankYou
        },
        {
            path: '/neo-purch-form',
            name: 'NEO Purchase Form',
            component: NEOPurchForm,
            props: { firstScreen: false }
        },
        {
            path: '/neo-purch-form-purch',
            name: 'NEO Purchase Form Purch',
            component: NEOPurchForm,
            props: { purchPicked: true, firstScreen: true }
        },
        {
            path: '/neo-purch-form-refi',
            name: 'NEO Purchase Form Refi',
            component: NEOPurchForm,
            props: { refiPicked: true }
        },
        {
            path: '/neo-refi-form',
            name: 'NEO Refi Form',
            component: NEORefiForm,
            props: { cashOutSelected: false}
        },
        {
            path: '/neo-refi-form-cash-out',
            name: 'NEO Refi Form Cash Out',
            component: NEORefiForm,
            props: { cashOutSelected: true}
        },
        {
            path: '/neo-modules',
            name: 'NEO Purchase Learning Modules',
            component: NEOModules
        },
///////////////////////////////////////////////////////
        {
            path: '/medina',
            name: 'Medina',
            component: MedinaHome
        },
        {
            path: '/medina-thank-you',
            name: 'Medina Thank You',
            component: MedinaThankYou
        },
        {
            path: '/medina-real-estate-form',
            name: 'Medina Real Estate Form',
            component: MedinaRealEstateForm
        },
        {
            path: '/medina-modules',
            name: 'Medina Real Estate Learning Modules',
            component: MedinaModules
        },
        {
            path: '/medina-counties',
            name: 'Medina Counties',
            component: MedinaCountiesPage
        },
///////////////////////////////////////////////////////
        {
            path: '/dayton',
            name: 'Dayton',
            component: DaytonHome
        },
        {
            path: '/dayton-thank-you',
            name: 'Dayton Thank You',
            component: DaytonThankYou
        },
        {
            path: '/dayton-real-estate-form',
            name: 'Dayton Real Estate Form',
            component: DaytonRealEstateForm
        },
        {
            path: '/dayton-modules',
            name: 'Dayton Real Estate Learning Modules',
            component: DaytonModules
        },
        {
            path: '/dayton-counties',
            name: 'Dayton Counties',
            component: DaytonCountiesPage
        },
///////////////////////////////////////////////////////
        {
            path: '/md-dc',
            name: 'Maryland',
            component: MarylandHome
        },
        {
            path: '/md-dc-thank-you',
            name: 'Maryland Thank You',
            component: MarylandThankYou
        },
        {
            path: '/md-dc-real-estate-form',
            name: 'Maryland Real Estate Form',
            component: MarylandRealEstateForm
        },
        {
            path: '/md-dc-modules',
            name: 'Maryland Real Estate Learning Modules',
            component: MarylandModules
        },
        {
            path: '/md-dc-counties',
            name: 'Maryland Counties',
            component: MarylandCountiesPage
        },
///////////////////////////////////////////////////////
        {
            path: '/ohio-refi',
            name: 'Ohio Refi',
            component: OhioRefiHome
        },
        {
            path: '/ohio-refi-thank-you',
            name: 'Ohio Refi Thank You',
            component: OhioRefiThankYou
        },
        {
            path: '/ohio-refi-form',
            name: 'Ohio Refi Form',
            component: OhioRefiForm,
            props: { cashOutSelected: false }
        },
        {
            path: '/ohio-refi-form-cash-out',
            name: 'Ohio Refi Form Cash Out',
            component: OhioRefiForm,
            props: { cashOutSelected: true }
        },
        {
            path: '/ohio-modules',
            name: 'Ohio Refi Learning Modules',
            component: OhioRefiModules
        },
        {
            path: '/:pathMatch(.*)*',
            name: 'NotFound',
            component: NotFound
        }
    ],
    scrollBehavior(to, from, savedPosition) {
        if (to.hash) {
            return {
                el: to.hash,
                behavior: 'smooth',
            };
        } else if (savedPosition) {
            return savedPosition;
        } else {
            return { x: 0, y: 0 };
        }
    }
});

// App.use(router);
// App.mount('#app');

export default router;