<template>
  <div class="background-layer">
    <div class="banner-container" :style="{ backgroundImage: `url(${currentImage})`, opacity: currentOpacity }">
      <div class="flex-box">
        <div class="caption">
          <div class="row">
              <div class="col"><h1>{{ currentCaption.title }}</h1>
                  <h2>{{ currentCaption.subtitle }}</h2></div>
              <div class="col">
                  <img class="arrow" :src=grayArrow>
              </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex-box2">
        <div class="banner-box">
          <div class="box-content">
            <div class="header">
              <h9>Over 10+ Years of Home Loans <span>With Heart</span></h9><i class="fa-solid fa-heart heart"></i>
            </div>
            <div class="description">
              <p>For over a decade, Liberty has been proud to serve home loans with heart, dedicated to personalized service and supporting clients through every step of their homeownership journey.</p>
            </div>
            <div class="row buttons-area">
              <div class="col">
                <button class="box-button2">Find a Loan Officer</button>
              </div>
              <div class="col">
                <button class="box-button1">Free rate quote</button>
              </div>
            </div>
          </div>
        </div>
    </div>
    
  </div>
</template>

<script>
import image1 from '@/main-site/assets/lesbian-couple3-2.png';
import image2 from '@/main-site/assets/family-hero-image1.png';
import image3 from '@/main-site/assets/veterans-division-hero1.png';
import image4 from '@/main-site/assets/liberty-lends5.png';
import grayArrow from '@/main-site/assets/arrow-gray2.png';  


export default {
  props: {
    purchForm: {
      type: String,
      required: true
    },
    refiURL: {
      type: String,
      required: true
    },
    cashOutURL: {
      type: String,
      required: true
    },
  },
  data() {
    return {
      grayArrow: grayArrow,

      images: [
        {
          url: image1,
          title: "Lea & Michelle",
          subtitle: "This couple was able to find a home that was perfect for starting their small family. They were able to work with a unique situation and close on a home they loved!"
        },
        {
          url: image2,
          title: "John & Jane",
          subtitle: "Liberty helped us navigate the home buying process with ease and professionalism. We couldn't be happier."
        },
        {
          url: image4,
          title: "Lea & Michelle",
          subtitle: "This couple was able to find a home that was perfect for starting their small family. They were able to work with a unique situation and close on a home they loved!"
        },
        {
          url: image3,
          title: "Liberty Lends",
          subtitle: "Every month, Liberty and its employees extend a helping hand to local non-profits, dedicated to supporting the communities we operate in."
        },
      ],
      currentImageIndex: 0,
      currentOpacity: 1,
    };
  },
  computed: {
    currentImage() {
      return this.images[this.currentImageIndex].url;
    },
    currentCaption() {
      return {
        title: this.images[this.currentImageIndex].title,
        subtitle: this.images[this.currentImageIndex].subtitle,
      };
    },
  },
  mounted() {
    this.startSlideshow();
  },

  methods: {
    startSlideshow() {
      setInterval(() => {
        this.fadeOut();
        setTimeout(() => {
          this.currentImageIndex = (this.currentImageIndex + 1) % this.images.length;
          this.fadeIn();
        }, 800); // Change image after fade-out transition
      }, 4000); // Change image every 5 seconds
    },
    fadeOut() {
      this.currentOpacity = 0;
    },
    fadeIn() {
      this.currentOpacity = 1;
    },
  },
};
</script>

<style scoped>
@media (max-width: 617px) { 
  .arrow {
    max-width: 80px !important;
    margin-left: -6em !important;
    margin-top: -1.5em !important;
  }

  h9 {
      font-size: 35px !important;
  }


  h1 {
      font-size: 15px !important;
  }

  h2 {
      font-size: 11px !important;
      max-width: 130px !important;
  }

  .box-button1 {
    font-size: 17px !important;
    padding: .7em .5em !important;
  }

  .box-button2 {
    font-size: 17px !important;
    padding: .7em .5em !important;
  }

  .buttons-area {
    margin-top: .8em !important;
  }

  .banner-box {
    top: 15px !important;
    padding: 1em !important;
    height: 230px !important;
  }

  .banner-container {
    background-position: top 50px left -100px !important;
    height: 30em !important;
    background-size: 150% !important;
  }

  .background-layer {
    height: 30em !important;
  }
}

@media (max-width: 900px) {
  .caption {
      top: 2.5em !important;
      height: 140px;
  }
}

@media (min-width: 618px) and (max-width: 900px) {

  .banner-box {
    width: 80% !important;
    height: 220px !important;
    top: 116px !important;
  }

  h9 {
      font-size: 40px !important;
  }

  h1 {
      font-size: 17px !important;
  }

  h2 {
      font-size: 14px !important;
  }

  .flex-box2 {
    padding-top: 0em !important;
  }

  .banner-container {
    background-position: top 70px left -30px;
    height: 30.6em !important;
  }

  .background-layer {
    height: 33em !important;
    margin-bottom: -.3em !important;
  }
}

.header {
  max-width: 720px;
}

p {
  font-size: 19px;
  font-weight: 600;
  margin-top: 1.5em;
  max-width: 580px;
  color: rgb(143, 143, 143);
  line-height: 1.3em;
}

.arrow {
  max-width: 140px;
  margin-left: .5em;
  margin-top: -3em;
  margin-left: -2em;
}

.col {
  padding: 0;
}

.space {
  height: 15px;
  }
  
  /* .white {
  background: linear-gradient(0deg, #ffffff, #00000000);
  margin-top: -2.9em;
  margin-bottom: 1.5em;
  position: relative;
  z-index: 300;
  } */

.buttons-area {
  margin-top: 1.5em;
  gap: 10px;
  padding: 0em .5em;
  display: flex;
  flex-direction: row;
  max-width: 650px;
}

.box-button1 {
  width: 100%;
  background-color: #b31919;
  border: none;
  border-radius: 5px;
  padding: 1em;
  color: white;
  font-weight: 800;
  font-size: 20px;
  line-height: 1.2em;
  min-height: 69px;
  /* border: 2px solid rgba(116, 158, 217, 0.441); */
}

.box-button2 {
  width: 100%;
  background-color: #062850;
  border: none;
  border-radius: 5px;
  padding: 1em;
  color: white;
  font-weight: 800;
  font-size: 20px;
  box-sizing: border-box; 
  line-height: 1.2em;
  min-height: 69px;

}

span {
  font-family: 'Merriweather';
  color: #b31919;
}

h2 {
  font-family: 'Figtree';
  font-weight: 500;
  font-size: 15px;
  line-height: 1.4em;
  max-width: 250px;
  text-shadow: 0px 1px 3px #ffffff, 0 0 1em #ffffff, 
  0 0 0.9em #ffffff;
}

h1 {
  font-family: 'Inter';
  font-weight: 700;
  font-size: 19px;
  line-height: 1.1em;
}

h9 {
  font-family: 'Figtree';
  font-weight: 700;
  font-size: 65px;
  line-height: 1.1em;
  color: #062850;
}

  
i {
    color: #062850;
    margin-left: .2em;
}

.banner-container {
  width: 100%;
  height: 45em; 
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-size: cover; 
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden; 
  transition: opacity 1s ease-in-out;
  position: absolute;
  opacity: 0.5;
}

.caption {
  position: absolute;
  top: 100px;
  right: 0;
  width: 400px;
  padding: 0em .7em !important;
}

.box-content {
  text-align: left;
}

.banner-box {
  background: linear-gradient(90deg, #fbfffe, #fbfffe, #fbfffe, #fbfffe, #fbfffef1, #fbfffec2,  #ffffff00);
  width: 800px;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 100;

}

.flex-box2 {
  width: 100%;
  max-width: 1350px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 100%;;
}

.flex-box {
  width: 100%;
  max-width: 1350px;
  padding-top: 8rem;
  padding-bottom: 1rem;
  text-align: left;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.background-layer {
  width: 100%;
  height: 45em; 
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 0em 0.7em;
  margin-bottom: -.1em;
  overflow: hidden; 
  margin-top: 2em;
}
</style>
  