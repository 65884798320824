<template>
    <CountiesNav
    realEstateURL="/md-dc"
    realEstateForm="/md-dc-real-estate-form"
    realEstateCounties="/md-dc-counties"/>
    <CountiesContent
    :buttonPath="buttonPath"
    :countiesList="countiesList"/>

</template>

<script>
import CountiesNav from '@/landing-pages/components/navigations/CountiesNav.vue'
import CountiesContent from '@/landing-pages/components/counties/CountiesContent.vue'
import columbia from '@/landing-pages/assets/colombia-maryland.jpeg'
import bethesda from '@/landing-pages/assets/downtown-bethesda-maryland.jpeg'
import laurel from '@/landing-pages/assets/laurel-MD.jpeg'
import frederick from '@/landing-pages/assets/frederick-MD.jpg'
import washington from '@/landing-pages/assets/adams-morgan-dc.jpg'
import baltimore from '@/landing-pages/assets/Baltimore.png'

export default {
    components: {
        CountiesNav,
        CountiesContent
    },

    data (){
        return {
            columbia: columbia,
            bethesda: bethesda,
            laurel: laurel,
            frederick: frederick,
            washington: washington,
            baltimore: baltimore,

            buttonPath: '/md-dc-real-estate-form',

            countiesList: [
                {
                    location: 'North Bethesda',
                    src: bethesda,
                    idName: 'bethesda',
                    subtitle: 'Perfect for those who want to live in an area with an urban feel, coffee shops, and public spaces.',
                    affordability: 3.5,
                    affordablityText: "Bethesda is known for being an affluent, high-income area with quality life and amenities justifying the cost.",
                    parksRec: 4.5,
                    parksRecText: "Bethesda is a haven for outdoor enthusiasts, boasting an abundance of beautiful parks, trails, and recreational facilities for all ages.",
                    schools: 5,
                    schoolsText: "Renowned for its top-tier schools, Bethesda is an ideal place for educational excellence, providing outstanding opportunities for academic growth.",
                    shoppingNightlife: 4.8,
                    shoppingNightlifeText: "The vibrant shopping scene and dynamic nightlife in Bethesda cater to a variety of tastes, making it a lively and exciting place to live and socialize.",
                    summaryLine1: "Bethesda, Maryland, nestled just northwest of Washington, D.C., is a vibrant and affluent community. This picturesque suburb is a blend of urban sophistication and suburban comfort, making it one of the most sought-after areas in the region. With its bustling downtown area, Bethesda offers a rich array of amenities, including upscale shopping centers, a wide variety of restaurants, and an active cultural scene with theaters and art galleries. The area is also home to the renowned National Institutes of Health and the Walter Reed National Military Medical Center, adding to its prestige and community importance.",
                    summaryLine2: "Beyond its urban appeal, Bethesda is a haven for outdoor enthusiasts and families. The community boasts numerous parks, playgrounds, and trails, including the famous Capital Crescent Trail, offering scenic routes for biking, walking, and jogging. Education is a top priority here, with Bethesda hosting some of the nation's highest-rated public and private schools, making it an ideal location for families prioritizing academic excellence. The combination of its proximity to the nation's capital, its array of recreational and cultural activities, and its commitment to community well-being makes Bethesda not just a place to live, but a place to thrive."
                },
                {
                    location: 'Laurel, MD',
                    src: laurel,
                    idName: 'laurel',
                    subtitle: 'A charming suburban haven with easy access to city life.',
                    affordability: 4.5,
                    affordablityText: "Laurel is generally considered more affordable than some of its neighboring cities, offering a range of housing options suitable for various budgets.",
                    parksRec: 4.5,
                    parksRecText: "The city is known for its ample green spaces, including parks and nature reserves like the Patuxent Research Refuge, providing excellent recreational opportunities.",
                    schools: 4,
                    schoolsText: "Schools in Laurel are generally well-regarded, offering good educational opportunities, though they might not rank as high as those in some more affluent neighboring areas.",
                    shoppingNightlife: 3.5,
                    shoppingNightlifeText: "The city offers a variety of shopping options and some nightlife, particularly around its historic Main Street area, though it may not be as vibrant or diverse as larger cities.",
                    summaryLine1: "Laurel, Maryland, positioned conveniently between Washington, D.C., and Baltimore, offers a unique living experience that combines suburban tranquility with easy access to urban amenities. This charming city is known for its rich history, diverse community, and an abundance of green spaces, including parks and nature reserves. Laurel's Main Street exudes a quaint, small-town feel with its array of local shops, restaurants, and historic buildings, making it a delightful destination for residents and visitors alike.",
                    summaryLine2: "The city is also home to the beautiful Patuxent Research Refuge, a haven for wildlife enthusiasts and outdoor adventurers.In Laurel, residents enjoy a sense of community and a slower pace of life, while still benefiting from the conveniences of city living. The housing market in Laurel is diverse, offering options for various preferences and budgets, from historic homes to modern apartments. The city's strategic location means that residents have easy access to major employment centers, top-notch educational institutions, and cultural attractions in both Baltimore and Washington, D.C. With its blend of affordability, community spirit, and proximity to major urban centers, Laurel represents an ideal choice for those seeking a balanced and fulfilling lifestyle."
                },
                {
                    location: 'Columbia, MD',
                    src: columbia,
                    idName: 'columbia',
                    subtitle: 'A top-voted, planned urban community with diverse amenities.',
                    affordability: 3.5,
                    affordablityText: "Columbia is known for its planned community and quality of life, which can come with a higher price tag compared to some other areas, but it offers a range of housing options.",
                    parksRec: 5,
                    parksRecText: "The city excels in offering extensive parks, lakes, and recreational facilities, aligning with its design as a planned community focused on quality of life.",
                    schools: 4.4,
                    schoolsText: "Columbia is known for having good quality schools, both public and private, making it a desirable location for families.",
                    shoppingNightlife: 4.5,
                    shoppingNightlifeText: "The city offers a variety of shopping centers, restaurants, and entertainment options. While it might not match the nightlife of a major city, it provides a vibrant environment for its size.",
                    summaryLine1: "Columbia, Maryland, renowned as a masterfully planned community, epitomizes a harmonious blend of residential charm and modern convenience. This unique city, nestled in the heart of Howard County, is celebrated for its innovative design that emphasizes community living and environmental consciousness. Columbia's layout is divided into self-contained villages, each featuring a mix of residential areas, schools, shopping centers, and recreational facilities, fostering a strong sense of community among residents. The city is dotted with picturesque parks, lakes, and walking trails, making it an idyllic setting for outdoor enthusiasts and families alike.",
                    summaryLine2: "Culturally diverse and vibrant, Columbia offers a rich array of amenities and activities. The city boasts high-quality educational institutions, making it a top choice for families. Shopping enthusiasts enjoy the Mall in Columbia and various other retail destinations, while the Merriweather Post Pavilion, a renowned outdoor concert venue, hosts a range of performances, attracting music lovers from all over. Columbia's strategic location between Baltimore and Washington, D.C., provides residents with easy access to the extensive cultural, professional, and social opportunities of these major urban centers. This blend of peaceful suburban living with the perks of city life makes Columbia a highly desirable place to call home."
                },
                {
                    location: 'Frederick, MD',
                    src: frederick,
                    idName: 'frederick',
                    subtitle: 'Historic charm meets vibrant culture in this scenic mountain gateway.',
                    affordability: 4.5,
                    affordablityText: "Frederick is generally considered affordable, especially when compared to the higher cost of living in nearby metropolitan areas like Washington, D.C., and Baltimore.",
                    parksRec: 4,
                    parksRecText: "The city is known for its ample public parks, recreational facilities, and outdoor activities, including access to scenic trails and historic sites.",
                    schools: 4.7,
                    schoolsText: "Schools in Frederick are well-regarded, offering quality education with several highly rated public and private schools.",
                    shoppingNightlife: 3,
                    shoppingNightlifeText: "Frederick provides a variety of shopping options, particularly in its charming historic downtown area. The nightlife is lively with a selection of restaurants, bars, and entertainment, though it may not be as extensive as larger urban centers.",
                    summaryLine1: "Frederick, Maryland, nestled in the scenic foothills of the Catoctin Mountains, is a city where historical charm seamlessly blends with contemporary living. Known for its rich Civil War history, Frederick's downtown streets are lined with beautifully preserved 19th-century buildings, housing an eclectic mix of shops, art galleries, and restaurants. This vibrant historic district, coupled with the city's commitment to preserving its past, makes Frederick a captivating destination for history buffs and culture enthusiasts alike. The city's thriving arts scene, exemplified by its numerous theaters and galleries, adds to its dynamic character.",
                    summaryLine2: "Beyond its historical and cultural appeal, Frederick is also a hub for outdoor activities and nature exploration. The city is a gateway to adventure, with easy access to hiking trails in the Catoctin Mountains and along the Potomac River, as well as picturesque parks like Baker Park in the heart of the city. Frederick's community-focused atmosphere is further enriched by its diverse array of local events, including festivals, farmers' markets, and live music performances. The city's excellent schools, family-friendly neighborhoods, and growing economy make it an ideal place for both individuals and families seeking a balanced lifestyle that combines small-town warmth with the conveniences of a larger city."
                },
                {
                    location: 'Washington, DC',
                    src: washington,
                    idName: 'washington',
                    subtitle: "The nation's historical capital, brimming with iconic landmarks and vibrant urban energy.",
                    affordability: 2.5,
                    affordablityText: "Washington, D.C., is known for its high cost of living, with housing prices and general expenses typically above the national average.",
                    parksRec: 5,
                    parksRecText: "The city excels in public parks and recreational facilities, including iconic spaces like the National Mall, Rock Creek Park, and numerous community parks and recreation centers.",
                    schools: 3.5,
                    schoolsText: "The quality of public schools in D.C. varies, with some excellent schools (especially in more affluent neighborhoods) and others facing challenges. There are also numerous prestigious private schools.",
                    shoppingNightlife: 5,
                    shoppingNightlifeText: "The city offers a vibrant shopping scene, from high-end stores to unique local boutiques, and an active nightlife with a wide range of bars, clubs, and restaurants catering to diverse tastes.",
                    summaryLine1: "Washington, D.C., the capital of the United States, is a city steeped in history and political significance. Known for its iconic landmarks such as the White House, the Capitol Building, and the Lincoln Memorial, D.C. is more than just the political epicenter of the nation; it's a vibrant city with a rich cultural tapestry. The city's numerous museums, most of which are part of the Smithsonian Institution and free to the public, offer a wealth of knowledge and entertainment. The National Mall, a large open park in downtown Washington, D.C., is a hub of activity, hosting everything from presidential inaugurations to cultural festivals.Beyond its historical and political landmarks, Washington, D.C., is a diverse and dynamic city with a strong sense of community.",
                    summaryLine2: "Each neighborhood in D.C. has its own unique character, from the historic charm of Georgetown to the trendy and bustling streets of Shaw and the U Street Corridor. The city's culinary scene is as diverse as its population, featuring everything from Michelin-starred restaurants to local food trucks serving international cuisines. Additionally, Washington, D.C., is known for its cherry blossoms in spring, particularly around the Tidal Basin, adding to the city's scenic beauty. With its blend of history, culture, and modern urban living, Washington, D.C., offers a rich and fulfilling experience for residents and visitors alike."
                },
                {
                    location: 'Baltimore',
                    src: baltimore,
                    idName: 'baltimore',
                    subtitle: 'A dynamic urban tapestry blending rich history with modern vitality.',
                    affordability: 4,
                    affordablityText: "Baltimore is generally more affordable compared to other major cities in the region, offering a variety of housing options at different price points.",
                    parksRec: 4.8,
                    parksRecText: "The city boasts numerous public parks, including the large Patterson Park and Druid Hill Park, along with waterfront areas and recreational facilities.",
                    schools: 4.3,
                    schoolsText: "The quality of public schools in Baltimore varies, with some excellent institutions and others facing challenges. There are also several highly regarded private and charter schools.",
                    shoppingNightlife: 4.7,
                    shoppingNightlifeText: "Baltimore offers a vibrant shopping scene, particularly in areas like Harbor East and Fells Point, and an active nightlife with a variety of bars, clubs, and live music venues.",
                    summaryLine1: "Baltimore, Maryland, affectionately known as 'Charm City,' offers a unique blend of historic allure and modern vibrancy. This bustling port city, famous for its beautiful Inner Harbor, is rich in American history and cultural heritage. Baltimore's distinct neighborhoods, each with its own personality, range from the cobblestone streets of Fells Point to the artsy and eclectic vibe of Hampden. The city is a treasure trove of attractions, including the National Aquarium, historic ships, and numerous museums.",
                    summaryLine2: "Baltimore's culinary scene is a highlight, boasting world-renowned crab houses and an array of dining options that reflect the city's diverse cultural makeup.Baltimore also thrives as a center for arts and education, home to internationally acclaimed institutions like the Johns Hopkins University and the Maryland Institute College of Art. The city's commitment to the arts is evident in its lively arts districts and events like Artscape, America's largest free arts festival. Despite facing urban challenges, Baltimore's resilient spirit shines through its community initiatives and redevelopment projects, aiming to uplift and revitalize the city. With its mix of historic charm, cultural richness, and community-driven growth, Baltimore offers a dynamic urban experience rooted in tradition yet boldly facing the future."
                }
            ],
        }
    },

}

</script>

<style scoped>
@media (max-width: 786px)  {
    .paragraph {
        margin: 2em 0 3.5em 0 !important;
    }

    .content {
        margin-top: 3em !important;
    }

    .image-content {
        flex-direction: column !important;
    }

    .block-image {
        height: 200px !important;
    }

    h2 {
        font-size: 42px !important;
        line-height: 1em;
    }

    h4 {
        font-size: 42px !important;
    }

    h3 {
        font-size: 42px !important;
    }

    p {
        font-size: 17px !important;
        line-height: 1.3em;
        width: 100% !important;
    }

    p1 {
        line-height: 1.2em !important;
    }

    p4 { 
        font-size: 17px !important;
    }

    p6 { 
        font-size: 17px !important;
    }
}

@media (max-width: 1000px)  {
    .block-image {
        max-height: 250px;
    }
}

button {
    color: white;
    background-color: #b31919;
    border: none;
    border-radius: 35px;
    font-weight: 800;
    padding: .5em 1em;
}

.paragraph-container {
    max-width: 1350px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1.5em .7em;
}

.paragraph {
    background-color: #efefef;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 2em 0 5em 0;
}

.inline-summary {
    margin-top: 1em;
}

.inline {
    text-align: left;
}

.summary {
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: left;
}

.image-content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    max-width: 1350px;
    padding: 0 .7em;
    gap: 20px;
}

.block-image {
    height: 350px;
}

.content {
    margin-top: 5em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.location-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

ul{
    margin: 0;
    padding: 0;
    width: 100%;
}

.bg-image {
    background: url("@/landing-pages/assets/summit-county-ohio.png");
    box-shadow: inset 0 0 0 1100px #00000076;
    position: relative;
    background-position: 0px 800px;
    background-size: cover no-repeat;
    height: 24em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-attachment: fixed;
}

.blocks {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    max-width: 1350px;
    padding: 4em .7em 0 .7em;
}

p6 {
    text-align: left;
    margin-bottom: 1em;
    font-size: 17px;

}

p4 {
    font-weight: 800;
    line-height: 1.2em;
    font-size: 17px;
}

h5 {
    color: rgb(185, 28, 14);
    font-size: 17px;
    font-weight: 800;
    line-height: 1.5rem;
    display: inline-block;
    margin-right: .3em;
}

h6 {
    color: #062850;
    font-size: 17px;
    font-weight: 800;
    line-height: 1.5rem;
    display: inline-block;
}

p1 {
    color: rgb(173, 173, 173);
    width: 100%;
    font-size: 19px;
    line-height: 1.3;
    font-weight: 800;
}

h1 {
    color: rgb(6, 40, 80);
    width: 100%;
    font-size: 26px;
    line-height: 1.3;
    font-weight: 800;
}

h2 {
    font-weight: 500;
    font-size: 58px;
    line-height: 1em;
    font-family: 'Inter';
    margin: 0;
    color: white;
    text-align: left;
}

h4 {
    font-family: 'Playfair-Display';
    font-size: 58px;
    font-weight: 600;
    color: #b31919;
    margin: 0;
    text-align: left;
    display: inline-block;
    margin-right: .3em;
    /* text-shadow: 0px 1px 3px rgb(0, 0, 0); */
}

h3 {
    font-family: 'Playfair-Display';
    font-size: 58px;
    font-weight: 600;
    color: #062850;
    margin: 0;
    text-align: left;
    display: inline-block;
}

p {
    font-weight: 500;
    font-size: 24px;
    line-height: 1.3em;
    font-family: 'Inter';
    color: white;
    width: 60%;
    margin-top: 1em;
    text-align: left;
}

</style>