<template>
    <div class="banner-container">
        <div class="flex-box">
            <div class="search-row">
                <div class="filters">
                    <SearchFilters/>
                </div>
                <div class="results">
                    <div class="result-bar">
                        <p>Search results</p>
                    </div>  
                    <div class="result">
                        <div class="headshot">
                            <img class="headshot-circle" :src=ChrisDoyle>
                        </div>
                        <div class="content">
                            <p1>First LastName</p1>
                            <p2>Mortgage Loan Officer</p2>

                        </div>
                    </div>               
                </div>
            </div>
            
        </div>
    </div>
</template>

<script>
import SearchFilters from '@/main-site/ui-elements/SearchFilters.vue';
import ChrisDoyle from '@/main-site/assets/chris_doyle-copy.jpg'

export default {
    components: {
        SearchFilters,
    },

    data(){
        return {
            ChrisDoyle: ChrisDoyle,
        }
    }
}
</script>

<style scoped>
p {
    margin: 0;
}

.content {
    width: 80%;
    display: flex;
    flex-direction: column;
}

img {
    overflow: hidden;
    width: 150px;
}

.headshot-circle {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    overflow: hidden;
    position: relative;
    object-fit: cover;
}

.headshot {
    width: 20%;
}

.result {
    width: 100%;
    display: flex;
    flex-direction: row;
    padding: 1em;
    gap: 50px;
}

.results {
    width: 70%;
    border: 1px solid #e8e8e8;
    border-radius: 8px;
}

.filters {
    width: 30%;
}

.search-row {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 30px;
    margin-top: 2em;
}

.result-bar {
    background-color: #f2f2f2;
    width: 100%;
    display: flex;
    justify-content: center;
    padding: .7em;
    border-radius: 8px 8px 0px 0px;
}

span {
    color: #b31919;
}

h {
    font-size: 60px;
    font-weight: 700;
    color: #062850;
}

.banner-container {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding: 0em 0.7em;
    margin-top: 0em;
}

.flex-box {
    width: 100%;
    max-width: 1350px;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
</style>