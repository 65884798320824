<template>
    <div class="fix-height">

        <div v-if="!finishBtn">
            <span><h1 :style="getCurrentQuestion.question.length >= 55 ? 'font-size: 18px;' : ''">{{ getCurrentQuestion.question }}</h1></span>
            
            <div class="gap">
            <div class="options-area">
            <label class="options" v-for="(option, index) in getCurrentQuestion.options" :key="index"
            >
            <div v-if="showAnswer==false">
                <input class="answer-bubble" 
                type="radio"
                :name="getCurrentQuestion.index"
                :value="index"
                v-model="getCurrentQuestion.selected"
                @change="SetAnswer"
                :class="(getCurrentQuestion.selected == index) ? 'selected' : ''"
                >
                <span class="answer-bubble-text" :class="getCurrentQuestion.selected == index ? 'letter-selected' : ''">{{ option.letter }}</span>
            </div> 

            
            <div v-if="showAnswer">
                <input class="answer-bubble" 
                type="radio"
                :name="getCurrentQuestion.index"
                :value="index"
                v-model="getCurrentQuestion.selected"
                @change="SetAnswer"
                :class="(index == getCurrentQuestion.answer) ? 'correct': 'incorrect'"
                >
                <span class="answer-bubble-text" :style="(index == getCurrentQuestion.answer) ? '' : 'display: none'">{{ option.letter }}</span>
                <span class="answer-bubble-text" :style="(index == getCurrentQuestion.answer) ? 'display: none' : ''"><i class="fa-solid fa-xmark"></i></span>
            </div>

                    <span class="options-text" :style="getCurrentQuestion.options.length >= 40 ? 'font-size: 15px; line-height: 1.35em;' : ''">{{ option.text }}</span>

            </label>
            </div>
            </div>

            <div class="buttons-area">
                <p class="error" v-if="showError"  :style="showAnswer ? 'display: none' : ''">Please make a selection!</p>
                <p v-if="!showNextBtn">{{ getCurrentQuestion.index + 1 }}/{{ questions.length }}</p>
                <p v-if="showNextBtn">Score: {{ score }}/{{ questions.length }}</p>
                <span v-if="showCheckButton" class="button" @click="checkAnswer">Check</span>
                <span v-if="showNextBtn" class="button" @click="nextBtn">Next</span>
                <span v-if="finishBtn" class="button" @click="declareFinish">Next</span>
            </div>
        </div>

        <div class="quiz-complete" v-if="finishBtn">
                <span><img class="logo" :src="navLogoWhite" alt="liberty.logo"></span>
                    <h2>Quiz complete!</h2>
                    <h3>Final score: <b>{{ score }}/{{ questions.length }}</b></h3>
                    <p1>How did you do? Reach out for reliable homebuying help!</p1>
                <router-link :to="{ path: '/', hash: '#lead-form' }"><button class="done-button">Get homebuying help</button></router-link>
        </div>
    </div>
</template>

<script setup>
import { ref, computed } from 'vue'
import navLogoWhite from '@/landing-pages/assets/lhm_hori_logo_white-transparent.png';
import navLogo from '@/landing-pages/assets/lhm_hori_logo_bluebell_redtype.svg';

const questions = ref([
    {
        question: 'self-employed scenarios',
        options: [ 
            {letter: 'A', text: 'No. Joe must be put on the loan if his income will be used.'}, 
            {letter: 'B', text: "Yes. It's possible to seperate income and credit scores from individual borrowers in order to qualify for a certain rate."}, 
        ],
        answer: 0,
        selected: null
    },
    {
        question: "Jennifer gets divorced and her ex-husband is given the responsibility of making all of the payments on their debt via the divorce decree. Her ex-husband, Jim, ends up going late on a few of the mortgage payments. Will those late payments be held against Jennifer when SHE applies for a mortgage loan?",
        options: [ 
            {letter: 'A', text: 'Yes, it can be held against her for 3 years.'}, 
            {letter: 'B', text: 'No, they will not be held against her.'}, 
        ],
        answer: 1,
        selected: null
    },
    {
        question: 'In addition, If Jim goes late on the bills, will Jennifer’s credit SCORE be adversely affected by those late payments if the divorce decree specified that she was not responsible for the payments?',
        options: [ 
            {letter: 'A', text: "No, Jim's payment history will NOT affect Jennifer."}, 
            {letter: 'B', text: 'Yes, her score WILL be adversely affected and will drop significantly.'}, 
        ],
        answer: 1,
        selected: null
    },
    
])

const finishBtn = ref(false)
const currentQuestion = ref(0)
const showAnswer = ref(false)
const showCheckButton = ref(true)
const showNextBtn = ref(false)
const showError = ref(false)

const score = computed(() => {
    let value = 0
    questions.value.forEach(q => {
        if(q.selected == q.answer) {
            value++
        }
    })
    return value
})

const getCurrentQuestion = computed(() => {
    let question = questions.value[currentQuestion.value]
    question.index = currentQuestion.value
    return question
})

const nextBtn = () => {
    if ((currentQuestion.value < questions.value.length - 1) && (currentQuestion.value <= questions.value.length -1)){
        currentQuestion.value++
    }
    showAnswer.value = false
    showCheckButton.value = true
    showNextBtn.value = false
}

const checkAnswer = () => {
    let question = questions.value[currentQuestion.value]
    if(question.selected == null) {
        showError.value = true;
    } else {
        showNextBtn.value = true;
        showCheckButton.value = false;
        showAnswer.value = true;
        showError.value = false;
    };

    if (currentQuestion.value === questions.value.length -1) {
        return finishBtn.value = true
    }
}

const declareFinish = () => {
    finishBtn.value = true
}
    
</script>

<style scoped>
@media (max-width: 768px) {

.options{
    max-width: 100% !important;
    align-items: start !important;
}

.options-text {
    width: 81% !important;
    min-width: 228px !important;
}

.fix-height {
    padding: 1em 0em !important;
}

h6 {
    font-size: 18px !important;
}

.buttons-area {
    margin-top: 1em !important;
}

}

.options-area {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 23px;
}

h6 {
    font-size: 22px;
    color: #6e9bce;
    font-weight: 800;
    margin-bottom: 1em;
}

.quiz-complete {
    display: flex;
    flex-direction: column;
}

.logo {
    width: 100px;
    margin-bottom: 0em;
}

i{
    color: white;
}

.correct{
    background-color: rgb(17, 162, 56) !important;
    color: #ffffff !important;
}

.incorrect{
    background-color: #b31919 !important;
}

.options{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.gap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.options-text{
    top: 2em;
    color: black;
    font-weight: 600;
    text-align: left;
    font-size: 17px;
    max-width: 500px;
    line-height: 1.4em;
}

input.selected{
    background-color: #b31919;
}

.letter-selected {
    color: white !important;
}

.answer-bubble-text {
  position: relative;
  left: -25px;
  top: -13px;
  font-size: 17px;
  font-weight: 700;
  color: white;
}

.answer-bubble {
  width: 38px;
  height: 38px;
  border-radius: 50%;
  background-color: #062850;
  -webkit-appearance: none; /*to disable the default appearance of radio button*/
  -moz-appearance: none;
}

p {
    margin: 0;
    padding: 0;
    color: #b31919;
    font-weight: 800;
}

.buttons-area {
    margin-top: 2em;
    display: flex;
    gap: 5px;
    justify-content: center;
    position: static;
    align-items: center;
    display: flex;
    flex-direction: column;
}

.button {
    color: white;
    background-color: #b31919;
    border-radius: 25px;
    padding: .5em 1.2em;
    font-weight: 800;
}

.done-button {
    color: white;
    background-color: #062850;
    border-radius: 25px;
    padding: .7em 1.2em;
    font-weight: 600;
    border: none;
    font-size: 18px;
    margin-top: 1.5em;
}

.fix-height {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 800px;
    height: 550px;
}

h1{
    color: black;
    font-weight: 700;
    font-size: 21px;
    line-height: 1.4em;
    margin-bottom: 2.5em;
}

h2{
    color: black;
    font-weight: 800;
    font-size: 28px;
    line-height: 1em;
    margin-bottom: .3em;
}

h3{
    color: black;
    font-weight: 600;
    font-size: 23px;
    line-height: 1em;
    margin-bottom: 1em;
}

p1 {
    color: black;
    font-weight: 500;
    font-size: 17px;
    line-height: 1em;
}

b {
    font-weight: 900;
    color: #062850;
}


.flex-class{ 
    display:flex;
    flex-direction: column;
    color: white;
    justify-content: center;
    align-items: center;
}

.error {
    color: #b31919;

}
</style>