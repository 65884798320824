<template>
    <Counties 
    :areaDescription="areaDescription"
    :pageName="pageName" 
    :pageDescription="pageDescription" 
    :counties="counties"
    :leadForm="leadForm"/>
</template>

<script>
import summitCounty from '@/landing-pages/assets/summit-county-ohio.png'
import cuyahogaCounty from '@/landing-pages/assets/cuyahoga-falls-fridays.jpeg'
import medinaCounty from '@/landing-pages/assets/downtown-medina.jpeg'
import Counties from '@/landing-pages/components/counties/Counties.vue'

export default {
    components: {
        Counties,
    },

    data (){
        return {
            summitCounty: summitCounty,
            cuyahogaCounty: cuyahogaCounty,
            medinaCounty: medinaCounty,

            areaDescription: "greater Cleveland",
            pageName: '/medina-counties',
            leadForm: '/medina-real-estate-form',
            counties: [
                {
                    location: 'Medina County',
                    src: medinaCounty,
                    id: '#medinaCounty',
                    subtitle: 'Find your perfect retreat and peaceful living with easy access to urban amenities.'
                },
                {
                    location: 'Cuyahoga Falls',
                    src: cuyahogaCounty,
                    id: '#cuyahogaFalls',
                    subtitle: 'Explore the charm of Cuyahoga Falls, with its welcoming local life.'
                },
                {
                    location: 'Summit County',
                    src: summitCounty,
                    id: '#summitCounty',
                    subtitle: 'Discover where scenic beauty meets vibrant community living.'
                }
            ]
        }
    },
}

</script>

<style scoped>
@media screen and (max-width: 460px) {
    .block-image {
        height: 110px !important;
    }
}

@media screen and (max-width: 768px) {
    h2 {
    font-size: 38px !important;
    }

    h3 {
        font-size: 38px !important;
    }

    p {
        font-size: 19px !important;
        min-width: 100% !important;
    }

    .block-image {
        max-height: 200px;
    }

    p2 {
        font-size: 16px !important;
        line-height: 1.3em;
        font-weight: 500 !important;
    }

    h4 {
        font-size: 18px !important;
    }
}

@media screen and (max-width: 1000px) {
    p {
        width: 80% !important;
    }

    .county-blocks {
        grid-template-columns: 1fr 1fr !important;
    }

    .block-image {
        max-height: 230px;
        border-radius: 5px !important;
    }

}

.menu-item {
    text-decoration: none !important;
}

h4{
    text-align: left;
    color: #b31919;
    font-weight: 800;
    font-size: 18px;
    margin-top: 1em;
}

p2 {
    font-size: 17px;
    font-weight: 600;
    text-align: left;
    line-height: 1.3em;
    text-decoration: none;
    color: black;
}

.county-blocks {
    display: grid;
    padding: 0 .7em;
    width: 100%;
    max-width: 1350px;
    justify-content: center;
    flex: 0 0 100%;
    flex: 1;
    grid-template-columns: 1fr 1fr 1fr;
    gap: .7em;
}

.cards {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.block-image {
    width: 100%;
    height: 280px;
    border-radius: 10px;
}

.counties-background {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    background-size: cover;
    justify-content: flex-start;
    /* background-image: url('@/assets/liberty-stars-red-stripes.svg'); */
}

h2 {
    font-weight: 500;
    font-size: 58px;
    line-height: 1em;
    font-family: 'Inter';
    margin: 0;
    color: #053873;
}

h3 {
    font-family: 'Playfair-Display';
    font-size: 58px;
    font-weight: 600;
    color: #b31919;
    margin: 0;
}

p {
    font-weight: 500;
    font-size: 24px;
    line-height: 1.3em;
    font-family: 'Inter';
    color: #7c7c80;
    width: 60%;
    margin-top: 1em;
}
</style>