<template>
    <MainNav/>
    <div class="banner-container2">
        <div class="flex-box">
            <h>Find a <span>Loan Officer</span></h>
            <SearchResults/>
            skillbridge
        </div>
    </div>
    
</template>

<script>
import MainNav from '@/main-site/components/MainNav.vue';
import SearchResults from '@/main-site/ui-elements/SearchResults.vue';

export default {
    components: {
        MainNav,
        SearchResults
    },

    data(){
        return {
        }
    }
}
</script>

<style scoped>

span {
    color: #b31919;
}

h {
    font-size: 65px;
    font-weight: 700;
    color: #062850;
}

.banner-container2 {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding: 0em 0.7em;
    margin-top: 9em;
}

.flex-box {
    width: 100%;
    max-width: 1350px;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
</style>