<template>
  <nav class="navbar" :class="{ 'mobile-menu-open': isMobileMenuOpen }">
    <div class="container">
      <div class="logo">
        <a href="/" class="menu-item" 
        ><img :src="navLogo" alt="liberty.logo"></a>
      </div>

      <div class="menu-toggle" @click="toggleMobileMenu">
        <a href="/apply" class="menu-item"><button>Apply Now</button></a>
      </div>

      <div class="menu-items" v-if="isMobileMenuOpen == false">
        <a href="/#reviews" class="menu-item">About Us</a>

        <a href="/apply" class="menu-item"><button>Apply Now</button></a>

      </div>
    </div>
  </nav>
  
</template>

<script>
import navLogo from '@/landing-pages/assets/lhm_hori_logo_bluebell_redtype.svg';
import navLogoWhite from '@/landing-pages/assets/lhm_hori_logo_white-transparent.png';


export default {
  name: 'Navigation',
  data() {
    return {
      isMobileMenuOpen: false,
      navLogo: navLogo,
      navLogoWhite: navLogoWhite
    };
  },
  methods: {
    toggleMobileMenu() {
      this.isMobileMenuOpen = !this.isMobileMenuOpen;
    },

    closeMobileMenu(){
      this.isMobileMenuOpen = false
    }
  }
}
</script>

<style scoped>
@media screen and (max-width: 1000px) {
  button {
    margin-right: -2em !important;
    font-size: 14px !important;
    padding: .5em .8em !important;
  }
}

button {
  background-color: #b31919;
  color: white;
  border: none;
  border-radius: 35px;
  font-weight: 700;
  padding: .5em 1.2em;
  font-family: 'Merriweather';
  font-size: 15px;
}

i{
  color: black;
  margin-top:50%;
}

.navbar {
  background-color: #fbfffeeb;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  position: fixed; 
  top: 0; 
  left: 0;
  right: 0;
  z-index: 1000;
  max-height: 100px;
}

.container {
  max-width: 1350px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo img {
  width: 200px;
}

.menu-toggle {
  display: none;
}

.menu-items {
  display: flex;
  align-items: center;
  gap: 20px;
}

.menu-item {
  margin-right: 20px;
  font-weight: 700;
  color: black;
  cursor: pointer;
  text-decoration: none;
}

.menu-item-mobile {
  margin-right: 20px;
  font-weight: 700;
  color: white;
  cursor: pointer;
  text-decoration: none;
}

@media screen and (max-width: 850px) {
  .logo img {
  width: 180px;
}

  .container {
    padding-left:5px;
  }

  .menu-items {
    display: none;
  }

  .menu-toggle {
    display: block;
    position: absolute;
    right: 20px;
    z-index: 1000;
  }

  .mobile-menu-open .menu-items {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 70px;
    left: 0;
    right: 0;
    z-index: 999;
    background-color: black;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .menu-toggle i {
    font-size: 24px;
  }

}
</style> 
