<template>
    <div class="info-background">
        <div class="info-blocks">
            <div class="col"><img :src="collage" alt="liberty.logo" class="collage"></div>
            <div class="col text">
                <h1>What does home & our customers mean to us?</h1>
                <h2>Everything.</h2>
                <p>At Liberty, we know home means everything to you, too. That's why each loan officer works closely with a dedicated underwriter, providing you with the smoothest journey. With everything managed in-house, we negotiate the best terms for you for a superior mortgage experience.</p>
                <div class="buttons-area">
                    <button class="banner-button">Home Purchase</button>
                    <button class="banner-button">Refinance</button>
                    <button class="banner-button">Cash Out</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import collage from '@/main-site/assets/3-photos-collage.png'


export default {
    props: {
        purchForm: {
            type: String,
            required: true
        },
        refiURL: {
            type: String,
            required: true
        },
        cashOutURL: {
            type: String,
            required: true
        }
    },
    
    data (){
        return {
            collage: collage,
        }
    },

}

</script>

<style scoped>
@media screen and (max-width: 730px) {
    .info-blocks {
        display: flex;
        flex-direction: column;
    }

    .info-background {
        margin: 3em 0em 7em 0em !important;
    }

    .collage {
        max-height: 220px !important;
    }
}

.banner-button {
    background-color: #b31919;
    border-radius: 10px;
    border: none;
    color: white;
    padding: 1em;
    width: 100%;
    font-weight: 700;
}

.buttons-area {
    display: flex;
    gap: 10px;
}

h2 {
    font-weight: 800;
    font-size: 50px;
    color: #043872;
}

h1 {
    font-size: 35px;
    line-height: 1.1em;
}

.text {
    padding-left: 4em;
    text-align: left;
}

.col {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.collage {
    max-height: 400px;
    border-radius: 10px;
    width: 100%;
    height: auto;
    object-fit: cover;
}

.info-blocks {
    display: flex;
    width: 100%;
    max-width: 1350px;
    justify-content: space-between;
    flex: 0 0 100%;
    flex: 1;
}

.info-background {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    background-size: cover;
    justify-content: flex-start;
    padding: 0 .7em;
    margin: 5em 0em 7em 0em;
}
</style>