<template>
    <MainNav/>
    <div class="about-container">
        <div class="about-flex-box">
            <div class="about-box">
                <h>About <span>Liberty Home Mortgage</span></h>
                <h2>Bringing our customers and their homes together for 10+ years.</h2>
                <p1 class="hero-description">From Conventional to FHA, VA & USDA to rehab loans, purchase, refinance, debt consolidation & mortgages for less than perfect credit and hard to verify income borrowers, we directly lend to almost all residential type mortgage loans.</p1>
            </div>
            <div class="second-box">
                <img class="image-group" :src="aboutImg">
            </div>
        </div>
        <div class="second-row">
            <div class="merits-banner">
                <img class="merit" :src="inc5000">
                <img class="merit" :src="bbb">
                <img class="merit" :src="plainsDealer23">
                <img class="merit" :src="plainsDealer22">
                <img class="equal-housing" :src="equalHousing">
            </div>
        </div>
        <div class="third-row">
            <div>
                <img class="officers" :src="ourOfficers">
            </div>
            <div>
                <h3>Technology, <div class="red-text">people &</div> expertise.</h3>
                <h4>That's how we win—for you.</h4>
                <p>Liberty Home Mortgage Corporation is a direct lender which means that all of your mortgage loan decisions are made by us. We do not shop your credit around to multiple lenders. We are the lender. We offer a full array of lending products. From Conventional to FHA, VA and USDA to rehab loans, purchase, refinance, debt consolidation and mortgages for less than perfect credit and hard to verify income borrowers, we cover almost all residential type mortgage loans.</p>
                <div class="buttons-area">
                    <button class="banner-button2">Home Purchase</button>
                    <button class="banner-button">Refinance</button>
                    <button class="banner-button">Cash Out</button>
                </div>
            </div>
        </div>
    </div>
    <AboutBannerTwo/>
    <Reviews/>
    <States/>

</template>

<script>
import MainNav from '@/main-site/components/MainNav.vue';
import Reviews from '@/main-site/components/Reviews.vue';
import States from '@/main-site/components/States.vue';
import AboutBannerTwo from '@/main-site/components/AboutBannerTwo.vue';
import ourOfficers from '@/main-site/assets/jason-liberty-officer.png';
import aboutImg from '@/main-site/assets/about-image-group.png';

import inc5000 from '@/main-site/assets/merit-inc5000.jpg';
import plainsDealer23 from '@/main-site/assets/merit-best-workplace2023.jpg';
import plainsDealer22 from '@/main-site/assets/merit-best-workplace2022.jpg';
import bbb from '@/main-site/assets/merit-logos-bbb.jpg';
import equalHousing from '@/main-site/assets/equal-housing-lender-1.png';

export default {
    components: {
        MainNav,
        Reviews,
        States,
        AboutBannerTwo
    },

    data() {
        return {
            inc5000: inc5000,
            plainsDealer23: plainsDealer23,
            plainsDealer22: plainsDealer22,
            bbb: bbb,
            ourOfficers: ourOfficers,
            equalHousing: equalHousing,
            aboutImg: aboutImg
        }
    }
}
</script>

<style scoped>

.hero-description {
    font-size: 18px;
    font-weight: 600;
}

.equal-housing {
    max-width: 80px !important;
}

.banner-button2 {
    background-color: #062850;
    border-radius: 10px;
    border: none;
    color: white;
    padding: 1em;
    width: 100%;
    font-weight: 700;
}
.banner-button {
    background-color: #b31919;
    border-radius: 10px;
    border: none;
    color: white;
    padding: 1em;
    width: 100%;
    font-weight: 700;
}

.buttons-area {
    display: flex;
    gap: 10px;
}

p {
    text-align: left;
    margin-top: 2.5em;
    font-weight: 500;
    line-height: 1.6em;
    margin-bottom: 1.5em;
}

h4 {
    font-family: 'Montserrat';
    font-weight: 700;
    font-size: 30px;
    line-height: 1.1em;
    text-align: left;
    color: rgb(157, 157, 157);
    margin-top: .4em;
}

h3 {
    font-family: 'Montserrat';
    font-weight: 700;
    font-size: 50px;
    line-height: 1.1em;
    color: #062850;
    text-align: left;
  }

.officers {
    height: 500px;
    padding-right: 20px;
}

.third-row {
    max-width: 1350px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
}

.second-row {
    max-width: 1350px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.merit {
    max-height: 100px;
    max-width: 120px;
}

.merits-banner {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    margin-top: 4em;
    width: 100%;
    gap: 50px;
    justify-items: center;
    align-items: center;
    border-top: 1px solid #e6e7ea;
    border-bottom: 1px solid #e6e7ea;
    padding: .8em 0em;
    margin-bottom: 5em;
}

h2 {
    font-weight: 700;
    font-size: 30px;
    margin-top: 1em;
    margin-bottom: 1em;
    line-height: 1.2em;
    color: rgb(146, 146, 146);
}

.image-group {
    max-width: 550px;
}

.second-box {
    color: white;
    width: 50%;
    padding: 1em;
    line-height: 3em;
    border-radius: 8px;
}

.about-box {
    width: 50%;
    padding: 3em 2em;
    border-radius: 8px;
}

.about-flex-box {
    width: 100%;
    height: 650px;
    max-width: 1350px;
    text-align: left;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 50px;
    background-image: url("@/main-site/assets/about-background.png");
    background-size: cover; 
    background-position: center;
    background-repeat: no-repeat;
    overflow: hidden; 
}

.about-image-right {
    width: 900px;
    height: 350px;
    overflow: hidden;
    position: relative;
    object-fit: cover;
}

.officers-image {
    overflow: hidden;
    width: 900px;
}

span {
    color: #062850 !important;
    text-shadow: none !important;
}

.red-text {
    display: inline;
    color: #b31919 !important;
    text-shadow: none !important;
}

h {
    font-size: 65px;
    font-weight: 700;
    color: #b31919;
    line-height: 1.1em;
}

.about-container {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding: 0em 0.7em;
    margin-top: 6em;
    margin-bottom: 6em;
    
  }
</style>