<template>
    <div class="container">
        <div class="form-header">
            <div class="back-home">
                <router-link class="menu-item" :to="{ path: '/medina'}"><i @click="resetCashOut" class="fa-solid fa-caret-left back-button"></i></router-link>
            </div>

            <a href="tel:440-665-7627">
            <div class="call">
                <i class="fa-solid fa-phone phone"></i>
                <p>Need help? </p><span class="hide"><b>440-665-7627</b></span>
            </div>
            </a>

        </div>
        <div class="fix-height">
            <RealEstateStepBubbles/>
            <RealEstateContent @send-counter-up="sendCounterUp" @send-counter-down="sendCounterDown"
            :webhook="webhook"
            :thankYou="thankYou"/>
        </div>
    </div>
</template>
    
<script>

import RealEstateStepBubbles from '@/landing-pages/components/step-components/RealEstateStepBubbles.vue'
import RealEstateContent from '@/landing-pages/components/lead-forms/RealEstateContent.vue'

export default{
    components: {
        RealEstateStepBubbles,
        RealEstateContent,
    }, 

    data () {
        return {
            realEstateSteps:[1,2,3,4,5,6,7,8],
            realEstateCounter: 1,
            webhook: 'https://hooks.zapier.com/hooks/catch/14662938/3n1gy6t/',
            thankYou: '/medina-thank-you'
        };
    },

    provide() {
        return {
            getRealEstateSteps: () => this.realEstateSteps,
            getRealEstateCounter: () => this.realEstateCounter,
        }
    },

    methods: {
        sendCounterUp(){
            this.realEstateCounter++
        },

        sendCounterDown(){
            this.realEstateCounter = this.realEstateCounter - 1;
        },

    },

}
</script>

<style scoped>

@media screen and (max-width: 500px) {
    .hide {
        display: none !important;
    }

    .fix-height{
        height: 700px !important;
    }
}
    .container {
        width: 100%;
        max-width: 1350px;
    }

    .hide {
        font-size: 16px;
        font-weight: 700;
        margin: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-left: .2em;
    }

    p {
        font-size: 16px;
        font-weight: 700;
        margin: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .phone {
        background-color: #ffd2d2;
        padding: .5em;
        width: 35px;
        height: 35px;
        border-radius: 30px;
        color: #b31919;
        margin-right: .5em;
    }

    .back-button {
        background-color: rgb(180, 210, 248);
        padding: .9em;
        width: 45px;
        height: 45px;
        border-radius: 10px;
        color: #062850;
    }

    .call {
        display: inline-flex;
        text-decoration: none;
        color: black;
    }

    .form-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        max-width: 1350px;
        width: 100%;
        padding: 1em 0;
    }

    .fix-height{
        height: 750px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
    }

    button {
        color: white;
        background-color: #b31919;
        border-radius: 20px;
        border: 0;
        padding: .5em 1em;
    }

    .buttons-area {
        margin-top: 1em;
        display: flex;
        gap: 10px;
        left: 50%;
        right: 50%;
        justify-content: center;
    }
</style>
