<template>
  <MainNav/>
  <TopBannerTwo/>
  <CallBanner/>
  <SecondaryBanner/>
  <AboutBanner/>
  <VeteransBanner/>
  <TertiaryBanner/>
  <MortgageCalc/>
  <Reviews/>
  <MainBlogBanner/>
</template>

<script>
import MainNav from '@/main-site/components/MainNav.vue';
import TopBannerTwo from '@/main-site/components/TopBannerTwo.vue';
import CallBanner from '@/main-site/components/CallBanner.vue';
import SecondaryBanner from '@/main-site/components/SecondaryBanner.vue';
import AboutBanner from '@/main-site/components/AboutBanner.vue';
import VeteransBanner from '@/main-site/components/VeteransBanner.vue';
import TertiaryBanner from '@/main-site/components/TertiaryBanner.vue';
import Reviews from '@/main-site/components/Reviews.vue';
import MortgageCalc from '@/main-site/components/MortgageCalc.vue';
import MainBlogBanner from '@/main-site/components/MainBlogBanner.vue';

export default {
  components: {
    MainNav,
    TopBannerTwo,
    CallBanner,
    SecondaryBanner,
    AboutBanner,
    VeteransBanner,
    TertiaryBanner,
    Reviews,
    MainBlogBanner,
    MortgageCalc,
  },
  props: {
    purchForm: {
      type: String,
      required: true
    },
    refiURL: {
      type: String,
      required: true
    },
    cashOutURL: {
      type: String,
      required: true
    },
  },
  data() {
    return {
    };
  },
};
</script>

<style scoped>
@media (max-width: 900px) {
  .banner-container {
    background-position: top 130px left -100px !important;
    background-size: 150% !important;
    height: 35em !important;
  }

  .banner-box {
    top: 20px !important;
  }
}

@media (min-width: 600px) and (max-width: 900px) {
  .banner-box {
    width: 80% !important;
    top: 30px;
  }
}

i {
    color: white;
}

.buttons-area {
  margin-top: 1.5em;
}

.box-button1 {
  width: 100%;
  background-color: white;
  border: 0px solid white;
  border-radius: 5px;
  padding: 1em;
  color: #b31919;
  font-weight: 800;
  font-size: 20px;
}

.box-button2 {
  width: 100%;
  background-color: #062850;
  border: 0px solid white;
  border-radius: 5px;
  padding: 1em;
  color: white;
  font-weight: 800;
  font-size: 20px;
}

span {
  font-family: 'Merriweather';
  color: rgb(255, 255, 255);
}

h2 {
  font-family: 'Montserrat';
  font-weight: 500;
  font-size: 15px;
  line-height: 1.4em;
  max-width: 250px;
}

h1 {
  font-family: 'Montserrat';
  font-weight: 800;
  font-size: 20px;
  line-height: 1.1em;
  color: #b31919;
}

h {
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: 60px;
  line-height: 1.1em;
  color: #062850;
}

.banner-container {
  width: 100%;
  height: 45em; 
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 0em 0.7em;
  background-size: cover; 
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden; 
  margin-top: 2em;
  margin-bottom: 5em;
  transition: background-image 1s ease-in-out;
}

.caption {
  position: relative;
  top: 100px;
  width: 400px;
}

.box-content {
  text-align: left;
}

.banner-box {
  background-color: #ba0505dc;
  width: 800px;
  height: 450px;
  position: relative;
  top: 100px;
  padding: 1em 2em;
}

.flex-box2 {
  width: 100%;
  max-width: 1350px;
  padding-top: 8rem;
  padding-bottom: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.flex-box {
  width: 100%;
  max-width: 1350px;
  padding-top: 8rem;
  padding-bottom: 1rem;
  text-align: left;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  max-height: 300px;
}
</style>
