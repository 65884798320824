<template>
  <div class="bg-image" id="about">
    <div class="row">
      <div class="reviews-text">
        <img :src="zillowLogo" alt="zillow.logo" class="zillow">
          <div class="inline-text">
            <p>Top-rated choice for</p><span>first-time homebuyers.</span>
          </div>
      </div>
      <div class="col-right">
          <img :src="navLogo" alt="liberty.logo" class="logo">
          <div class="reviews">
            <div class="zillow-reviews">
                <p3>Zillow</p3>
                <div class="stars">
                  <p4>4.9 </p4>
                  <i class="fa-solid fa-star"></i><i class="fa-solid fa-star"></i><i class="fa-solid fa-star"></i><i class="fa-solid fa-star"></i><i class="fa-solid fa-star"></i>
                </div>
            </div>
            <div class="google-reviews">
                <p3>Google</p3>
                  <div class="stars">
                    <p4>4.7 </p4>
                    <i class="fa-solid fa-star"></i><i class="fa-solid fa-star"></i><i class="fa-solid fa-star"></i><i class="fa-solid fa-star"></i><i class="fa-solid fa-star"></i>
                  </div>
            </div>
          </div>
      </div>
      <!-- <div class="reviews" id="bf-revz-widget-987654321301116"></div> -->
    </div>
    <div class="reviews-container">
      <div class="reviews-row">
          <div v-for="review in reviews" :key="index" class="flex desktop">
              <p6>{{ review.name }}</p6>
              <hr>
              <p5>{{ review.text }}</p5>
          </div>

          <div class="mobile">
            <div class="flex">
                <p6>{{ currentReview.name }}</p6>
                <hr>
                <p5>{{ currentReview.text }}</p5>
            </div>
            <div class="buttons-area">
              <button @click="prev()" class="prev-btn"><i class="fa-solid fa-arrow-left arrows" :class="(prevBtnDisabled === true) ? 'disabled': ''"></i></button>
              <button @click="next()" class="next-btn"><i class="fa-solid fa-arrow-right arrows" :class="(nextBtnDisabled === true) ? 'disabled': ''"></i></button>
            </div>
          </div>
          
      </div>
    </div>
  </div> 
</template>

<script>
import navLogo from '@/main-site/assets/lhm_hori_logo_bluebell_redtype.svg';
import zillowLogo from '@/landing-pages/assets/Zillow_logo.svg';

export default {
  mounted() {
    this.loadBirdEyeScript();
  },
  methods: {
    // loadBirdEyeScript() {
    //   const script = document.createElement('script');
    //   script.type = 'text/javascript';
    //   script.src = 'https://birdeye.com/embed/v7/152284788004314/11/987654321301116';
    //   script.async = true;

    //   document.body.appendChild(script);
    // },

    startSlideshow() {
      setInterval(() => {
        this.currentReviewIndex = (this.currentReviewIndex + 1) % this.reviews.length;
      }, 5000); // Change image every 5 seconds
    },

    prev(){
      if ( this.currentReviewIndex > 0 ){
        this.currentReviewIndex = this.currentReviewIndex - 1;
        return this.nextBtnDisabled = false;
      } else {
        return this.prevBtnDisabled = true;
      };
    },

    next(){
      if ( this.currentReviewIndex < (this.reviews.length - 1) ){
        this.currentReviewIndex = this.currentReviewIndex + 1;
        this.prevBtnDisabled = false;
      } else {
        this.nextBtnDisabled = true;
      }
    },

  },

  mounted() {
    this.startSlideshow();
  },

  computed: {
    currentReview() {
      return {
        name: this.reviews[this.currentReviewIndex].name,
        text: this.reviews[this.currentReviewIndex].text
      };
    },

    disabledPrevBtn(){
      if ( this.currentReviewIndex === 0 ){
        this.prevBtnDisabled = true;
      } else if ( this.currentReviewIndex > 0 ) {
        this.prevBtnDisabled = false;
      }
    },

    disabledNextBtn(){
      if ( this.currentReviewIndex < (this.reviews.length - 1) ){
        return this.nextBtnDisabled = false;
      } else {
        return this.nextBtnDisabled = true;
      }
    }
    
  },

  data (){
    return {
      navLogo: navLogo,
      zillowLogo: zillowLogo,
      currentReviewIndex: 0,
      prevBtnDisabled: true,
      nextBtnDisabled: false,

      reviews: [
          {
              name: 'Stephen T.',
              text: "Buying our first home through Liberty could not have been any easier. Brandon was incredibly helpful and knowledgeable on all of the intricacies of the process... If I could review with more stars I absolutely would. Being a first time home owner was daunting but Brandon was able to make us feel at ease with this new chapter in our lives and we couldn’t be more grateful.",
          },
          {
              name: 'Johan & LaSandra A.',
              text: "Sharp & timely. What a great lender from start to finish. The details are where they shine - because not being on top of them can make or break a loan. Very thorough and we loved the convenience of the high tech process - making the entire experience efficient and timely. Highly recommend.",
          },
          {
              name: 'Brittany B.',
              text: "Kate is an invaluable resource for a first time homebuyer. She made herself available as often as needed and never talked down to us. Thanks to her extensive product knowledge we were able to find a loan to fit our complex needs and she took the time to lay out all options so we felt confident in our decision.",
          }
      ],
    }
  }
};
</script>

<style scoped>
@media (max-width: 500px) {
  p {
    font-size: 35px !important;
  }

  .inline-text span {
    font-size: 35px !important;
  }

  .inline-text {
    line-height: .8em !important;
  }

  .reviews {
    gap: 15px !important;
  }

  p3 {
    font-size: 15px !important;
  }

  p4 {
    font-size: 28px !important;
  }

  .logo {
    width: 120px !important;
  }
}

@media (min-width: 501px) and (max-width: 900px) {
   p {
    font-size: 38px !important;
  }

  .inline-text span {
    font-size: 38px !important;
  }

   .inline-text {
    line-height: 1em !important;
  }
}

@media (max-width: 900px) {
  p6 {
    margin-top: 0em;
  }

  .reviews-row {
    margin: 3em 0em 0em 0em !important;
    flex-direction: column !important;
  }

  .desktop {
    display: none !important;
  }

  .mobile {
    display: block !important;
  }

  .row {
    flex-direction: column !important;
  }

  .reviews {
    width: 100% !important;
  }

  .reviews-text {
    width: 100% !important;
    justify-content: center !important;
    align-items: center;
    margin-bottom: 1.5em;
  }

  .inline-text {
    text-align: center !important;
    width: 100% !important;
    line-height: 1em;
  }


  .col-right {
    max-width: 100% !important;
    align-items: center !important;
  }

  .reviews {
    justify-content: center;
  }

  .reviews-text {
    max-width: 100% !important;
  }

  .zillow {
    width: 160px !important;
    margin-bottom: 1em;
  }


  .bg-image {
      padding: 3em 0 !important;
  }
}

.mobile {
  display: none;
}

.arrows {
  color: white;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: white;
}

.next-btn {
  border-radius: 30px;
  border: none;
  height: 40px;
  width: 40px;
  background-color: #4582cd49;
}

.prev-btn {
  border-radius: 30px;
  border: none;
  height: 40px;
  width: 40px;
  background-color: #4582cd49;
}

.disabled {
  color: #4582cd2c;
  -webkit-text-stroke-width: 0px;
}

.buttons-area {
  display: flex;
  gap: 10px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 1.2em;
}

hr {
  width: 80%; 
  margin: .5em;
}

p6 {
  font-weight: 700;
  font-size: 20px;
}

.review-col {
  text-align: left !important;
  display: flex;
  flex-direction: column;
}

.reviews-row {
  width: 100%;
  display: flex;
  padding: 0 .7em;
  justify-content: center;
  flex-direction: row;
  flex: 0 0 100%;
  flex: 1;
  gap: 1.4em;
  margin: 3em 0em;
}

.flex {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.reviews-container {
  max-width: 1350px;
}

p4 {
  font-size: 35px;
  font-weight: 800;
}

p5 {
  text-align: left;
}

p3 {
  font-size: 20px;
  font-weight: 500;
}

.zillow-reviews {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.google-reviews {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.zillow {
  width: 200px;
  margin-bottom: 1em;
}

.logo {
  width: 150px;
  margin-left: -.5em;
}

img {
  width: 120px;
  margin-bottom: .5em;
}

.inline-text p, .inline-text span {
    display: inline; 
}

.inline-text span {
  margin: 0em 0em 0em .2em;
  font-size: 45px;
  display: inline-block;
}

p {
  text-align: left;
  color: black;
  font-size: 45px;
  font-weight: 600;
  display: inline-block;
  margin: 0;
  padding: 0;
}

.inline-text {
  text-align: left;
  color: #b31919;
  font-size: 45px;
  font-weight: 800;
  line-height: 1.25em;
  display: inline-block;
  margin: 0;
  padding: 0;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1350px;
}

.col-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  max-width: 50%;
}

.reviews {
  display: flex;
  flex-direction: row;
  gap: 80px;
}

.reviews-text {
  max-width: 50%;
  display: flex;
  flex-direction: column;
}

.bg-image {
    /* background-image: url("@/assets/stripes-navy-gradient@2x.png"); */
    background-color: #e9f0f9;
    position: relative;
    background-size: cover;
    padding: 5em 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 2em 0em 0em 0em;
}
</style>
