<template>
    <MainNav/>
    <div class="banner-container1">
    </div>
    <SearchBar/>
    <div class="top-banner">
        <div class="top-banner-container">
            <SearchResults/>
        </div>
    </div>
    
</template>

<script>
import SearchBar from '@/main-site/ui-elements/SearchBar.vue';
import MainNav from '@/main-site/components/MainNav.vue';
import SearchResults from '@/main-site/ui-elements/SearchResults.vue';

export default {
    components: {
        MainNav,
        SearchBar,
        SearchResults,
    },
}
</script>

<style scoped>
@media screen and (max-width: 1125px) {
    .banner-container1 {
        margin-top: 72px !important;
    }
}

.flex-box {
    width: 100%;
    max-width: 1350px;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.banner-container1 {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding: 0em 0.7em;
    margin-top: 88px;
}

span {
    color: #b31919;
}

h {
    font-size: 65px;
    font-weight: 700;
    color: #062850;
}

img {
    height: 400px;
}

.top-banner-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    max-width: 1350px;
    width: 100%;
    margin-top: 2em;
}

.top-banner {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0em .7em;
}


</style>